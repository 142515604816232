import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFeaturedBig from "../../atoms/story-card-featured-big";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import Carousel from "../../atoms/carousel";
import Icon, { ZodiacIcon } from "../../atoms/icon";
import styles from "./styles.m.css";

const zodiacs = [
  {
    type: "aries",
    display_name: "மேஷம்"
  },
  {
    type: "taurus",
    display_name: "ரிஷபம்"
  },
  {
    type: "gemini",
    display_name: "மிதுனம்"
  },
  {
    type: "cancer",
    display_name: "கடகம்"
  },
  {
    type: "leo",
    display_name: "சிம்மம்"
  },
  {
    type: "virgo",
    display_name: "கன்னி"
  },
  {
    type: "libra",
    display_name: "துலாம்"
  },
  {
    type: "scorpio",
    display_name: "விருச்சிகம்"
  },
  {
    type: "sagitarius",
    display_name: "தனுசு"
  },
  {
    type: "capricorn",
    display_name: "மகரம்"
  },
  {
    type: "aquarius",
    display_name: "கும்பம்"
  },
  {
    type: "pisces",
    display_name: "மீனம்"
  }
];

const GenZodiacList = (collectionItems, zodiacsData) => {
  const desktopGap = 30;
  const mobileGap = 30;
  const numberOfCardsInViewDesktop = 7;
  const numberOfCardsInViewMobile = 2;
  const numberOfCardsInViewTablet = 3;
  const signs = collectionItems.map((item, index) => {
    const zodiac = zodiacsData[index] || {};
    const slg = get(item, ["slug"], null);
    return (
      <Link aria-label="Zodiac Sign" className={styles["zodiac-element"]} href={slg} key={zodiac.type}>
        <ZodiacIcon
          type={zodiac.type}
          className={styles["zodiac-element__icon"]}
          hoverClassName={styles["show-on-hover"]}
          hoverDefault={true}
        />
        <h3 className={styles["headline"]}>{zodiac.display_name}</h3>
      </Link>
    );
  });
  return (
    <Carousel
      className={styles["carousel"]}
      options={{
        type: "carousel",
        perView: numberOfCardsInViewDesktop,
        gap: desktopGap,
        rewind: false,
        peek: {
          before: 0,
          after: 244
        },
        breakpoints: {
          767: {
            perView: numberOfCardsInViewMobile,
            gap: mobileGap,
            peek: {
              before: mobileGap,
              after: 144
            }
          },
          991: {
            perView: numberOfCardsInViewTablet,
            gap: desktopGap,
            peek: {
              before: desktopGap,
              after: 244
            }
          }
        }
      }}
      renderCenterLeftControls={({ previousSlide, currentSlide }) =>
        currentSlide > 0 ? (
          <button
            aria-label="previous slide"
            role="button"
            onClick={previousSlide}
            className={`${styles["slider-nav-button"]}`}
          >
            <Icon type="angle-left" className={styles.icon} />
          </button>
        ) : null
      }
      renderCenterRightControls={({ nextSlide }) => (
        <button aria-label="next slide" role="button" onClick={nextSlide} className={`${styles["slider-nav-button"]}`}>
          <Icon type="angle-right" className={styles.icon} />
        </button>
      )}
    >
      {signs}
    </Carousel>
  );
};

const SanipeyarchiCollection = ({ collection, accentColor }) => {
  const { name } = collection;
  const items = get(collection, ["items"], null);
  if (!items) {
    return null;
  }
  return (
    <div className={`${styles["four-story-half-featured"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <CollectionLink collection={collection}>
          <CollectionTitleWithCrossLine className={`${styles.title}`} title={name} />
        </CollectionLink>
        {collection && GenZodiacList(items, zodiacs)}
      </div>
    </div>
  );
};

SanipeyarchiCollection.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFeaturedBig.propTypes)),
    accentColor: PropTypes.string
  })
};
SanipeyarchiCollection.storyLimit = 12;

export default SanipeyarchiCollection;
