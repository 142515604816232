import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import Carousel from "../../atoms/carousel";
import CollectionTitleWithCTA from "../../atoms/CollectionTitleWithCTA";
import Icon from "../../atoms/icon";
import { getStoryHeadline } from "../../utils/utils";

import styles from "./styles.m.css";
import AudioStoryCardDLTextOverley from "../../atoms/audio-story-card-dl-text-overley";

const AudioCollectionSliderFocusedCardWithTextOverley = ({
  collection,
  numberOfCardsInViewDesktop = 4.6,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1.2,
  rectcard = true
}) => {
  const desktopGap = 0;
  const mobileGap = 0;

  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);

  const slides = collection.items
    .filter(({ story = {} }) => getStoryHeadline(story) && story.slug)
    .map(({ story }) => (
      <AudioStoryCardDLTextOverley
        key={story.id}
        story={story}
        collection={collection}
        rectcard={rectcard}
        className={styles.card}
        pFrom={`${pageName}-story-card-slider`}
      />
    ));

  return (
    <div className={styles.base}>
      <div className={`${styles["carousel-wrapper"]} ${styles["container-story"]}`}>
        {collection.name && (
          <CollectionLink collection={collection} customSlug={true} slugName={"vikatan-play"}>
            <CollectionTitleWithCTA className={styles.title} title={collection.name} theme="red" />
          </CollectionLink>
        )}
        <Carousel
          className={styles["carousel"]}
          options={{
            type: "slider",
            perView: numberOfCardsInViewDesktop,
            gap: desktopGap,
            rewind: false,
            breakpoints: {
              0: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap
              },
              767: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap
              },
              991: {
                perView: numberOfCardsInViewTablet,
                gap: desktopGap
              }
            }
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) =>
            currentSlide > 0 ? (
              <button
                aria-label="previous slide"
                role="button"
                onClick={previousSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="angle-left" className={styles.icon} />
              </button>
            ) : null
          }
          renderCenterRightControls={({ nextSlide }) => (
            <button
              aria-label="next slide"
              role="button"
              onClick={nextSlide}
              className={`${styles["slider-nav-button"]}`}
            >
              <Icon type="angle-right" className={styles.icon} />
            </button>
          )}
          childClass={styles["glide_li_child"]}
        >
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

AudioCollectionSliderFocusedCardWithTextOverley.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  rectcard: PropTypes.string
};

export default AudioCollectionSliderFocusedCardWithTextOverley;
