import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { fetchIPLPointTable24 } from "../../../api";

import styles from "./styles.m.css";

const IplPointTable = ({ accentColor, titleClassName }) => {
  const [matchList, setData] = useState([]);

  useEffect(() => {
    fetchIPLPointTable24().then(list => {
      setData(list);
    });
  }, []);
  const pointList = get(matchList, ["teams"], []);
  return (
    <div className={`${styles["base"]} ${styles["pagetable"]}`}>
      <div className={`${styles["point-table-wrapper"]} container`}>
        <div className={`${styles["table-grid"]}`}>
          <div className={`${styles["point-table"]}`}>
            <h6 className={`${styles["point-table-title"]} ${styles[titleClassName]}`}>IPL 2024 Points table</h6>
            <table className={styles["inner-table"]}>
              <thead>
                <th>Team</th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>PLD</span>
                  <span className={`${styles["point-mobile"]}`}>P</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Won</span>
                  <span className={`${styles["point-mobile"]}`}>W</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Lost</span>
                  <span className={`${styles["point-mobile"]}`}>L</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Tied</span>
                  <span className={`${styles["point-mobile"]}`}>T</span>
                </th>
                <th>N/R</th>
                <th>Net RR</th>
                <th>Pts</th>
              </thead>
              {pointList.length > 0 &&
                pointList.map((list, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <table className={styles["sub-table"]}>
                          <tr>
                            <td>
                              <div className={styles["logo-name"]}>
                                <img
                                  alt={list.short_name}
                                  src={`${list.team_logo}?format=webp&w=100&dpr=1.0`}
                                  className={styles["img-view"]}
                                />
                                <span>{list.short_name}</span>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>{list.played}</td>
                      <td>{list.won}</td>
                      <td>{list.lost}</td>
                      <td>{list.tied}</td>
                      <td>{list.no_result}</td>
                      <td>{list.net_run_rate}</td>
                      <td>{list.points}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

IplPointTable.propTypes = {
  accentColor: PropTypes.string,
  titleClassName: PropTypes.string
};

export default IplPointTable;
