import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import SliderWebStoriesCard from "../../rows/slider-web-stories-card";
import { fetchFbStory } from "../../../api";
import styles from "./styles.m.css";

const WebstoriesFbUint = ({ collection, accentColor }) => {
  const [postURL, setPostURL] = useState("");
  useEffect(() => {
    fetchFbStory()
      .then(res => {
        setPostURL(res);
      })
      .catch(console.error);
  }, []);
  return (
    <div className={`${styles["main-container"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className={styles["story-card-container"]}>
          <div className={styles["sidebar-story-container"]}>
            <div className={styles["small-card-container"]}>
              <Iframe
                url={postURL && `https://www.facebook.com/plugins/post.php?href=${postURL.post_url}`}
                width="300"
                height="325"
                frameBorder="0"
                id="fb_load"
                className={styles["iframe-sec"]}
              />
              <a href={postURL && postURL.post_url} target="new" className={styles["button"]}>
                கருத்தை பதிவு செய்ய
              </a>
            </div>
          </div>
          <div className={styles["main-story-container"]}>
            <SliderWebStoriesCard collection={collection} numberOfCardsInViewDesktop={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

WebstoriesFbUint.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string
  })
};
WebstoriesFbUint.storyLimit = 4;

export default WebstoriesFbUint;
