import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";
import Icon from "../../atoms/icon";

export default function StoryImageSlide({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true
}) {
  const storyData = getStoryData(story);
  const externalLink = get(story, ["metadata", "reference-url"]);

  if (!storyData.headline || !story["url"]) {
    return null;
  }

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  return (
    <div className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}>
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        className={styles["link"]}
        href={externalLink || story.url}
        externalLink={SourceLink || externalLink}
      >
        <div className={styles["content"]}>
          <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
            {storyData.headline}
          </h3>
        </div>
        <div className={styles["image-container"]}>
          <ResponsiveImageWithFallback
            className={styles["image-wrapper"]}
            slug={storyData.imageS3Key}
            metadata={storyData.imageMetadata}
            alt={storyData.imageCaption}
            isPremium={isPremium(story)}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [3, 3], screenWidthCoverage: 1 },
              { aspectRatio: [3, 3], screenWidthCoverage: 1 }
            )}
            story={story}
          >
            {story.engagement &&
              story.engagement.total && (
                <span className={styles["engagement-total"]}>
                  <Icon type={"speaker"} className={styles["speaker-icon"]} />
                  {story.engagement.total}
                </span>
              )}
          </ResponsiveImageWithFallback>
        </div>
      </Link>
    </div>
  );
}

StoryImageSlide.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  story: PropTypes.shape({
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  })
};
