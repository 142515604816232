import React from "react";
import Collapsible from "react-collapsible";
import PropTypes from "prop-types";

const FaqSection = props => {
  return (
    <div className="faq-outer">
      <Collapsible trigger="இந்த ஊரடங்குக் காலத்தில் மக்கள் அத்தியாவசிய தேவைகளுக்கு வெளியே வந்தாலே காவல் துறையினர் அடிப்பதாக கூறுகிறார்களே? இது சரியா?">
        <p>
          ஓய்வு பெற்ற போலீஸ் அதிகாரி கருணாநிதியிடம் கேட்டதற்கு, கொரோனா வைரஸ் பரவுவதைத் தடுக்க முன்எச்சரிக்கை
          நடவடிக்கையாக மத்திய, மாநில அரசு 144 தடை உத்தரவுகளைப் பிறப்பித்துள்ளது. அரசின் இந்த உத்தரவுக்கு பொதுமக்கள்
          ஒத்துழைப்பைக் கொடுக்க வேண்டும். ஏதோ ஒரு காரணத்தைச் சொல்லி வெளியில் வருவதை மக்கள் குறைத்துக் கொள்ள வேண்டும்.
          அத்தியாவசிய பொருள்களை வாங்க மக்கள் வருவது தவறில்லை. இன்றைய சூழலில் நியாயமான காரணத்துக்காக 144 தடை உத்தரவு
          பிறப்பிக்கப்பட்டுள்ளது. அதனால் காவல் துறையினர், சேவை செய்யும் நோக்கத்தில் இன்றைய சூழலில் பாதுகாப்பு பணியில்
          ஈடுபட்டுவருகின்றனர். வெளியில் வருபவர்கள் எல்லோரும் வன்முறையாளர்கள் இல்லை என்பதை அவர்கள் அறிவார்கள். சில
          இடங்களில் தேவையில்லாமல் வெளியில் வரும் பொதுமக்களிடம் காவல் துறையினர் கெஞ்சி கேட்டு திருப்பி அனுப்புகின்றனர்.
          ஒரு கட்டத்துக்கு மேல் செல்பவர்களிடம்தான் கோபத்தைக் காட்டுகின்றனர். அத்தியாவசிய பொருள்களை வாங்க வரும் பெண்கள்,
          முதியவர்கள் மற்றும் பொதுமக்கள் யாரிடமும் காவல்துறையினர் கடுமையாக நடந்துக் கொள்வதில்லை. கம்பெனிகள், கல்வி
          நிலையங்கள் எல்லாம் மூடப்பட்டுள்ளன. இந்தச் சமயத்தில் வெளியில் வருபவர்கள், நியாயமான காரணத்தைச் சொல்லும்போது
          அவர்கள் மீது காவல்துறையினர் நடவடிக்கை எடுக்க மாட்டார்கள் என்றார்.
        </p>
      </Collapsible>
      <Collapsible trigger="மூன்று மாதங்களுக்கு இஎம்ஐ கட்டத் தேவையில்லை என்கிறார்கள். அப்படியென்றால் மூன்று மாதங்களுக்குப் பிறகு நான்கு மாதங்களுக்குச் சேர்த்து இஎம்ஐ கட்ட நேரிடுமா?">
        <p>
          ரிசர்வ் வங்கி ஆளுநர் சக்திகாந்த் தாஸ் அறிவிப்பில், மூன்று மாதங்களுக்கு இஎம்ஐ கட்டத்தேவையில்லை எனவும் கடன்
          வசூலை நிறுத்திவைக்க வங்கிகளுக்கு ரிசர்வ் வங்கி உத்தரவிட்டுள்ளதாகவும் தெரிவிக்கப்பட்டுள்ளது. மேலும், 2020,
          மார்ச் 1-ம் தேதியிலிருந்து அனைத்துக் கால கடன்களுக்கும் தவணைகளைச் செலுத்துவதற்கு மூன்று மாத கால அவகாசம்
          அனுமதிக்கப்படுகிறது. அதாவது மார்ச், ஏப்ரல், மே மாதங்களுக்கான இஎம்ஐ கட்டத்தேவையில்லை. தள்ளுபடி
          அளிக்கப்படவில்லை. கடன் செலுத்துவது தள்ளிவைக்கப்படுகிறது, அவ்வளவே! வங்கி நிறுவனங்கள் கூறும் காலத்தில் இந்த
          இஎம்ஐ-களை செலுத்த வேண்டும். இதனால் வாடிக்கையாளரின் சிபில் ஸ்கோர் பாதிக்கப்படாது. எனினும் இந்த அறிவிப்பை
          ஒவ்வொரு வங்கிகளும் எப்படி அணுகவிருக்கின்றன என்பது குறித்து பொறுத்துதான் பார்க்கவேண்டும்.
        </p>
      </Collapsible>
      <Collapsible trigger="கொரோனா அல்லாத வேறு சில காரணங்களுக்காக மருத்துவமனைக்குச் செல்ல வேண்டிய அவசியம் ஏற்பட்டால், முன்னெச்சரிக்கை நடவடிக்கைகள் என்னென்ன?">
        <p>
          மருத்துவமனைக்குச் செல்லும்போது மாஸ்க் அணிவதும், வீட்டுக்கு வந்தவுடன் கைகளைச் சுத்தமாகக் கழுவுவதும்தான்
          முன்னெச்சரிக்கை நடவடிக்கைகள்.
        </p>
      </Collapsible>
      <Collapsible trigger="வைரஸ் தொற்று இல்லாமல், சாதாரண காய்ச்சல், சளி இருப்பவரை கொரோனா தொற்றுமா?">
        <p>
          கட்டாயம் தொற்றும் என்று சொல்ல முடியாது. மற்றவர்களைவிட இவர்களுக்கு வருகிற வாய்ப்பு கொஞ்சம் கூடுதல் என்று
          சொல்லலாம்.
        </p>
      </Collapsible>
      <Collapsible trigger="சளி, காய்ச்சல் வந்தாலே ஜி.ஹெச்சுக்குத்தான் போகவேண்டுமா, அருகிலுள்ள ஏதேனும் மருத்துவமனையில் பரிசோதனை செய்யக்கூடாதா?">
        <p>
          எளிதில் சரியாகிற சளி, காய்ச்சலுக்கெல்லாம் ஜி.ஹெச்சுக்குப் போகவேண்டிய அவசியமில்லை. இதையும் தாண்டி அதிகப்படியான
          காய்ச்சல், மூச்சுத்திணறல் இருந்தால் உடனே சென்றுவிடுங்கள்
        </p>
      </Collapsible>
      <Collapsible trigger="பேருந்து, ரயில்களில் கூட்டநெரிசலில் பயணிக்கும்போது மூச்சுக்காற்றின் வழியே வைரஸ் பரவுமா?">
        <p>
          இன்றைய நிலைமையில் இதுதான் ஆபத்தான விஷயமே. அதனால், பயணங்களின்போது கட்டாயம் மாஸ்க் அணிந்துகொள்ளுங்கள்.
          வண்டியைவிட்டு இறங்கியவுடனே கைகளை ஹேண்ட் சானிட்டைஸரால் சுத்தப்படுத்திக் கொள்ளுங்கள்.
        </p>
      </Collapsible>
      <Collapsible trigger="கொரோனா வராமல் தடுக்க, தவிர்க்கவேண்டிய உணவுகள்..">
        <p>அப்படி எதுவும் இல்லை. எந்த உணவாக இருந்தாலும் நன்கு சமைக்கப்பட்டிருக்க வேண்டும்.</p>
      </Collapsible>
      <Collapsible trigger="வீட்டிலேயே தெர்மா மீட்டர் வைத்து டெம்பரேச்சர் செக் செய்வது சரியா?">
        <p>அக்குள் பகுதியில் வைத்து செக் பண்ணலாம். வாயில் வைத்து செய்யவே கூடாது.</p>
      </Collapsible>
      <Collapsible trigger="சைனஸ் தொந்தரவு இருப்பவர்களை கொரோனா எளிதில் தாக்குமா? இந்த நேரத்தில் அவர்கள் எப்படிப் பாதுகாப்பாக இருக்க வேண்டும்?">
        <p>
          மற்றவர்களைவிட இவர்களுக்கு வாய்ப்பு கொஞ்சம் கூடுதல்தான். ஆனால், இவர்கள் மாஸ்க் அணிவது, கூட்டம் அதிகமான
          இடங்களுக்குச் செல்லாமல் இருப்பது, வாயைப் பொத்தி தும்மல் போட்டவுடனே ஹேண்ட் சானிட்டைஸர் பயன்படுத்துவது என்று
          கவனமாக இருந்தால் பயப்பட வேண்டாம்.
        </p>
      </Collapsible>
      <Collapsible trigger="ஹேண்ட் சானிட்டைஸர் கிடைக்கவில்லையென்றால் சோப்பால் கைகழுவினால் போதுமா?">
        <p>தாராளமாகச் செய்யலாம்.</p>
      </Collapsible>
      <Collapsible trigger="கொரோனா பீதி ஏற்பட்டதிலிருந்தே வளர்ப்புப் பிராணிகள் மற்றும் கால்நடைகளுடன் நேரடித் தொடர்பில் இருக்காதீர்கள் என்று அறிவுறுத்தப்படுகிறதே...">
        <p>
          கொரோனா வைரஸ் புதிதாக வந்துள்ள பாதிப்பு என்பதால், அது நம்மிடமிருந்து விலங்குகளுக்குப் பரவுமா, அவற்றிடமிருந்து
          மனிதர்களுக்குப் பரவுமா என்றெல்லாம் இன்னும் தெளிவாகவில்லை. விலங்குகளிடமிருந்து பரவும் என்பதும்
          நிரூபிக்கப்படவில்லை. அதனால், முன்புபோல வளர்ப்புப் பிராணிகள் மற்றும் கால்நடைகளிடம் நெருக்கம் காட்ட வேண்டாம்.
          அப்படியே செல்லவேண்டிய சூழல் ஏற்பட்டாலும் மாஸ்க் போட்டுக்கொண்டுசெல்லுங்கள்.
        </p>
      </Collapsible>
    </div>
  );
};

FaqSection.propTypes = {
  vuid: PropTypes.string,
  closeModal: PropTypes.func
};

export default FaqSection;
