import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import { Icon } from "../../atoms/icon";
import VideoStoryCard from "../../atoms/video-story-card";
import styles from "./styles.m.css";
import Carousel from "../carousel";
export default function VideoStories({
  collection,
  className = "",
  storiesStart = 0,
  hasTruncatedHeadline = true,
  numberOfCardsInViewDesktop = 1,
  numberOfCardsInViewTablet = 1,
  numberOfCardsInViewMobile = 1,
  showCarousel
}) {
  const desktopGap = 30;
  const mobileGap = 30;
  if (!collection || !collection.items) {
    return null;
  }
  const stories = collection.items.map(item => item.story);
  if (stories.length === 0) {
    return null;
  }
  let slides = stories
    .slice(storiesStart, 7)
    .map(story => <VideoStoryCard className={styles["story-card"]} key={story.id} story={story} page="video-page" />);

  let sliderMain = stories
    .slice(0, 1)
    .map(story => (
      <VideoStoryCard className={styles["story-card"]} key={story.id} story={story} page="home-page" playBtn="big" />
    ));

  if (slides.length === 2) {
    return <div>{slides}</div>;
  }

  return (
    <div className={`${styles["numbered-items-wrap"]} ${className} bg-diamond-dark`}>
      {collection.slug &&
        collection.name && (
          <div className={styles["numbered-header"]}>
            <h2 className={styles["title"]}>
              <Icon type={"videographer"} className={styles["video-icon"]} />
              <Link aria-label={`"Read more "${collection.name}`} href={`/vikatan-video?artfrm=video-section-home`}>
                வீடியோஸ்
              </Link>
            </h2>
            <div className={styles["more-div"]}>
              <Link
                className={styles["more-button"]}
                aria-label={`"Read ${styles["more-button"]} "${collection.name}`}
                href={`/vikatan-video?artfrm=video-section-home-readmore`}
              >
                <span>மேலும் வீடியோஸ் </span>
                <span className={styles["more-icons"]}>
                  <Icon type={"slider-next"} className={styles["left-icon"]} />
                  <Icon type={"slider-next"} className={styles["right-icon"]} />
                </span>
              </Link>
            </div>
          </div>
        )}
      <div className={styles["main-head"]}>{sliderMain}</div>
      {showCarousel &&
        showCarousel === true && (
          <div className={showCarousel === true ? styles["showallvideo"] : ""}>
            <Carousel
              className={`${styles["first-slider"]} ${className}`}
              options={{
                type: "slider",
                perView: numberOfCardsInViewDesktop,
                gap: desktopGap,
                focusAt: 0,
                bound: true,
                peek: {
                  before: 0,
                  after: 120
                },
                breakpoints: {
                  320: {
                    perView: numberOfCardsInViewMobile,
                    gap: mobileGap,
                    bound: true,
                    peek: {
                      before: 0,
                      after: 120
                    }
                  },
                  767: {
                    perView: numberOfCardsInViewMobile,
                    gap: mobileGap,
                    bound: true,
                    peek: {
                      before: 0,
                      after: 120
                    }
                  },
                  991: {
                    perView: numberOfCardsInViewTablet,
                    gap: desktopGap,
                    bound: true,
                    peek: {
                      before: 0,
                      after: 120
                    }
                  }
                }
              }}
              renderCenterLeftControls={({ previousSlide }) => {
                return null;
              }}
              renderCenterRightControls={({ nextSlide }) => {
                return null;
              }}
              renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                return null;
              }}
              childClass={styles["glide__slide__li"]}
            >
              {slides}
            </Carousel>
          </div>
        )}
    </div>
  );
}

VideoStories.propTypes = {
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  storiesStart: PropTypes.number,
  showCarousel: PropTypes.bool,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.number,
        slug: PropTypes.string.number,
        id: PropTypes.string.number
      })
    )
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};
