import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import { getPagePath } from "../../../../isomorphic/data/collection";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources, checkAppSlug } from "../../utils/utils";
import Icon from "../icon";

const CollectionSpecialBundleItem = ({ collection, cardWithImageZoom = true, className = "" }) => {
  const headline = get(collection, ["name"], "");
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["slug"], "");
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");

  if (!(headline && slug)) {
    return null;
  }

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = getPagePath(collection);
  }

  return (
    <Link
      aria-label="Magazine"
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={getPagePath(collection)}
      externalLink={SourceLink}
    >
      <ResponsiveImageWithFallback
        className={styles["image-wrapper"]}
        slug={coverImageS3Key}
        metadata={coverImageMetaData}
        alt={imageCaption}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          { aspectRatio: [7, 8], screenWidthCoverage: 0.6 },
          { aspectRatio: [7, 8], screenWidthCoverage: 0.3 }
        )}
      />
      <div className={styles["content-wrap"]}>
        <div>
          <div className={styles["rectangle"]}>
            <Icon type="bundle" className={`${styles["bundle"]}`} />
          </div>
          <div className={styles["special"]}> சிறப்புத் தொகுப்பு </div>
        </div>
        <h3 className={styles["headline"]}>{headline}</h3>
        {collection.summary && <p className={styles["summary"]}>{collection.summary}</p>}
      </div>
    </Link>
  );
};

CollectionSpecialBundleItem.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  className: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

export default CollectionSpecialBundleItem;
