import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";

const CollectionTitleWithSeries = ({ title, className = "" }) => {
  if (!title) {
    return null;
  }

  return (
    <h4 className={styles["collection-title"]}>
      <span className={styles.text}>{title}</span>
    </h4>
  );
};

CollectionTitleWithSeries.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CollectionTitleWithSeries;
