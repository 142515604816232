import React from "react";
import PropTypes from "prop-types";

import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";

import styles from "./styles.m.css";

const data = {};
data.story = {
  url: "https://special.vikatan.com/neetly/",
  "story-template": "listicle",
  "acces-level-value": 100,
  id: "987iug",
  "author-name": "சுரேஷ் கண்ணன்",
  sections: [
    {
      "display-name": "ஆனந்த விகடன்",
      color: "#e92227"
    }
  ],
  access: "login",
  authors: [
    {
      id: 287213,
      name: "Sooraj Balasubramanian",
      slug: "sooraj-balasubramanian",
      "avatar-url":
        "https://lh4.googleusercontent.com/-ktRiE1o9BIM/AAAAAAAAAAI/AAAAAAAAAAA/AKxrwcZynoAnoQdy2YXqd7IeLvKCKdZ1wA/mo/photo.jpg?sz=50",
      "avatar-s3-key": null,
      "twitter-handle": null,
      bio:
        "<p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\u2019s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s</p><p>with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>",
      "contributor-role": { id: 1385, name: "author" }
    },
    {
      id: 283616,
      name: "Sriram",
      slug: "sriram",
      "avatar-url": null,
      "avatar-s3-key": null,
      "twitter-handle": null,
      bio: null,
      "contributor-role": null
    }
  ],
  contributors: [
    {
      "role-name": "author",
      authors: [
        {
          name: "சுரேஷ் கண்ணன்"
        },
        {
          name: "சுரேஷ் ஆனந்த"
        }
      ]
    },
    {
      "role-name": "photographer",
      authors: [
        {
          name: "சுரேஷ் கண்ணன்"
        }
      ]
    }
  ],
  headline:
    "மருத்துவர் ஆக வேண்டும் எனும் நம் கனவின் கரம் பற்ற இனி நீட் தேர்வுகள் ஒரு தடையாக இருக்கப் போவதில்லை. வந்தாச்சு NEETly ஆப்!",
  subheadline: "",
  alternative: {
    home: {
      default: {
        headline:
          "மருத்துவர் ஆக வேண்டும் எனும் நம் கனவின் கரம் பற்ற இனி நீட் தேர்வுகள் ஒரு தடையாக இருக்கப் போவதில்லை. வந்தாச்சு NEETly ஆப்!",
        "hero-image": {
          "hero-image-metadata": {
            width: 1088,
            height: 550,
            "mime-type": "image/jpeg",
            "focus-point": [300, 300]
          },

          "hero-image-s3-key": "vikatan/2021-11/0b739a61-4204-4090-84c1-1d6a87920122/shutterstock_six.jpg",
          "hero-image-caption": "NEETly ஆப் மூலம் உங்கள் கனவு வாழ்க்கையைத் தொடரவும்",
          "hero-image-attribution": "NEETly ஆப் மூலம் உங்கள் கனவு வாழ்க்கையைத் தொடரவும்"
        }
      }
    }
  },

  slug: "story-slug",
  "hero-image-metadata": {},
  "hero-image-s3-key": "vikatan/2021-11/0b739a61-4204-4090-84c1-1d6a87920122/shutterstock_six.jpg",
  "hero-image-caption": "some caption",
  "published-date": "ஜூலை 14",
  metadata: { "reference-url": "https://special.vikatan.com/neetly/" }
};

export default function StoryCardFullWidthWithNeet({ className = "", cardWithImageZoom = true }) {
  const storyData = getStoryData(data.story);

  const externalLink = "https://special.vikatan.com/neetly/";
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink;
  }

  return (
    <div className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}>
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        className={styles["link"]}
        href={externalLink}
        externalLink={SourceLink || externalLink}
      >
        <ResponsiveImageWithFallback
          className={styles["image-container"]}
          slug={storyData.imageS3Key}
          metadata={storyData.imageMetadata}
          alt={storyData.imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [16, 9], screenWidthCoverage: 1 },
            { aspectRatio: [16, 9], screenWidthCoverage: 0.66 }
          )}
          story={storyData}
        />
        <div className={styles["content"]}>
          <h3 className={styles["headline"]}>{storyData.headline}</h3>
        </div>
      </Link>
    </div>
  );
}

StoryCardFullWidthWithNeet.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  story: PropTypes.shape({
    "author-name": PropTypes.string,
    "contributor-role": PropTypes.shape({
      name: PropTypes.string
    }),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "story-template": PropTypes.string,
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  })
};
