import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";

import { WithLazy } from "@quintype/components";
import Iframe from "react-iframe";
import StoryCardVertical from "../../atoms/story-card-vertical";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitle from "../../atoms/CollectionTitle";
import Icon from "../../atoms/icon";
import Media from "react-media";
import { PGAdwithAd } from "../ad-free";

const MagazineSixStoryVikatanPoll = ({ collection, pagetype = "", index, stories }) => {
  const { name } = collection;

  if (stories.length < 1) {
    return null;
  }

  const [userType = "F", setType] = useState(false);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType);
    });
  }, []);

  const stack = stories
    .slice(0, 6)
    .map((story, index) => (
      <StoryCardVertical
        story={story}
        key={story.id}
        className={styles["story-card"]}
        authorName={false}
        utmURL={"home-mid-row"}
      />
    ));

  return (
    <div className={`${styles["base"]}`}>
      <div className={`${styles["base-section"]} container`}>
        <div className={`${styles["story-grid"]}`}>
          <div className={styles["left-panel"]}>
            <CollectionLink collection={collection} className={`${styles["title-grid"]}`}>
              <CollectionTitle className={styles.title} title={name} />
              <Icon type="double-arrow" className={styles["double-arrows"]} />
            </CollectionLink>
            <div className={styles["main-content"]}>{stack}</div>
          </div>
          <div className={styles["right-panel"]}>
            <div className={`${styles["vikatan-poll"]}`}>
              <Iframe
                title="vikatan poll"
                frameBorder="0"
                className={`${styles["iframe-outer"]}`}
                src="https://special.vikatan.com/vikatan-poll/index.php"
              />
            </div>
            <div className="hidden-desktop">
              <WithLazy>
                {() => (
                  <Media query="(max-width: 767px)">
                    {matches =>
                      matches ? (
                        <div>
                          {userType === "P" || userType === "T" || userType === "R" ? (
                            " "
                          ) : (
                            <div className="ad_300_250 ad-top-bottom-space">
                              <PGAdwithAd
                                adId={`Vikatan2023_Mobile_HP_MID1_336x280`}
                                adSize={[[336, 280], [300, 250]]}
                                minHeight={280}
                                adDivId="div-ad-desk-3849069-16-3"
                                class=""
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    }
                  </Media>
                )}
              </WithLazy>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MagazineSixStoryVikatanPoll.propTypes = {
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardVertical.propTypes))
  }),
  stories: PropTypes.arrayOf(StoryCardVertical.propTypes.story),
  pagetype: PropTypes.string,
  index: PropTypes.number
};

export default MagazineSixStoryVikatanPoll;
