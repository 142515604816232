import React from "react";
import PropTypes from "prop-types";

import BigStoryCard from "../big-story-card/index";

import styles from "./styles.m.css";

const StoryStackBig = ({ bigStory, mobileStoryCount, stories, className }) => {
  if (stories.length < 1) {
    return null;
  }
  const stack = [
    bigStory ? (
      <BigStoryCard story={stories[0]} key={stories[0].id} />
    ) : (
      <BigStoryCard story={stories[0]} className={`${styles["line-separater"]}`} key={stories[0].id} />
    )
  ].concat(
    stories.slice(1).map((story, index) => (
      <BigStoryCard
        story={story}
        className={`
          ${styles[index + 1 >= mobileStoryCount ? "mobile-hide" : ""]}
          ${styles[index + 2 === mobileStoryCount ? "skip-line" : ""]}
        `}
        key={story.id}
      />
    ))
  );
  return <div className={`${styles["base"]} ${className}`}>{stack}</div>;
};

StoryStackBig.propTypes = {
  bigStory: PropTypes.bool,
  mobileStoryCount: PropTypes.number,
  stories: PropTypes.arrayOf(BigStoryCard.propTypes.story),
  className: PropTypes.string
};

export default StoryStackBig;
