import React from "react";
import PropTypes from "prop-types";
import { addAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, EVOLOK } from "../../integrations";
import { VikatanDesktopSticky, VikatanSticky } from "../rows/ad-free";
import { TaboolaAd } from "../atoms/taboola-ad";

const newTemplates = addAdsToTemplates("home");

function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

export class HomePage extends React.Component {
  componentDidMount() {
    const { pageType } = this.props;
    if (pageType === "story-feed") {
      window.history.replaceState({}, "", "/");
    }
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    EVOLOK.loadScript(this.props.pageType, { collection: this.props });
    DeepBI.registerPageView({ collection: this.props });
  }

  render() {
    const { pageType } = this.props;
    return (
      <div>
        <LazyCollection
          collection={this.props.data.collection}
          config={this.props.config}
          collectionTemplates={getAdsWrappedCollectionTemplate}
          lazyAfter={1}
          accentColor={"#2f81cd"}
          pageType={pageType}
          trendingStories={this.props.data.trending}
          latestNews={this.props.data.latestNews}
          latestStories={this.props.data.latestStories}
        />
        <VikatanSticky placement="home" />
        <VikatanDesktopSticky placement="home" />
        <div className="taboola-outer container">
          <TaboolaAd
            config={{
              mode: "thumbnails-a",
              id: "taboola-below-home-thumbnails",
              placement: "Below Home Thumbnails"
            }}
            url={global && global.location && global.location.href}
            className={"taboola-article"}
            directory={"home"}
          />
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    trending: PropTypes.object,
    latestNews: PropTypes.object,
    latestStories: PropTypes.object
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
