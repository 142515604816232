import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import Contributor from "../../atoms/contributor";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import Icon from "../icon";

import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

export default function StoryCard({ story, className = "", cardWithImageZoom = true, hasTruncatedHeadline = true }) {
  const storyData = getStoryData(story);

  if (!storyData.headline || !story["url"]) {
    return null;
  }

  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");
  const externalLink = get(story, ["metadata", "reference-url"]);
  const icons = {
    "photo-album": "gallery",
    photo: "photostory",
    video: "video"
  };

  const iconType = get(icons, [story["story-template"]], null);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }
  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={externalLink || story.url}
      externalLink={SourceLink || externalLink}
    >
      <ResponsiveImageWithFallback
        className={styles["image-container"]}
        slug={storyData.imageS3Key}
        metadata={storyData.imageMetadata}
        isPremium={isPremium(story)}
        alt={storyData.imageCaption}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          { aspectRatio: [3, 2], screenWidthCoverage: 0.5 },
          { aspectRatio: [3, 2], screenWidthCoverage: 0.17 }
        )}
        story={story}
      >
        {iconType && (
          <span className={styles["story-template-icon"]}>
            <Icon type={iconType} className={styles["template-icon"]} />
          </span>
        )}
        {story.engagement &&
          story.engagement.total && (
            <span className={styles["engagement"]}>
              <Icon type="speaker" className={styles["speaker-icon"]} />
              {story.engagement.total}
            </span>
          )}
      </ResponsiveImageWithFallback>
      <div className={styles["content"]}>
        {contributor && (
          <Contributor name={contributor["name"]} type={contributorRole} className={styles["contributor"]} />
        )}
        <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
          {storyData.headline}
        </h3>
      </div>
    </Link>
  );
}

StoryCard.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  story: PropTypes.shape({
    "author-name": PropTypes.string,
    "contributor-role": PropTypes.shape({
      name: PropTypes.string
    }),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "story-template": PropTypes.string,
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  })
};
