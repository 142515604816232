import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";

const CollectionTitleWithSideLine = ({ title, className = "", design = "inner" }) => {
  if (!title) {
    return null;
  }

  return (
    <h2 className={`${styles[design]} ${className}`}>
      <span className={styles.text}>{title}</span>
    </h2>
  );
};

CollectionTitleWithSideLine.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  design: PropTypes.string
};

export default CollectionTitleWithSideLine;
