import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { fetchWCPointTable } from "../../../api";

import styles from "./styles.m.css";

const WCPointTable = ({ accentColor, homeSec = false, titleClassName }) => {
  const [matchList, setData] = useState([]);

  useEffect(() => {
    fetchWCPointTable().then(list => {
      setData(list);
    });
  }, []);
  const pointList = get(matchList, ["data", "rounds", 0, "groups", 0, "points"], []);

  return (
    <div className={`${styles["base"]} ${homeSec ? styles["homepagetable"] : styles["pagetable"]}`}>
      <div className={`${styles["point-table-wrapper"]} container`}>
        <div className={`${styles["table-grid"]}`}>
          <div className={`${styles["point-table"]}`}>
            <h6 className={`${styles["point-table-title"]} ${styles[titleClassName]}`}>Points Table</h6>
            <table className={styles["inner-table"]}>
              <thead>
                <th>Team</th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>PLD</span>
                  <span className={`${styles["point-mobile"]}`}>P</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Won</span>
                  <span className={`${styles["point-mobile"]}`}>W</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Lost</span>
                  <span className={`${styles["point-mobile"]}`}>L</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Tied</span>
                  <span className={`${styles["point-mobile"]}`}>T</span>
                </th>
                <th>N/R</th>
                <th>Net RR</th>
                <th>Pts</th>
              </thead>
              <tbody>
                {pointList.length > 0 &&
                  pointList.map((list, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <table className={styles["sub-table"]}>
                            <tr>
                              <td>
                                <span className={`${styles["img-sec"]} ${styles[list.team.key]}`} />
                                <span>{list.team.name}</span>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>{list.played}</td>
                        <td>{list.won}</td>
                        <td>{list.lost}</td>
                        <td>{list.tied}</td>
                        <td>{list.no_result}</td>
                        <td>{list.net_run_rate}</td>
                        <td>{list.points}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className={styles["table-word-details"]}>
              <span>
                <b>P</b> - PLD
              </span>
              <span>
                <b>W</b> - Won
              </span>
              <span>
                <b>L</b> - Lost
              </span>
              <span>
                <b>T</b> - Tied
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
WCPointTable.propTypes = {
  accentColor: PropTypes.string,
  homeSec: PropTypes.string,
  titleClassName: PropTypes.string
};

export default WCPointTable;
