import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFullWidthCurve from "../../atoms/story-card-full-width-curve";
import StoryCardVerticalContent from "../../atoms/story-card-vertical-content";
import CollectionTitleWithCTA from "../../atoms/CollectionTitleWithCTA";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

export const EightStory8sTemplate = ({ collection, Icon }) => {
  const { items } = collection;
  const MaxNumberOfThumbnailStories = 6;
  const MaxNumberOfMainStories = 2;
  return (
    <div className={`${styles["seven-story-7s"]}`}>
      <div className={`${styles["container-story"]} container`}>
        <div className={styles["collection-title"]}>
          <CollectionLink collection={collection}>
            <CollectionTitleWithCTA className={styles.title} title={collection.name} />
          </CollectionLink>
        </div>
        <div className={`${styles["story-card-grid"]}`}>
          {items &&
            items.length > 1 &&
            items
              .slice(1, MaxNumberOfMainStories + 1)
              .map(item => (
                <StoryCardFullWidthCurve className={styles["story-card-full-width"]} key={item.id} story={item.story} />
              ))}

          <div className={styles["gradient-container"]}>
            <div className={styles["thumb-story-container"]}>
              {items &&
                items.length > 1 &&
                items
                  .slice(1, MaxNumberOfThumbnailStories + 1)
                  .map(item => (
                    <StoryCardVerticalContent className={styles["story-card"]} key={item.id} story={item.story} />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const VikatanTV = ({ collection, BgPattern = "", accentColor }) => {
  const VikatanTVIcon = () => <Icon type="vikatan-tv" className={styles.icon} />;
  return (
    <EightStory8sTemplate
      style={accentColor && { "--accent-color": accentColor }}
      collection={collection}
      BgPattern={BgPattern}
      Icon={VikatanTVIcon}
    />
  );
};

VikatanTV.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFullWidthCurve.propTypes))
  }),
  BgPattern: PropTypes.string
};

EightStory8sTemplate.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFullWidthCurve.propTypes))
  }),
  Icon: PropTypes.func,
  BgPattern: PropTypes.string
};
EightStory8sTemplate.storyLimit = 8;

export default EightStory8sTemplate;
