import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import produce from "immer";
import Carousel from "../../atoms/carousel";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithCrossLineBundle from "../../atoms/CollectionTitleWithCrossLineBundle";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import AuthorDetailCard from "../../pages/story/story-templates/shared/author-detail-series-card";
import styles from "./styles.m.css";
import { getStoryHeadline, checkAppSlug, generateImageSources } from "../../utils/utils";
import Icon from "../../atoms/icon";

const CollectionCard = ({ collection, hasTruncatedHeadline = true, className = "", type = "small", config }) => {
  if (!collection) {
    return null;
  }
  const headline = get(collection, ["name"], "");
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["slug"], "");
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");

  const Author = get(collection, ["items", 0, "story", "authors"], []);

  if (!(headline && slug)) {
    return null;
  }

  const viSelectPagePath = slug => {
    if (!slug) return null;

    return "/tamil-web-series" + "/" + slug;
  };

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = viSelectPagePath(slug);
  }
  return (
    <div className={`${className} ${styles["story-card"]}`}>
      <div className={styles["image-container"]}>
        <Link href={viSelectPagePath(slug)} externalLink={SourceLink}>
          <ResponsiveImageWithFallback
            className={styles["image-container-figure"]}
            slug={coverImageS3Key}
            metadata={coverImageMetaData}
            alt={imageCaption}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
              { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
            )}
          />
        </Link>
      </div>

      <div className={styles["content"]}>
        <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `} />
        <span className={styles.highlight}>
          <Link href={viSelectPagePath(slug)} externalLink={SourceLink}>
            {collection.name}
          </Link>
        </span>

        <h4 className={`${styles["subheadline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
          <Link href={viSelectPagePath(slug)} externalLink={SourceLink}>
            {collection.summary}
          </Link>
        </h4>

        {Author.length >= 0 &&
          type === "big" && (
            <div className={`${styles["author-section"]}`}>
              <AuthorDetailCard authors={Author} config={config} className={styles["author-detail"]} limit={1} />
            </div>
          )}
      </div>
    </div>
  );
};

CollectionCard.propTypes = {
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  config: PropTypes.object,
  type: PropTypes.string,
  story: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    )
  }),
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

class ThreeCollectionSliderRound extends Component {
  constructor() {
    super();
    this.state = {
      currentSlideIndex: 0,
      ShowCarousle: false
    };
    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        ShowCarousle: true
      });
    }, 10);
  }

  handleSlideChange(index) {
    this.setState({ currentSlideIndex: index });
  }

  render() {
    const { collection, config } = this.props;

    if (!collection) {
      return null;
    }

    const { items } = collection;

    let stacks =
      items &&
      items.filter(item => item.type === "collection").map((collection, index) => {
        collection = produce(collection, draft => {
          draft.items = draft.items.filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null);
        });
        return (
          <CollectionCard
            key={collection.id}
            collection={collection}
            className={styles["story-card-small"]}
            type="small"
            config={config}
          />
        );
      });

    const Items = get(collection, ["items"], "");
    return (
      <div
        className={`${styles["base"]}`}
        style={this.props.accentColor && { "--accent-color": this.props.accentColor }}
      >
        <div className={`container`}>
          {collection.name && (
            <CollectionLink collection={collection}>
              <CollectionTitleWithCrossLineBundle className={styles.title} title={collection.name} />
            </CollectionLink>
          )}
          <CollectionCard
            collection={Items[this.state.currentSlideIndex]}
            className={styles["story-card-big"] + (stacks.length === 1 ? ` ${styles["show-on-mobile"]}` : "")}
            type="big"
          />

          {this.state.ShowCarousle &&
            stacks.length > 1 && (
              <div className={styles["carousel-wrapper"]}>
                <Carousel
                  className={styles["carousel"]}
                  afterSlide={this.handleSlideChange}
                  options={{
                    type: "carousel",
                    autoplay: false,
                    perView: 3,
                    classes: {
                      activeSlide: styles["is-card-active"]
                    },
                    breakpoints: {
                      320: {
                        perView: 1.5
                      },
                      767: {
                        perView: 1.3
                      }
                    }
                  }}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      aria-label="previous slide"
                      role="button"
                      onClick={previousSlide}
                      className={`${styles["slider-nav-button"]}`}
                    >
                      <Icon type={"slider-prev"} className={styles["slider-button"]} />
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      aria-label="next slide"
                      role="button"
                      onClick={nextSlide}
                      className={`${styles["slider-nav-button"]}`}
                    >
                      <Icon type={"slider-next"} className={styles["slider-button"]} />
                    </button>
                  )}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                    return (
                      <div className={styles.bullets}>
                        {Items.map((item, index) => (
                          <button
                            aria-label="Slider Navigation"
                            role="button"
                            key={item.id}
                            className={
                              currentSlide === index
                                ? `${styles["bullet"]} ${styles["is-active"]}`
                                : `${styles["bullet"]}`
                            }
                            onClick={() => {
                              goToSlide(index);
                            }}
                          />
                        ))}
                      </div>
                    );
                  }}
                >
                  {stacks}
                </Carousel>
              </div>
            )}
        </div>
      </div>
    );
  }
}

ThreeCollectionSliderRound.propTypes = {
  storyCount: PropTypes.number,
  config: PropTypes.object,
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(CollectionCard.propTypes))
  })
};

export default ThreeCollectionSliderRound;
