import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import MagazineSlider from "../magazine-slider/index";
import MagazineYearlySubscription from "../../atoms/magazine-yearly-subscription";

import styles from "./styles.m.css";

const MagazineSubscription = ({ collection, metadata, accentColor }) => {
  if (!collection) {
    return null;
  }
  const magazines = get(collection, ["items"], []).filter(item => item["type"] === "collection");
  if (magazines.length === 0) {
    return null;
  }
  return (
    <div className={styles["subscription-row"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className="row">
          <div className={styles["magazine-slider-wrap"]}>
            <MagazineSlider items={magazines} className={styles["magazine-slider"]} />
          </div>
          <MagazineYearlySubscription subscription={metadata} className={styles["magazine-subscription"]} />
        </div>
      </div>
    </div>
  );
};

MagazineSubscription.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    items: MagazineSlider.propTypes.items
  }),
  metadata: PropTypes.shape(MagazineYearlySubscription.propTypes)
};

export default MagazineSubscription;
