import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import styles from "./styles.m.css";

export const WCMatchCard = ({ item, className = "" }) => {
  const relatedName = get(item, ["sub_title"], " ");
  const shortName = get(item, ["short_name"], "");
  const status = get(item, ["status"], " ");
  const winner = get(item, ["winner"], "");
  const teamsA = get(item, ["teams", "a", "name"], " ");
  const teamsB = get(item, ["teams", "b", "name"], " ");
  const teamsAkey = get(item, ["teams", "a", "key"], " ");
  const teamsBkey = get(item, ["teams", "b", "key"], " ");
  const winnerTeam = get(item, ["teams", winner, "name"], "");
  // const inningsA = get(item, ["innings", "a_1", "run_str"], " ");
  const inningsB = get(item, ["innings", "b_1", "run_str"], " ");
  const locationName = get(item, ["venue", "city"], "");
  const startdate = get(item, ["start_at"], "");
  const newdate = new Date(startdate * 1000);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[newdate.getMonth()];
  const date = newdate.getDate();
  const options = { weekday: "short" }; // Use 'short' to get day abbreviations (e.g., 'FRI')
  const dayOfWeek = newdate.toLocaleDateString("en-US", options);

  return (
    <React.Fragment>
      <div className={styles["ipl-card"]}>
        <div className={styles["shortname"]}>
          {shortName && (
            <div className={styles["headline"]}>
              <span>{relatedName}</span>
              <span className={styles["formattedtime"]}>{`${dayOfWeek}, ${month} ${date}`}</span>{" "}
            </div>
          )}
        </div>
        <div className={styles["ipl-card-grid"]}>
          <ul className={styles["ipl-score-list"]}>
            <li className={styles["ipl-score"]}>
              <div className={`${styles["image-view"]} ${styles[teamsAkey]}`} />
              <div className={styles["team-name"]}>{teamsA}</div>
              {/* <div className={styles["ipl-score-pnts"]}>
                  <span>{inningsA}</span>
                </div> */}
            </li>
          </ul>
          <div className={styles["match-name"]}>
            <div className={styles["match-name-tag"]}>
              {status === "not_started" && (
                <div>
                  <p>
                    <span className={styles["score-tag-notstarted"]}>Upcoming</span>
                  </p>
                </div>
              )}
              {status === "completed" && (
                <div>
                  <p>
                    <span className={styles["score-tag-completed"]}>{status}</span>
                  </p>
                </div>
              )}
              {status === "started" && (
                <div>
                  <p>
                    <span className={styles["score-tag-started"]}>Live</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <ul className={styles["ipl-score-list"]}>
            <li className={styles["ipl-score"]}>
              <div className={`${styles["image-view"]} ${styles[teamsBkey]}`} />
              <div>{teamsB}</div>
              <div className={styles["ipl-score-pnts"]}>
                <span>{inningsB}</span>
              </div>
            </li>
          </ul>
        </div>
        {winnerTeam ? (
          <div className={styles["winner-status"]}>{winnerTeam} Won the Match</div>
        ) : (
          <div className={styles["winner-status"]}>{locationName}</div>
        )}
      </div>
    </React.Fragment>
  );
};

WCMatchCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape()
};

export default WCMatchCard;
