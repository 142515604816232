import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import Carousel from "../../atoms/carousel";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import styles from "./styles.m.css";
import { getStoryHeadline, checkAppSlug, getStoryData, generateImageSources } from "../../utils/utils";
import Icon from "../../atoms/icon";

const StoryCard = ({ story, className = "", type = "small", hasTruncatedHeadline = true }) => {
  if (!story) {
    return null;
  }

  const storyData = getStoryData(story);

  if (!(storyData.headline && story.url)) {
    return null;
  }

  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = story.url;
  }

  return (
    <div className={styles["big-card-container"]}>
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        href={`${story.url}?pfrom=vikatan-podcast-home`}
        externalLink={SourceLink}
        className={`${className} ${styles["story-card"]}`}
      >
        <Icon type="podcast" className={styles["podcast-icon"]} />
        <Icon type="video-play" className={styles["play-icon"]} />

        <ResponsiveImageWithFallback
          className={styles["image-container"]}
          slug={storyData.imageS3Key}
          metadata={storyData.imageMetadata}
          alt={storyData.imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [16, 9], screenWidthCoverage: 1 },
            { aspectRatio: [16, 9], screenWidthCoverage: 0.66 }
          )}
        />

        <div className={styles["content"]}>
          <div className={styles["content-txt"]}>
            <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
              <span>{story["headline"]}</span>
            </h3>
            {type === "big" && (
              <h4 className={`${styles["subheadline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
                {story["subheadline"]}
              </h4>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

StoryCard.propTypes = {
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  story: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    )
  })
};

class PodcastFourCollection extends Component {
  constructor() {
    super();
    this.state = {
      currentSlideIndex: 0,
      ShowCarousle: false
    };
    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        ShowCarousle: true
      });
    }, 10);
  }

  handleSlideChange(index) {
    this.setState({ currentSlideIndex: index });
  }

  render() {
    const { collection } = this.props;
    if (!collection) {
      return null;
    }

    const items = ([...collection.items] || [])
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, this.props.storyCount || 5);
    let slides = items.map(({ story }) => (
      <StoryCard className={styles["story-card-small"]} key={story.id} story={story} type="small" />
    ));

    return (
      <div
        className={`${styles["base"]}`}
        style={this.props.accentColor && { "--accent-color": this.props.accentColor }}
      >
        <div className={`container`}>
          <div className={styles["podacst-main-title"]}>
            <Icon type="podcast" />
            <span>விகடன் Podcast</span>
          </div>
          <StoryCard
            story={items[this.state.currentSlideIndex].story}
            className={styles["story-card-big"] + (slides.length === 1 ? ` ${styles["show-on-mobile"]}` : "")}
            type="big"
          />

          {slides.length > 1 && (
            <div className={styles["carousel-wrapper"]}>
              <Carousel
                className={styles["carousel"]}
                afterSlide={this.handleSlideChange}
                options={{
                  type: "carousel",
                  autoplay: false,
                  perView: 3,
                  classes: {
                    activeSlide: styles["is-card-active"]
                  },
                  breakpoints: {
                    767: {
                      perView: 1
                    }
                  }
                }}
                renderCenterLeftControls={({ previousSlide }) => (
                  <button
                    aria-label="previous slide"
                    role="button"
                    onClick={previousSlide}
                    className={`${styles["slider-nav-button"]}`}
                  >
                    <Icon type={"slider-prev"} className={styles["slider-button"]} />
                  </button>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <button
                    aria-label="next slide"
                    role="button"
                    onClick={nextSlide}
                    className={`${styles["slider-nav-button"]}`}
                  >
                    <Icon type={"slider-next"} className={styles["slider-button"]} />
                  </button>
                )}
                renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                  return (
                    <div className={styles.bullets}>
                      {items.map(({ story }, index) => (
                        <button
                          aria-label="Slider Navigation"
                          role="button"
                          key={story.id}
                          className={
                            currentSlide === index
                              ? `${styles["bullet"]} ${styles["is-active"]}`
                              : `${styles["bullet"]}`
                          }
                          onClick={() => {
                            goToSlide(index);
                          }}
                        />
                      ))}
                    </div>
                  );
                }}
              >
                {slides}
              </Carousel>
            </div>
          )}
        </div>
      </div>
    );
  }
}

PodcastFourCollection.propTypes = {
  storyCount: PropTypes.number,
  config: PropTypes.object,
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCard.propTypes))
  })
};

export default PodcastFourCollection;
