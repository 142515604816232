import React from "react";
import PropTypes from "prop-types";
import SliderMain from "../../atoms/slider-main/index";
import CollectionCoverImage from "../../atoms/collection-cover-image";
import Iframe from "react-iframe";

import styles from "./styles.m.css";

const SliderCollectionStoriesBundle = ({ stories }) => {
  return (
    <div className={styles["slider-collection-stories-bundle"]}>
      <div className={styles["iframe-author"]}>
        <Iframe
          url="https://special.vikatan.com/forms/kids-stories/form.php?type=data"
          width="100%"
          height="450px"
          frameBorder="0"
        />
      </div>
      <div className={styles["slider-stories-wrap"]}>
        <SliderMain stories={stories.slice(0, 5)} />
      </div>
      <div className={styles["iframe-form"]}>
        <Iframe
          url="https://special.vikatan.com/forms/kids-stories/form.php?type=form"
          width="100%"
          height="450px"
          frameBorder="0"
        />
      </div>
    </div>
  );
};

SliderCollectionStoriesBundle.propTypes = {
  stories: SliderMain.propTypes.stories
};

const CollectionStories = ({ collections }) => {
  return (
    <div className={styles["five-story-container"]}>
      {collections && (
        <div className={styles["collections-image"]}>
          {collections
            .slice(0, 1)
            .map(
              collection =>
                collection && (
                  <CollectionCoverImage
                    key={collection.id}
                    collection={collection}
                    className={styles["collection-item"]}
                  />
                )
            )}
        </div>
      )}
    </div>
  );
};

CollectionStories.propTypes = {
  collections: PropTypes.arrayOf(CollectionCoverImage.propTypes.collection)
};

const KidsStories = ({ collection, accentColor, pagetype = "" }) => {
  if (!collection.items) {
    return null;
  }
  const stories = collection.items.filter(item => item && item.type === "story").map(item => item.story);
  const collections = collection.items.filter(item => item && item.type === "collection");

  const collectionBundle = collections.filter(item => item && item.type === "collection");

  return (
    <div className={styles["main-row"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <CollectionStories collections={collectionBundle} />
        {stories &&
          stories.length > 0 && (
            <SliderCollectionStoriesBundle stories={stories.slice(0, 7)} collections={collectionBundle} />
          )}
      </div>
    </div>
  );
};

KidsStories.propTypes = {
  accentColor: PropTypes.string,
  collection: CollectionCoverImage.propTypes.collection,
  pagetype: PropTypes.string
};

export default KidsStories;
