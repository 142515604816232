import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Carousel from "../../atoms/carousel";
import MatchCard from "../../atoms/match-card";
import get from "lodash/get";
import { fetchRecentMatch } from "../../../api";

import styles from "./styles.m.css";

const IplRecentMachCard = ({
  numberOfCardsInViewDesktop = 3,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1,
  accentColor
}) => {
  const desktopGap = 30;
  const mobileGap = 0;

  const [matchList, setData] = useState([]);

  useEffect(() => {
    fetchRecentMatch().then(list => {
      setData(list);
    });
  }, []);
  console.log("matchList", matchList);
  const listCard1 = get(matchList, ["data", "cards", 0], []);
  const listCard2 = get(matchList, ["data", "cards", 1], []);
  const listCard3 = get(matchList, ["data", "cards", 2], []);
  const listCard4 = get(matchList, ["data", "cards", 3], []);
  const listCard5 = get(matchList, ["data", "cards", 4], []);
  const listCard6 = get(matchList, ["data", "cards", 5], []);

  return (
    <div className={styles.base}>
      <div className={`container ${styles["carousel-wrapper"]}`}>
        <Carousel
          className={styles["carousel"]}
          options={{
            type: "slider",
            perView: numberOfCardsInViewDesktop,
            gap: desktopGap,
            focusAt: 0,
            startAt: 3,
            bound: true,
            breakpoints: {
              767: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap
              },
              991: {
                perView: numberOfCardsInViewTablet,
                gap: desktopGap
              }
            }
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => null}
          renderCenterRightControls={({ nextSlide }) => null}
        >
          <MatchCard key={1} item={listCard1} className={styles.card} />
          <MatchCard key={2} item={listCard2} className={styles.card} />
          <MatchCard key={3} item={listCard3} className={styles.card} />
          <MatchCard key={4} item={listCard4} className={styles.card} />
          <MatchCard key={5} item={listCard5} className={styles.card} />
          <MatchCard key={6} item={listCard6} className={styles.card} />
        </Carousel>
      </div>
    </div>
  );
};

IplRecentMachCard.propTypes = {
  accentColor: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default IplRecentMachCard;
