import React from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import SliderNew from "../../atoms/slider-new/index";
import styles from "./styles.m.css";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithSideLine from "../../atoms/CollectionTitleWithSideLine";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const StorySliderWithIframe = ({ collection, accentColor }) => {
  const { name } = collection;
  const stories = collection.items.filter(item => item && item.type === "story").map(item => item.story);
  return (
    <div>
      <div className={`${styles["faq-main"]} container`}>
        <div className={styles["grid-one-two"]}>
          <div className={styles["faq-div"]}>
            <CollectionLink collection={collection}>
              <CollectionTitleWithSideLine title={name} design="outer" />
            </CollectionLink>
            <SliderNew stories={stories.slice(0, 5)} dots={false} />
          </div>
          <div className={styles["iframe-form"]}>
            <Iframe
              url={`https://special.vikatan.com/election/survey/index.php?survierId=${cookies.get("thinmint")}`}
              width="100%"
              frameBorder="0"
              className={styles["faq-form"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StorySliderWithIframe.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(),
    accentColor: PropTypes.string
  })
};
StorySliderWithIframe.storyLimit = 4;

export default StorySliderWithIframe;
