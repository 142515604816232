import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import { MagazineCard } from "../../../../../atoms/magazine-buy-card";
import { get } from "lodash";

const MagazineListCard = ({ collection, homeMagazine, utmURL = "", config }) => {
  const { items } = collection;
  let stacks =
    items &&
    items.map((item, index) => {
      const magazineEntityId = get(item, ["metadata", "entities", "collectionEntities", "magazine", 0, "id"]);
      const magazineConfig = config.magazines && config.magazines.find(m => m.id === magazineEntityId);
      const magazinePath = get(magazineConfig, ["url"], "");
      item.pagePath = magazinePath;

      return (
        <MagazineCard
          collection={item}
          key={index}
          className={styles.card}
          homeMagazine={homeMagazine}
          utmURL={utmURL}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }
  return (
    <div className={`${styles["base"]}`}>
      <div className={styles["carousel-wrapper"]}>{stacks}</div>
    </div>
  );
};

MagazineListCard.propTypes = {
  homeMagazine: PropTypes.bool,
  utmURL: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  config: PropTypes.shape()
};

export default MagazineListCard;
