import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithSideLine from "../../atoms/CollectionTitleWithSideLine";
import StoryCardFullImg from "../../atoms/story-card-full-img-1-3";
import StoryStack from "../../atoms/story-stack/index";
import ConstituencySearchBar from "../../atoms/constituency-searchbar";
import styles from "./styles.m.css";

import { wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";

import { DfpAd } from "../../atoms/dfp-ad";

const WrappedStoryStack = wrapCollectionLayout(StoryStack);

const mobileStoryCount = 5;
const desktopOddStoryCount = 5;
const desktopEvenStoryCount = 3;

const EightStory4CWithBanner = ({ collection, metadata, accentColor, pagetype = "" }) => {
  const { items } = collection;
  let stacks = items
    .filter(item => item.type === "collection")
    .slice(0, 2)
    .map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, index % 2 === 0 ? desktopOddStoryCount : desktopEvenStoryCount);
      });
      return (
        <React.Fragment key={collection.id}>
          <CollectionLink collection={collection} className={styles["sub-collection-link"]}>
            {/* <h2 className={styles["sub-collection-headline"]}>{collection.name}</h2> */}
            <CollectionTitleWithSideLine
              title={collection.name}
              design="outer"
              className={styles["sub-collection-headline"]}
            />
          </CollectionLink>
          <WrappedStoryStack
            className={styles["sub-collection-story"]}
            bigStory={index === 0}
            bigStoryHover={true}
            mobileStoryCount={mobileStoryCount}
            collection={collection}
          />
        </React.Fragment>
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }
  return (
    <div className={styles["base"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`${styles["container"]} container`}>
        <div>
          <div className={styles["stack-grid"]}>{stacks}</div>
          <ConstituencySearchBar />
        </div>
        <div className={styles["right-adz-slot"]}>
          <div className={styles["widget300xauto"]}>
            <DfpAd className={`${styles.ad}`} adtype="Rectangle" placement={"election"} />
          </div>
          <div className={styles["ugc-form"]}>
            <a href="/tamil-nadu-election-candidates-list-2021?pfrom=home" target="new">
              <img
                // eslint-disable-next-line max-len
                src="https://gumlet.assettype.com/vikatan/2021-04/04e3f9ba-3ae1-405c-940e-139c3910ae9e/WhatsApp_Image_2021_04_02_at_20_47_08.jpeg"
                alt="candidate-list"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(StoryCardFullImg.propTypes))
});

EightStory4CWithBanner.propTypes = {
  accentColor: PropTypes.string,
  pagetype: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  })
};

export default EightStory4CWithBanner;
