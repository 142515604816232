import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import Iframe from "react-iframe";

const LoksabhaElectionPoll = () => {
  return (
    <div className={`${styles["base"]}`}>
      <div className={`${styles["iframe-container"]} container`}>
        <div className={`${styles["iframe-box"]}`}>
          <Iframe
            title="vikatan poll"
            frameBorder="0"
            className={`${styles["iframe-outer"]}`}
            src="https://special.vikatan.com/election_quiz_poll/index.php"
          />
        </div>
      </div>
    </div>
  );
};

LoksabhaElectionPoll.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  showTitle: PropTypes.bool
};

export default LoksabhaElectionPoll;
