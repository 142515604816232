import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SubdomainsBigStoryCard from "../../atoms/big-story-card-subdomain";
import SubdomainsSmallStoryCard from "../../atoms/small-story-card-subdomains";
import { get } from "lodash";
import styles from "./styles.m.css";
import { OtherDomainCollection } from "../../../api";
import { Link } from "@quintype/components";

const SubdomainsStoryCard = ({ collection, mobileStoryCount }) => {
  const slug = get(collection, ["slug"]);
  const ImageUrl = get(collection, ["metadata", "cover-image", "cover-image-url"]);
  const [items, setitems] = useState([]);
  useEffect(() => {
    OtherDomainCollection(slug)
      .then(res => {
        if (res && res.items) {
          res && setitems(res && res.items);
        }
      })
      .catch(error => {
        console.error("Error", error);
      });
  }, []);

  return (
    <div className={`${styles["four-story-half-featured"]}`}>
      <div className={`${styles["four-story-content"]} container`}>
        <div className={`${styles["subdomain-title"]}`}>
          <Link
            href={
              slug && slug === "timepassonline-latest-news"
                ? "https://timepassonline.vikatan.com/?utm_source=vikatan-web"
                : "https://newssense.vikatan.com/?utm_source=vikatan-web"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ImageUrl}
              alt="logo"
              className={`
            ${styles[slug && slug === "timepassonline-latest-news" ? "subdomain-logo-tp" : "subdomain-logo"]}

          `}
            />
          </Link>
        </div>

        <div className={styles["base"]}>
          {items &&
            items.length > 0 && (
              <SubdomainsBigStoryCard
                className={styles["story-card-featured-big"]}
                key={items[0].id}
                story={items[0].story}
                titleColor="#fff"
              />
            )}
          {items &&
            items.length > 1 &&
            items.slice(1, 7).map((items, index) => (
              // items.slice(1, 7).map(item => (
              <SubdomainsSmallStoryCard
                // className={styles["story-card-featured-small"]}
                key={items.id}
                story={items.story}
                titleColor="#fff"
                className={`
          ${styles[index + 1 >= mobileStoryCount ? "" : ""]}
          ${styles["line-separater"]}
          ${styles[index + 2 === mobileStoryCount ? "skip-line" : ""]}
          ${styles[index % 2 === 0 ? "withborder" : "withborder-left"]}
        `}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

SubdomainsStoryCard.propTypes = {
  accentColor: PropTypes.string,
  mobileStoryCount: PropTypes.number,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(SubdomainsBigStoryCard.propTypes)),
    accentColor: PropTypes.string
  })
};
SubdomainsStoryCard.storyLimit = 4;

export default SubdomainsStoryCard;
