import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";

import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import Contributor from "../contributor";

import { getStoryData, generateImageSources } from "../../utils/utils";
import { PremiumBadge } from "../../atoms/premium-badge";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

export const WebStoriesFocusedCard = ({ story, className = "", cardWithImageZoom = true }) => {
  const storyData = getStoryData(story);

  if (!storyData.headline || !story.url) {
    return null;
  }

  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");

  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={story.url}
      externalLink={story.url}
      target="_blank"
    >
      <div className={styles["base"]}>
        <ResponsiveImageWithFallback
          className={styles["image-wrapper"]}
          slug={storyData.imageS3Key}
          metadata={storyData.imageMetadata}
          alt={storyData.imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [3, 4], screenWidthCoverage: 0.3 },
            { aspectRatio: [2, 3], screenWidthCoverage: 0.2 }
          )}
          story={story}
        />
        <div className={styles["webstories-icon"]}>
          <Icon type="stories" className={styles["search"]} />
          <p>Web Stories</p>
        </div>
        <div className={styles["text-wrapper"]}>
          <h3 className={`${styles["headline"]}`}>
            <span className={styles.highlight}>{storyData.headline}</span>
          </h3>
          {contributor && (
            <Contributor
              name={contributor["name"]}
              type={contributorRole}
              iconColor="#4a4a4a"
              className={styles["contributor"]}
            />
          )}
          <div className={styles["dots"]}>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
          </div>
          <PremiumBadge isPremium={isPremium(story)} positionClass="bottomLeft" />
        </div>
      </div>
    </Link>
  );
};

WebStoriesFocusedCard.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  })
};

export default WebStoriesFocusedCard;
