import React from "react";
import PropTypes from "prop-types";

import Carousel from "../carousel";

import MainsliderFocusedCardBig from "../main-slider-focused-card-big";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const BigStorySlider = ({ stories, className = "" }) => {
  stories = stories.filter(story => story.url && getStoryHeadline(story));
  if (stories.length === 0) {
    return null;
  }
  let slides = stories.map(story => <MainsliderFocusedCardBig className={styles.card} key={story.id} story={story} />);

  if (slides.length === 1) {
    return <div>{slides}</div>;
  }

  return (
    <div>
      <Carousel
        className={`${styles["first-slider"]} ${className}`}
        options={{
          autoplay: 3000,
          type: "carousel"
        }}
        renderCenterLeftControls={({ previousSlide }) => {
          return null;
        }}
        renderCenterRightControls={({ nextSlide }) => {
          return null;
        }}
        renderBottomCenterControls={({ currentSlide, goToSlide }) => {
          return null;
        }}
      >
        {slides}
      </Carousel>
    </div>
  );
};

BigStorySlider.propTypes = {
  stories: PropTypes.arrayOf(MainsliderFocusedCardBig.propTypes.story),
  className: PropTypes.string
};

export default BigStorySlider;
