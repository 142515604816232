import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Carousel from "../../atoms/carousel";
import Icon from "../../atoms/icon";
import assetify from "@quintype/framework/assetify";
import win from "../../../../assets/images/win.gif";
import lose from "../../../../assets/images/lose.png";
import leading from "../../../../assets/images/leading.gif";
import back from "../../../../assets/images/back.png";
import BigSliderSlide from "../../atoms/big-slider-slide/index";
import styles from "./styles.m.css";
import { fetchAllCandidatesList } from "../../../api";
import { checkAppSlug } from "../../utils/utils";

const StarCandidateResult = ({
  accentColor,
  numberOfCardsInViewDesktop = 4,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1
}) => {
  const [constituency, setConstituency] = useState([]);
  const desktopGap = 30;
  const mobileGap = 10;
  useEffect(() => {
    fetchAllCandidatesList()
      .then(res => {
        const conRes = get(res, ["data", "items"]);
        setConstituency(conRes);
      })
      .catch(console.error);
  }, []);

  let link = "";
  if (checkAppSlug()) {
    link = "?source=app&type=webview";
  }

  const candidateList = constituency.filter(item => item.election_result_home === 1);

  let slides =
    candidateList.length !== 0 &&
    candidateList.map((item, index) => {
      let icon = null;
      if (item.election_result === "பின்னடைவு") {
        icon = back;
      }

      if (item.election_result === "முன்னிலை") {
        icon = leading;
      }

      if (item.election_result === "வெற்றி") {
        icon = win;
      }

      if (item.election_result === "தோல்வி") {
        icon = lose;
      }

      return (
        <div className={styles["box-div"]} key={"slide-" + index}>
          <div className={styles["title"]}>
            <h1>{item.party_name}</h1>
          </div>
          <div className={styles["other-img"]}>
            <img src={`${item.candidate_image}`} className={styles["mem-img"]} />
            <h1 className={styles["canditate-name"]}>{item.candidate_name}</h1>
          </div>
          <div className={styles["result-img"]}>
            {icon && <img alt="Vikatan" src={assetify(icon)} className={styles["result"]} />}
          </div>
        </div>
      );
    });

  return (
    <div className={`${styles["base"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className={styles["star-canditate-wrapper"]}>
          <h1 className={styles["star-title"]}>நட்சத்திர வேட்பாளர்கள்</h1>
          {candidateList.length !== 0 && (
            <Carousel
              className={styles["carousel"]}
              options={{
                type: "carousel",
                autoPlay: false,
                perView: numberOfCardsInViewDesktop,
                gap: desktopGap,
                rewind: false,
                peek: {
                  before: 0,
                  after: 244
                },
                breakpoints: {
                  767: {
                    perView: numberOfCardsInViewMobile,
                    gap: mobileGap,
                    peek: {
                      before: mobileGap,
                      after: 144
                    }
                  },
                  991: {
                    perView: numberOfCardsInViewTablet,
                    gap: desktopGap,
                    peek: {
                      before: desktopGap,
                      after: 244
                    }
                  }
                }
              }}
              renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                currentSlide > 0 ? (
                  <button
                    aria-label="previous slide"
                    role="button"
                    onClick={previousSlide}
                    className={`${styles["slider-nav-button"]}`}
                  >
                    <Icon type="angle-left" className={styles.icon} />
                  </button>
                ) : null
              }
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  aria-label="next slide"
                  role="button"
                  onClick={nextSlide}
                  className={`${styles["slider-nav-button"]}`}
                >
                  <Icon type="angle-right" className={styles.icon} />
                </button>
              )}
              childClass={styles["glide_li"]}
            >
              {slides}
            </Carousel>
          )}
          <div className={`${styles["read-more"]}`}>
            <a
              href={`/tamil-nadu-election-candidates-result-list-2021${link}`}
              className={`${styles["read-more-link"]}`}
            >
              முழு விவரங்களுக்கு
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

StarCandidateResult.propTypes = {
  accentColor: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  stories: PropTypes.arrayOf(BigSliderSlide.propTypes.story)
};

export default StarCandidateResult;
