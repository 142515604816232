import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import Iframe from "react-iframe";

const TnElectionResult = () => {
  return (
    <div className={`${styles["base"]}`}>
      <div className={`container`}>
        <div className={`${styles["min"]}`}>
          <Iframe
            frameBorder="0"
            className={styles["floris-bar"]}
            src="https://flo.uri.sh/visualisation/6796930/embed"
            scrolling="no"
          />
        </div>
      </div>
    </div>
  );
};

TnElectionResult.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  showTitle: PropTypes.bool
};

export default TnElectionResult;
