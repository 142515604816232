import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { fetchDistrick, fetchConstituency } from "../../../api";
import translate from "../../../../assets/images/translate.png";
import assetify from "@quintype/framework/assetify";
import Select from "react-select";
import { Link } from "@quintype/components";
import Icon from "../../atoms/icon";

import styles from "./styles.m.css";
import { checkAppSlug } from "../../utils/utils";

class AutoSelectComponent extends React.Component {
  logChange = val => {
    const { SelectedValue } = this.props;
    SelectedValue(val.value);
  };

  render() {
    const { options, className } = this.props;
    return <Select className={styles[className]} options={options} onChange={this.logChange} />;
  }
}

AutoSelectComponent.propTypes = {
  options: PropTypes.array,
  SelectedValue: PropTypes.func,
  className: PropTypes.string
};

const ConstituencySearchBar = () => {
  const [districk, setDistrick] = useState([]);
  const [constituency, setConstituency] = useState([]);

  const [selectedDistrick, setSelectedDistrick] = useState("");
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  useEffect(() => {
    fetchDistrick()
      .then(res => {
        const disRes = get(res, ["data", "items"]);
        setDistrick(disRes);
      })
      .catch(console.error);

    fetchConstituency()
      .then(res => {
        const conRes = get(res, ["data", "items"]);
        setConstituency(conRes);
      })
      .catch(console.error);
  }, []);

  const disOp =
    districk &&
    districk.map(item => {
      const objItem = {};
      objItem.label = `${item.District_Tm} (${item.District_En})`;
      objItem.value = item.Id;
      return objItem;
    });

  const contOp =
    constituency &&
    constituency.filter(i => selectedDistrick === i.thokkuthi_district).map(item => {
      const objItem = {};
      objItem.label = `${item.area}`;
      objItem.value = item.thokkuthi_slug;
      return objItem;
    });

  const allContOp =
    constituency &&
    constituency.map(item => {
      const objItem = {};
      objItem.label = `${item.area} (${item.area_en})`;
      objItem.value = item.thokkuthi_slug;
      return objItem;
    });

  let link = "";
  if (checkAppSlug()) {
    link = "?source=app&type=webview";
  }

  return (
    <div className={styles["form-grid"]}>
      <div className={styles["form-txt"]}>
        <h1>
          <span>தொகுதி</span>
          <span>360</span>
        </h1>
      </div>
      <div className={styles["form-content"]}>
        <h2>உங்கள் தொகுதியின் வேட்பாளர்கள், வாக்காளர்களின் எண்ணிக்கை மற்றும் தேர்தல் கள நிலவரங்களை அறிந்துகொள்ள…</h2>
        <div className={styles["form"]}>
          <div>
            <label>தொகுதியைத் தேர்ந்தெடுக்க</label>
            <div className={styles["search-field"]}>
              <AutoSelectComponent
                className="districk"
                options={allContOp}
                SelectedValue={e => setSelectedConstituency(e)}
              />
              <div className={styles["form-icons"]}>
                <Icon type="search" className={styles["search"]} />
                <img alt="Translate" src={assetify(translate)} className={styles["translate"]} />
              </div>
            </div>
          </div>
          <div className={styles["or"]}>OR</div>
          <div>
            <label>மாவட்டம்</label>
            <AutoSelectComponent className="districk" options={disOp} SelectedValue={e => setSelectedDistrick(e)} />
          </div>
          <div>
            <label>சட்டமன்றத் தொகுதி</label>
            <AutoSelectComponent
              className="districk"
              options={contOp}
              SelectedValue={e => setSelectedConstituency(e)}
            />
          </div>

          <Link
            aria-label={`இங்கு க்ளிக் செய்க`}
            className={selectedConstituency ? styles["submit-btn"] : styles["isDisabled"]}
            href={
              selectedConstituency
                ? `/election-2021/constituency/${selectedConstituency}-assembly-constituency-elections-2021-updates${link}`
                : "#"
            }
          >
            இங்கு க்ளிக் செய்க
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConstituencySearchBar;
