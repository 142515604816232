import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import assetify from "@quintype/framework/assetify";
import cskImg from "../../../../assets/images/csk.png";
import rrImg from "../../../../assets/images/rr.png";
import dcImg from "../../../../assets/images/dc.png";
import kkrImg from "../../../../assets/images/kkr.png";
import kxpImg from "../../../../assets/images/kxp.png";
import miImg from "../../../../assets/images/mi.png";
import srhImg from "../../../../assets/images/srh.png";
import rcbImg from "../../../../assets/images/rcb.png";

export const MatchCard = ({ item, className = "" }) => {
  const key = get(item, ["key"], "");
  const name = get(item, ["name"], "");
  const relatedName = get(item, ["related_name"], " ");
  const shortName = get(item, ["short_name"], "");
  const status = get(item, ["status"], " ");
  const teamsA = get(item, ["teams", "a", "key"], " ");
  const teamsB = get(item, ["teams", "b", "key"], " ");
  const inningsA = get(item, ["innings", "a_1", "run_str"], " ");
  const inningsB = get(item, ["innings", "b_1", "run_str"], " ");
  const result = get(item, ["msgs", "result"], "");
  const toss = get(item, ["toss", "str"], "");
  const startdate = get(item, ["start_date", "timestamp"], "");
  const newdate = new Date(startdate * 1000);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[newdate.getMonth()];
  const date = newdate.getDate();
  const hours = newdate.getHours();
  const minutes = "0" + newdate.getMinutes();
  const seconds = "0" + newdate.getSeconds();
  const formattedTime = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  const iplImg = {
    csk: cskImg,
    mi: miImg,
    srh: srhImg,
    kkr: kkrImg,
    kxip: kxpImg,
    dc: dcImg,
    rr: rrImg,
    rcb: rcbImg
  };
  const Aimage = get(iplImg, [teamsA], "");
  const Bimage = get(iplImg, [teamsB], "");
  const convertToSlug = Name => {
    return Name.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };
  let slug = `/ipl-2020`;
  if (name) {
    slug = `/ipl-2020/${convertToSlug(name)}-live-score-full-${key}`;
  }

  return (
    <React.Fragment>
      <Link className={styles["ipl-top-wrapper"]} href={slug}>
        <div className={styles["ipl-card"]}>
          <div className={styles["shortname"]}>
            {shortName && (
              <h1>
                {`${relatedName} - ${month} ${date} `}
                <span className={styles["formattedtime"]}>{formattedTime}</span>{" "}
              </h1>
            )}
          </div>
          <div className={styles["ipl-card-grid"]}>
            <ul className={styles["ipl-score-list"]}>
              <li className={styles["ipl-score"]}>
                {Aimage && <img alt="" src={assetify(Aimage)} className={styles["img-view"]} />}
                <div>{teamsA}</div>
                <div className={styles["ipl-score-pnts"]}>
                  <span>{inningsA}</span>
                </div>
              </li>
            </ul>
            <div className={styles["match-name"]}>
              <div className={styles["match-name-tag"]}>
                {status === "notstarted" && (
                  <div>
                    <p>
                      <span className={styles["score-tag-notstarted"]}>Upcoming</span>
                    </p>
                    Dubai, UAE
                  </div>
                )}
                {status === "completed" && (
                  <div>
                    <p>
                      <span className={styles["score-tag-completed"]}>{status}</span>
                    </p>
                    {result}
                  </div>
                )}
                {status === "started" && (
                  <div>
                    <p>
                      <span className={styles["score-tag-started"]}>Live</span>
                    </p>
                    {toss}
                  </div>
                )}
              </div>
            </div>
            <ul className={styles["ipl-score-list"]}>
              <li className={styles["ipl-score"]}>
                {Bimage && <img alt="" src={assetify(Bimage)} className={styles["img-view"]} />}
                <div>{teamsB}</div>
                <div className={styles["ipl-score-pnts"]}>
                  <span>{inningsB}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

MatchCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape()
};

export default MatchCard;
