import React from "react";
import PropTypes from "prop-types";

import Carousel from "../../atoms/carousel";
import Icon from "../../atoms/icon";
import StoryImageSlide from "../../atoms/story-image-slider-slide";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const StoryImageSlider = ({ stories, accentColor }) => {
  stories = stories.filter(story => story.url && (getStoryHeadline(story) || story["alternate-headline"]));
  if (stories.length === 0) {
    return null;
  }

  let slides = stories.map(story => <StoryImageSlide accentColor={accentColor} key={story.id} story={story} />);

  if (slides.length === 1) {
    return (
      <div className={`${styles["base"]}`}>
        <div>{slides}</div>
      </div>
    );
  }

  return (
    <div className={`${styles["base"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div>
        <Carousel
          options={{
            autoplay: 3000,
            type: "carousel"
          }}
          renderCenterLeftControls={({ previousSlide }) => (
            <button
              aria-label="previous slide"
              role="button"
              onClick={previousSlide}
              className={`${styles["slider-nav-button"]}`}
            >
              <Icon type={"slider-prev"} className={styles["slider-nav"]} />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button
              aria-label="next slide"
              role="button"
              onClick={nextSlide}
              className={`${styles["slider-nav-button"]}`}
            >
              <Icon type={"slider-next"} className={styles["slider-nav"]} />
            </button>
          )}
          renderBottomCenterControls={({ nextSlide }) => null}
        >
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

StoryImageSlider.propTypes = {
  accentColor: PropTypes.string,
  stories: PropTypes.arrayOf(StoryImageSlide.propTypes.story)
};

export default StoryImageSlider;
