import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";

import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";
import Icon from "../icon";

const SmallStoryCardCurve = ({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  verticalimg = true,
  sectionpagetemplate = false,
  pFrom = "small-card",
  videocard = false,
  iconbottomright = false
}) => {
  const storyData = getStoryData(story);

  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  if (!(storyData.headline && story.url)) {
    return null;
  }
  const icons = {
    "photo-album": "",
    photo: "",
    video: "video-play"
  };
  const iconType = get(icons, [story["story-template"]], null);

  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={`${externalLink || story.url}?pfrom=${pFrom}`}
      externalLink={SourceLink || externalLink}
    >
      <div
        className={`${styles["base"]} ${verticalimg ? styles["vertical-image"] : ""} ${
          sectionpagetemplate ? styles["sectionpagetemplatetrue"] : "sectionpagetemplatefalse"
        } ${videocard ? styles["videocard"] : styles["notvideocard"]}`}
      >
        <div className={styles["image-with-icon"]}>
          <ResponsiveImageWithFallback
            className={styles["image-wrapper"]}
            slug={storyData.imageS3Key}
            metadata={storyData.imageMetadata}
            isPremium={isPremium(story)}
            alt={storyData.imageCaption}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
              { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
            )}
            story={story}
          />
          {iconbottomright &&
            iconType && (
              <span className={styles["story-template-icon"]}>
                <Icon type={iconType} className={styles["template-icon"]} />
              </span>
            )}
        </div>
        <div className={styles["text-wrapper"]}>
          <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
            {storyData.headline}
          </h3>
        </div>
      </div>
    </Link>
  );
};

SmallStoryCardCurve.propTypes = {
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  verticalimg: PropTypes.bool,
  sectionpagetemplate: PropTypes.bool,
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  }),
  pFrom: PropTypes.string,
  videocard: PropTypes.bool,
  iconbottomright: PropTypes.bool
};

export default SmallStoryCardCurve;
