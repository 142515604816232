import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, WithLazy, wrapCollectionLayout } from "@quintype/components";
import { getType } from "../../../data/collection";
import Media from "react-media";
import HomeLiveBlog from "../../atoms/home-live-blog";
import SliderMain5s from "../../atoms/slider-main-5s";
import CollectionBundleItem from "../../atoms/collection-bundle-item";
import TrendingStories from "../../atoms/trending-stories";
import VideoStories from "../../atoms/video-stories";
import MagazineListCard from "../../pages/story/story-templates/shared/magazine-list-card-home";
import styles from "./styles.m.css";
import Iframe from "react-iframe";
import { VikatanMainResponsive, PGAdwithAd, VdoAiHomeScript } from "../ad-free";
import Gamessection from "../../atoms/GamesSection";
import SixStory6c from "../../rows/six-stories-collection";
import SixStory6cwithcategory6s from "../six-stories-collection-with-category-for-you-stories";
import { get } from "lodash";

const WrappedTrendingStories = wrapCollectionLayout(TrendingStories);
const WrappedVideoStories = wrapCollectionLayout(VideoStories);

const MainRow = ({
  collection,
  accentColor,
  pagetype = "",
  magazineList,
  config,
  magazine,
  latestNews,
  latestStories,
  homeMagazine,
  homecollection = true
}) => {
  if (!collection.items) {
    return null;
  }
  const stories = collection.items
    .filter(item => item && item.type === "story" && get(item, ["associated-metadata", "live", 0], null) === null)
    .map(item => item.story);
  const collections = collection.items.filter(item => item && item.type === "collection");
  const checkLiveBlog = collection.items
    .filter(item => item && item.type === "story" && get(item, ["associated-metadata", "live", 0], null) === "true")
    .map(item => item.story);

  const collectionofCollection = collections
    .filter(collection => {
      return getType(collection) === "default";
    })
    .slice(0, 5);

  const firstCollection = get(collectionofCollection, [0], []);

  const secondCollection = latestNews;
  const thirdCollection = latestStories;
  const secondColStories = secondCollection && get(secondCollection, ["items", 0, "items"], []).slice(0, 6);
  const thirdColStories = thirdCollection && get(thirdCollection, ["items", 0, "items"], []).slice(0, 6);
  const trendingCollection =
    collections.filter(collection => getType(collection) === "trending").slice(0, 1)[0] || null;
  const videoCollection = collections.filter(collection => collection.slug === "vikatan-videos").slice(0, 3)[0] || null;

  const MagazineCollection = collections.filter(collection => getType(collection) === "special").slice(0, 1)[0] || null;
  const [userType = "F", setType] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the key to force iframe reload
      setIframeKey(prevKey => prevKey + 1);
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div>
      <div className={styles["main-row"]} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`${styles["main-row-grid"]} container`}>
          <div className={styles["top-grid"]}>
            <div className={styles["magazine-slider"]}>
              {MagazineCollection && (
                <MagazineListCard
                  collection={MagazineCollection}
                  homeMagazine={true}
                  utmURL="homepage-wrapper"
                  config={config}
                />
              )}
            </div>
            <div className="hidden-mobile">
              <Media query="(min-width: 767px)">
                {matches =>
                  matches && (
                    <div className={styles["bundle-wrapper"]}>
                      {collections && (
                        <div className={styles["collections-wrap"]}>
                          {collections
                            .slice(0, 1)
                            .map(
                              collection =>
                                collection && (
                                  <CollectionBundleItem
                                    key={collection.id}
                                    collection={collection}
                                    className={styles["collection-item"]}
                                  />
                                )
                            )}
                        </div>
                      )}
                    </div>
                  )
                }
              </Media>
            </div>
          </div>
          <div className={styles["main-grid"]}>
            <div className={styles["main-left-panel"]}>
              {checkLiveBlog.length !== 0 && (
                <div className={styles["slider-stories-wrap"]}>
                  <HomeLiveBlog stories={checkLiveBlog.slice(0, 1)} utmURL="home-main-row-liveblog" />
                </div>
              )}
              <div className={styles["slider-stories-wrap"]}>
                <h1 className={styles["main-title"]}>
                  <Link href="/">முக்கியச் செய்திகள்</Link>
                </h1>
                <SliderMain5s stories={stories.slice(0, 5)} utmURL="home-main-row" />
              </div>
              <div className="hidden-desktop">
                <Media query="(max-width: 767px)">
                  {matches =>
                    matches && (
                      <div>
                        <div className={styles["bundle-wrapper"]}>
                          {collections && (
                            <div className={styles["collections-wrap"]}>
                              {collections
                                .slice(0, 1)
                                .map(
                                  collection =>
                                    collection && (
                                      <CollectionBundleItem
                                        key={collection.id}
                                        collection={collection}
                                        className={styles["collection-item"]}
                                      />
                                    )
                                )}
                            </div>
                          )}
                        </div>
                        {userType === "P" || userType === "T" || userType === "R" ? (
                          " "
                        ) : (
                          <div className="ad_300_250 ad-top-bottom-space">
                            <PGAdwithAd
                              adId={`Vikatan2023_Mobile_HP_ATF_336x280`}
                              adSize={[[336, 280], [300, 250]]}
                              minHeight={280}
                              adDivId="div-ad-desk-3849069-169028051743-4"
                              class=""
                            />
                          </div>
                        )}
                      </div>
                    )
                  }
                </Media>
              </div>
              {checkLiveBlog.length === 0 && (
                <div>
                  <SixStory6c
                    collection={firstCollection}
                    className={styles["getmagazine"]}
                    authorName={true}
                    utmURL="home-mid-row"
                    homecollection={homecollection}
                    storyLimit={6}
                  />
                </div>
              )}
              <div className="hidden-desktop">
                <WithLazy>
                  {() => (
                    <Media query="(max-width: 767px)">
                      {matches =>
                        matches ? (
                          <div>
                            {userType === "P" || userType === "T" || userType === "R" ? (
                              " "
                            ) : (
                              <div className="ad_300_250 ad-top-bottom-space">
                                <PGAdwithAd
                                  adId={`Vikatan2023_Mobile_HP_MID1_336x280`}
                                  adSize={[[336, 280], [300, 250]]}
                                  minHeight={280}
                                  adDivId="div-ad-desk-3849069-169028051723-4"
                                  class=""
                                />
                              </div>
                            )}
                            <WrappedTrendingStories
                              collection={trendingCollection}
                              className={styles["trending-stories"]}
                            />
                            {userType === "P" || userType === "T" || userType === "R" ? (
                              " "
                            ) : (
                              <div className="ad_300_250 ad-top-bottom-space">
                                <PGAdwithAd
                                  adId={`Vikatan2023_Mobile_HP_MID1_336x280`}
                                  adSize={[[336, 280], [300, 250]]}
                                  minHeight={280}
                                  adDivId="div-ad-desk-3849069-169028051723-4"
                                  class=""
                                />
                              </div>
                            )}
                            <div className={`${styles["vikatan-poll"]}`}>
                              <Iframe
                                key={iframeKey}
                                title="vikatan poll"
                                frameBorder="0"
                                className={`${styles["iframe-outer"]}`}
                                src="https://special.vikatan.com/vikatan-poll/index.php"
                              />
                            </div>

                            {userType === "P" || userType === "T" || userType === "R" ? (
                              " "
                            ) : (
                              <div className="ad_300_250 ad-top-bottom-space">
                                <PGAdwithAd
                                  adId={`Vikatan2023_Mobile_HP_MID1_336x280`}
                                  adSize={[[336, 280], [300, 250]]}
                                  minHeight={280}
                                  adDivId="div-ad-desk-3849069-169028051723-4"
                                  class=""
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      }
                    </Media>
                  )}
                </WithLazy>
              </div>
              <div className={styles["two-stories-section"]}>
                <SliderMain5s
                  stories={stories.slice(5, 9)}
                  topFullView={false}
                  authorName={false}
                  utmURL="home-twostories-row"
                />
              </div>
              <div className="hidden-desktop">
                {userType === "P" || userType === "T" || userType === "R" ? (
                  " "
                ) : (
                  <div className="ad_300_250 ad-top-bottom-space">
                    <PGAdwithAd
                      adId={`Vikatan2023_Mobile_HP_MID1_336x280`}
                      adSize={[[336, 280], [300, 250]]}
                      minHeight={280}
                      adDivId="div-ad-desk-3849069-169028051723-4"
                      class=""
                    />
                  </div>
                )}
              </div>
              <SixStory6cwithcategory6s
                collection={secondCollection}
                stories={secondColStories}
                horizondalView={true}
                cardGrid={true}
                authorName={false}
                utmURL="home-third-row"
              />
              <div className="hidden-desktop">
                <Media query="(max-width: 767px)">
                  {matches =>
                    matches && (
                      <div>
                        <div>
                          {userType === "P" || userType === "T" || userType === "R" ? (
                            " "
                          ) : (
                            <div className="ad_300_250 ad-top-bottom-space">
                              <PGAdwithAd
                                adId={`Vikatan2023_Mobile_HP_MID2_336x280`}
                                adSize={[[336, 280], [300, 250]]}
                                minHeight={280}
                                adDivId="div-ad-desk-3849069-169028051745-4"
                                class=""
                              />
                            </div>
                          )}
                        </div>
                        {userType === "P" || userType === "T" || userType === "R" ? (
                          <div className={`${styles["fullneet"]}`}>
                            <WrappedVideoStories
                              collection={videoCollection}
                              storiesStart={1}
                              showCarousel={true}
                              className={`${styles["video-section"]} ${styles["video-section-paid"]}`}
                            />
                          </div>
                        ) : (
                          <div className="widget300xauto-targetted">
                            <div>
                              <WrappedVideoStories
                                collection={videoCollection}
                                storiesStart={1}
                                showCarousel={false}
                                className={`${styles["video-section"]}`}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }
                </Media>
              </div>
              <SixStory6cwithcategory6s
                collection={thirdCollection}
                stories={thirdColStories}
                horizondalView={false}
                cardGrid={false}
                authorName={false}
                utmURL="home-fourth-row"
              />
            </div>
            <div className={styles["main-right-panel"]}>
              {userType === "P" || userType === "T" || userType === "R" ? (
                " "
              ) : (
                <div className={`${styles["ad-space"]} widget300xauto`}>
                  <VikatanMainResponsive
                    placement={pagetype}
                    MobileAdtype="Vikatan_Mobile_HP_ATF_300x250"
                    DesktopAdtype="Vikatan_Desktop_HP_ATF_RIGHT_300x250"
                  />
                </div>
              )}
              <div>
                <Media query="(min-width: 767px)">
                  {matches =>
                    matches ? (
                      <div>
                        <WrappedTrendingStories
                          collection={trendingCollection}
                          className={styles["trending-stories"]}
                        />

                        {userType === "P" || userType === "T" || userType === "R" ? (
                          " "
                        ) : (
                          <div className="ad_300_250 ad-top-bottom-space">
                            <PGAdwithAd
                              adId={`Vikatan2023_Desktop_HP_RightMID_300x250`}
                              adSize={[[300, 250]]}
                              minHeight={250}
                              adDivId="div-ad-desk-3849069-169028051748-4"
                              class=""
                            />
                          </div>
                          // <div className="ad_300_250 ad-top-bottom-space">
                          //   <WithLazy margin="300px">
                          //     {() => (
                          //       <Media query="(min-width: 767px)">
                          //         <ins id="Vikatan1" data-adspot-key="y5wz0acp" data-source="www.vikatan.com" />
                          //       </Media>
                          //     )}
                          //   </WithLazy>
                          // </div>
                        )}

                        <div className={`${styles["vikatan-poll"]}`}>
                          <Iframe
                            title="vikatan poll"
                            frameBorder="0"
                            className={`${styles["iframe-outer"]}`}
                            src="https://special.vikatan.com/vikatan-poll/index.php"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                </Media>
              </div>
              <Gamessection />
              <div>
                <Media query="(min-width: 768px)">
                  {matches =>
                    matches && (
                      <div>
                        {userType === "P" || userType === "T" || userType === "R" ? (
                          <div className={`${styles["fullneet"]}`}>
                            <WrappedVideoStories
                              collection={videoCollection}
                              storiesStart={1}
                              showCarousel={true}
                              className={`${styles["video-section"]} ${styles["video-section-paid"]}`}
                            />
                          </div>
                        ) : (
                          <div className="widget300xauto-targetted">
                            <div className={`${styles["Adplayer"]}`}>
                              <WrappedVideoStories
                                collection={videoCollection}
                                storiesStart={1}
                                showCarousel={false}
                                className={styles["video-section"]}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }
                </Media>
              </div>
              <div className={styles["series-wrapper"]}>
                {userType === "P" || userType === "T" || userType === "R" ? (
                  <div>
                    <Link className={styles["series-wrapper-link"]} href="/series?pfrom=homepage-mainrow-series">
                      <img
                        src="https://gumlet.assettype.com/vikatan/2023-01/f3994072-d50a-402d-840f-5f88fec046ba/Vikatan_thodargal.png?format=webp&w=300&dpr=1.0"
                        alt="series"
                        width="350px"
                        height="425px"
                      />
                    </Link>
                    <Link className={styles["series-wrapper-link"]} href="/short-stories?pfrom=homepage-mainrow-series">
                      <img
                        src="https://gumlet.assettype.com/vikatan/2023-06/3bb81e58-faad-4016-ab1e-6e9a1f826142/short_stories_home.png?format=webp&w=300&dpr=1.0"
                        alt="series"
                        width="350px"
                        height="460px"
                        className={styles["shortstories-image-paid-user"]}
                      />
                    </Link>
                  </div>
                ) : (
                  <div>
                    {userType === "P" || userType === "T" || userType === "R" ? (
                      <Link className={styles["series-wrapper-link"]} href="/series?pfrom=homepage-mainrow-series">
                        <img
                          src="https://gumlet.assettype.com/vikatan/2023-01/f3994072-d50a-402d-840f-5f88fec046ba/Vikatan_thodargal.png?format=webp&w=300&dpr=1.0"
                          alt="series"
                          width="350px"
                          height="425px"
                          className={styles["series-image-free-user"]}
                        />
                      </Link>
                    ) : (
                      <div className={`${styles["ad-space"]} widget300xauto`}>
                        <VdoAiHomeScript />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MainRow.propTypes = {
  accentColor: PropTypes.string,
  collection: CollectionBundleItem.propTypes.collection,
  pagetype: PropTypes.string,
  magazine: PropTypes.array,
  config: PropTypes.shape(),
  magazineList: PropTypes.array,
  latestNews: PropTypes.shape(),
  latestStories: PropTypes.shape(),
  homeMagazine: PropTypes.bool,
  homecollection: PropTypes.string
};
MainRow.storyLimit = 20;

export default MainRow;
