import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Carousel from "../../atoms/carousel";
import get from "lodash/get";
import { fetchWCMatchList } from "../../../api";

import styles from "./styles.m.css";
import WCMatchCard from "../../atoms/wc-match-card";
import WCLiveScore from "../wc-live-card";
import { Link } from "@quintype/components";

const WCMatchList = ({
  numberOfCardsInViewDesktop = 3,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1,
  accentColor,
  homeMatchlist,
  titleClassName,
  title
}) => {
  const desktopGap = 30;
  const mobileGap = 0;

  const [completedMatch, setcompletedMatch] = useState([]);
  const [upcomigMatch, setUpcomigMatch] = useState([]);
  const [liveMatch, setLiveMatch] = useState([]);

  useEffect(() => {
    fetchWCMatchList().then(list => {
      const listData = get(list, ["data", "matches"], []);
      const completedmatchData = listData.length !== 0 && listData.filter(item => item.status === "completed");
      const upmatchData = listData.length !== 0 && listData.filter(item => item.status === "not_started");
      const livematchData = listData.length !== 0 && listData.filter(item => item.status === "started");
      setcompletedMatch(completedmatchData);
      setUpcomigMatch(upmatchData);
      setLiveMatch(livematchData);
    });
  }, []);

  return (
    <div className={styles.base}>
      <div className={`${styles["base"]} ${homeMatchlist ? styles["homematchlist"] : styles["pagematchlist"]}`}>
        <div className={`container ${styles["carousel-wrapper"]}`}>
          {liveMatch.length !== 0 ? (
            <div className={styles["match-wrapper"]}>
              <h6 className={`${styles["headline"]} ${styles[titleClassName]}`}>{title}</h6>
              {liveMatch.length > 0 &&
                liveMatch.map((item, i) => {
                  return (
                    <Link className={styles["live-match-wrapper"]} key={i} href={`/wc-2023/${item.key}`}>
                      <WCLiveScore slug={item.key} />
                    </Link>
                  );
                })}
            </div>
          ) : (
            <div />
          )}
          {completedMatch.length !== 0 && (
            <div className={styles["match-wrapper"]}>
              <h6 className={`${styles["headline"]} ${styles[titleClassName]}`}>Completed Matches</h6>
              <Carousel
                className={styles["carousel"]}
                options={{
                  type: "slider",
                  perView: numberOfCardsInViewDesktop,
                  gap: desktopGap,
                  focusAt: 0,
                  startAt: 0,
                  bound: true,
                  breakpoints: {
                    767: {
                      perView: numberOfCardsInViewMobile,
                      gap: mobileGap
                    },
                    991: {
                      perView: numberOfCardsInViewTablet,
                      gap: desktopGap
                    }
                  }
                }}
                renderCenterLeftControls={({ previousSlide, currentSlide }) => null}
                renderCenterRightControls={({ nextSlide }) => null}
                renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                  return (
                    <div className={styles.dots}>
                      {completedMatch.length > 1 && (
                        <div className={styles["carousel-dots"]}>
                          {completedMatch.map((item, i) => (
                            <div key={i} className={`${currentSlide === i ? styles["active-dot"] : styles["dot"]}`} />
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }}
              >
                {completedMatch.length > 0 &&
                  completedMatch.map((item, i) => {
                    return (
                      <Link key={i} href={`/wc-2023/${item.key}`}>
                        <WCMatchCard key={i} item={item} className={styles.card} />
                      </Link>
                    );
                  })}
              </Carousel>
            </div>
          )}
          {!homeMatchlist &&
            upcomigMatch.length !== 0 && (
              <div className={styles["match-wrapper"]}>
                <h6 className={`${styles["headline"]} ${styles[titleClassName]}`}>Upcoming Matches</h6>
                <Carousel
                  className={styles["carousel"]}
                  options={{
                    type: "slider",
                    perView: numberOfCardsInViewDesktop,
                    gap: desktopGap,
                    focusAt: 0,
                    startAt: 0,
                    bound: true,
                    breakpoints: {
                      767: {
                        perView: numberOfCardsInViewMobile,
                        gap: mobileGap
                      },
                      991: {
                        perView: numberOfCardsInViewTablet,
                        gap: desktopGap
                      }
                    }
                  }}
                  renderCenterLeftControls={({ previousSlide, currentSlide }) => null}
                  renderCenterRightControls={({ nextSlide }) => null}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                    return (
                      <div className={styles.dots}>
                        {upcomigMatch.length > 1 && (
                          <div className={styles["carousel-dots"]}>
                            {upcomigMatch.map((item, i) => (
                              <div key={i} className={`${currentSlide === i ? styles["active-dot"] : styles["dot"]}`} />
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }}
                >
                  {upcomigMatch.length > 0 &&
                    upcomigMatch.map((item, i) => <WCMatchCard key={i} item={item} className={styles.card} />)}
                </Carousel>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

WCMatchList.propTypes = {
  accentColor: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  homeMatchlist: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string
};

export default WCMatchList;
