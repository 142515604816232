import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithSideLine from "../../atoms/CollectionTitleWithSideLine";
import StoryCardML4x3DP1x1 from "../../atoms/story-card-ml4x3-dl1x1";
import { Link } from "@quintype/components";
import { DfpAd } from "../../atoms/dfp-ad";
import Collapsible from "react-collapsible";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import Iframe from "react-iframe";

const GoldStory = ({ collection, accentColor }) => {
  const { name, items } = collection;
  const stories =
    items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, 4)
      .map(({ story }) => <StoryCardML4x3DP1x1 className={styles["story-card"]} key={story.id} story={story} />) || [];

  if (!stories.length) {
    return null;
  }

  return (
    <div className={`${styles["main-container"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className={styles["iframe-card-container"]}>
          <Iframe frameBorder="0" className={styles["iframe-outer"]} src="https://special.vikatan.com/gold-page/" />
        </div>
        <div className={styles["story-card-container"]}>
          <div className={styles["container"]}>
            <h2> தங்க நகையின் விலை எவ்வாறு கணக்கிடப்படுகிறது? </h2>
            <p>
              பொதுவாக, தங்க நகையின் விலை, 22 அல்லது 18 காரட் தங்கத்தின் சந்தை மதிப்பு, செய்கூலி மற்றும் ஜிஎஸ்டி வரியை
              உள்ளடக்கும். செய்கூலி, நகைக்கு நகை வேறுபடும். நகையில் இருக்கும் டிசைன்களைப் பொறுத்து செய்கூலியும்,
              சேதாரமும் மாறுபடும். அனைத்து நகைக்கடைகளிலும் தங்கத்தின் விலை ஒரே மாதிரியாக இருந்தாலும்,
              <Link href={"/news/miscellaneous/141349-be-aware-while-buying-gold-jewellery-exclusive-deals"}>
                செய்கூலி, சேதாரத்தின் விகிதம்
              </Link>
              <span> வேறுபட்டிருக்கும்.</span> இதனால், வாடிக்கையாளர்கள் நகை கடைகளில் பேரம் பேசினால், செய்கூலி,
              சேதாரத்தின் விலையைக் குறைத்துக் கேட்கலாம். தங்கத்தின் மீது 3% ஜிஎஸ்டியும் செய்கூலி மீது கூடுதலாக 5%
              ஜிஎஸ்டி வரியும் வதிக்கப்படுகிறது. முக்கியமாக, கல் பதித்த நகைகள் என்றால், கல்லின் எடை நகையின் மொத்த
              எடையிலிருந்து கழிக்கப்படும். கல்லின் மதிப்பு தனியாகச் சேர்க்கப்படும். பொதுவாக தங்கம் விலை சென்னை, டெல்லி,
              கொல்கத்தா, மும்பை என இடங்களுக்கு தகுந்தபடி விலையில் மாறுபாடு இருக்கும். அதன் தரம் மற்றும், இறக்குமதி வரி
              போன்ற பல்வேறு காரணங்களால் இந்த மாறுபாடு இருக்கும். தற்போதைய நிலையில் இறக்குமதி சுங்க வரி 10 சதவிகிதமாக
              இருக்கிறது.
            </p>
            <h2>டாலர் மதிப்பு ஏறி, இறங்குவதற்கும் தங்கத்துக்கும் என்ன சம்மந்தம்?</h2>
            <p>
              அமெரிக்க அரசாங்கம் பெரிய தங்க உற்பத்தியாளராக இல்லாத போதிலும், உலகத்தில் உள்ள தங்கத்தின் ஒரு பெரிய பகுதியை
              இருப்பில் வைத்திருக்கிறது. அதனால் <Link href={"/topics/gold-rate"}>தங்கம் விலை</Link>
              யைத் தீர்மானிக்கும் சக்தியாக அமெரிக்கா இருக்கிறது. அதனால் அனைத்து நாடுகளும், தங்கத்தை அமெரிக்க டாலரில்
              கொடுத்து வாங்குகின்றன. அமெரிக்க டாலருக்கு நிகரான இந்திய ரூபாய் மதிப்பு, கடந்த சில மாதங்களாகத் தொடர்ந்து
              குறைந்து கொண்டே வருகிறது. இப்படி தொடர்ந்து குறைந்தால், அதிக ரூபாய் கொடுத்து டாலர் வாங்க வேண்டி இருக்கும்.
              எனவே, நிறுவனங்கள் இறக்குமதி செய்ய அதிக டாலர்களைச் செலுத்த வேண்டியிருக்கும். அரசாங்கமும் தங்கத்தை
              வாங்குவதற்காக அதிக டாலர்களைச் செலுத்த வேண்டியிருக்கும். இதன் விளைவாக தங்கத்தின் விலை உயர்கிறது. மாறாக,
              டாலருக்கு நிகரான இந்திய ரூபாயின் மதிப்பு அதிகரிக்கும் போது. தங்கத்தை இறக்குமதி செய்ய, அதிக டாலர்களை செலவு
              செய்யத் தேவையில்லை. இதனால் தங்கத்தின் விலை குறைகிறது.
            </p>
            <h2> 22 மற்றும் 24 காரட் தங்கம் என்றால் என்ன? </h2>
            <p>
              காரட் என்பது தங்கத்தின் தூய்மையைக் குறிக்கும் குறியீடாகும். தங்கத்துடன் தாமிரம், துத்தநாகம், நிக்கல் போன்ற
              உலோகங்களைச் சேர்த்தால் மட்டுமே நகை செய்ய முடியும். தங்கத்துடன் மற்ற உலேகங்களைச் சேர்க்கும்போது அதன் காரட்
              குறியீடு குறைந்துகொண்டே வரும். சந்தையில்
              <Link href={"/business/finance/is-it-safe-to-invest-in-gold-now"}> 24,22,18,14 காரட்டுகளில் தங்கம் </Link>
              கிடைக்கின்றது. 24 காரட் தங்கம் , மற்ற உலோகங்களின் கலவை இல்லாத, சுத்தமான தங்கமாகும். மற்ற காரட் தங்கத்தை
              விட சற்றே விலையுயர்ந்தது. 22 காரட் தங்கம், 22 பங்கு தங்கம் மற்றும் 2 பங்கு தாமிரம் போன்ற உலோகங்களைக்
              கொண்டு தயாரிக்கப்படும் கலவையாகும். இது 24 காரட்டை விட அதிக தடிமானைத்தைக் கொண்டுள்ளதால் இக்கலவை நகை செய்ய
              பயன்படுத்தப்படுகிறது. இது 916 தங்கம் என்றும் அழைக்கப்படுகிறது.
            </p>
            <h2> மத்திய அரசால் கொண்டுவரப்பட்ட தங்கத் திட்டம் எது? </h2>
            <p>
              <Link href={"/news/investment/reserve-bank-announced-sovereign-gold-plan-scheme"}>
                சவரன் தங்கப் பத்திரத் திட்டம்
              </Link>
              <span> மத்திய</span> அரசு மற்றும் இந்திய ரிசர்வ் வங்கி இணைந்து வெளியிடும் தங்கப் பத்திரத் திட்டமாகும்.
              இத்திட்டம் தங்கத்தில் கிடைக்கும் அனைத்து நன்மைகளையும் வழங்கும். முதலீட்டிற்காக தங்கத்தை வாங்குபவர்கள்,
              அதற்கு பதிலாக இந்த தங்கப் பத்திரங்களை வாங்கலாம். செய்கூலி, சேதாரம், வரி போன்ற எந்த கூடுதல் செலவுகளும்
              இதில் இல்லை. இந்த தொகைக்கு ரிசர்வ் வங்கியால் நிர்ணயிக்கப்படும் வட்டி வழங்கப்படுகிறது. இப்பத்திரத்தை
              வாங்குவதும் விற்பதும் எளிது. நமது வங்கிகளின் மூலமே இந்த திட்டத்தில் பங்குகொள்ளலாம். கடந்த ஜூலை 6-ம் தேதி
              கூட, ஆர்.பி.ஐ நான்காம் கட்ட தங்கப் பத்திர திட்டத்தை அறிமுகம் செய்து, விற்பனைக்கு கொண்டுவந்தது. இந்த
              திட்டத்திற்கு 8 ஆண்டுகள் முதிர்வுக் காலம் உண்டு. ஆனால் 5 ஆண்டில் இருந்தே வெளியேறுபவர்கள் வெளியேறலாம்.
              இதில் உள்ள ஒரு நல்ல விஷயம் என்னவெனில் பிசிகல் தங்கத்தினைப் போலவே நீங்கள் இந்த தங்க பத்திரத்தினை பிணையமாக
              வைத்து கடன் வாங்கிக் கொள்ளலாம். இந்த பத்திரங்கள் இந்திய அரசால் ஆதரிக்கப்படுவதால் முதலீட்டாளர்கள்,
              நம்பிக்கையாக முதலீடு செய்யலாம்.
            </p>
            <h2>தங்க நகையில், சேதாரம் என்றால் என்ன?</h2>
            <p>
              கையாலோ அல்லது இயந்திரத்தின் உதவியாலோ தங்கத்தை உருக்கி நகை செய்யும்போது, ஒரு குறிப்பிட்ட அளவு தங்கம்
              வீணாகிறது. இதுதான் சேதாரம். இயந்திரத்தைப் பயன்படுத்தி நகைகள் செய்யும்போது சேதாரம் குறையும். ஆண்டிக்,
              <Link href={"/oddities/miscellaneous/139893-gold-necklace-set-designs"}>டெம்பில் ஜுவல்லரி </Link> போன்ற
              அதிக கைவேலையைக் கொண்ட நகைகளில் சேதாரம் அதிகமாக இருக்கும். சேதாரம் பொதுவாக 3% முதல் 35% வரை இருக்கும்.
              கூடுதலாக, நகைகளில் ஏதேனும் கற்கள் பதிக்கப்பட்டால் , அந்த செலவும் தங்கத்தின் விலையில் சேர்க்கப்படும்.
            </p>
          </div>
          <div className={styles["sidebar-story-container"]}>
            <div className={styles["small-card-ad-container"]}>
              <DfpAd className={`${styles.ad}`} adtype="Rectangle" placement="gold-page-2" />
            </div>
          </div>
        </div>
        <CollectionLink collection={collection}>
          <CollectionTitleWithSideLine className={styles.title} title={name} />
        </CollectionLink>
        <div className={styles["story-card-container"]}>
          <div className={styles["bg-story-container"]}>{stories}</div>
          <div className={styles["sidebar-story-container"]}>
            <div className={styles["small-card-ad-container"]}>
              <DfpAd className={`${styles.ad}`} adtype="Rectangle" placement="gold-page-3" />
            </div>
          </div>
        </div>
        <CollectionTitleWithSideLine className={styles.title} title="Gold Rate FAQ" />
        <div className={styles["story-card-container"]}>
          <div className={styles["story-container-faq"]}>
            <Collapsible trigger="1. சென்னையில் இன்று 22 கேரட் 1 கிராம் தங்கத்தின் விலை என்ன?">
              <p>இன்றைய நிலவரப்படி, சென்னையில் 22 கேரட் 1 கிராம் தங்கத்தின் விலை</p>
            </Collapsible>
            <Collapsible trigger="2. சென்னையில் இன்று 24 கேரட் 1 கிராம் தங்கத்தின் விலை என்ன?">
              <p>இன்றைய நிலவரப்படி, சென்னையில் 24 கேரட் 1 கிராம் தங்கத்தின் விலை</p>
            </Collapsible>
            <Collapsible trigger="3. தங்கத்தின் விலை எவ்வாறு கணக்கிடப்படுகிறது?">
              <p>
                தங்கத்தின் விலையானது இரண்டு காரணிகளைச் சார்ந்தது. முதலாவது நகையில் இருக்கும் தங்கத்தின் அளவை பொறுத்தது
                (22 கேரட் அல்லது 18 கேரட்). இரண்டாவது தங்கத்தோடு கலக்கப் பயன்படும் உலோகத்தைப் பொறுத்தது. தினமும்
                தங்கமானது பங்குச்சந்தையில் வர்த்தகம் செய்யப்படும். இதனால் தேவை மற்றும் விநியோகம் சார்ந்து இதன் விலை
                தினமும் மாறுபடும். ஒவ்வொரு நகை உரிமையாளர்க்கும் அவர் யாரிடம் தங்கம் வாங்குகிறார் என்பதைப் பொறுத்து அதன்
                விலையானது மாறுபடும். தங்கத்தின் விலையானது இறுதியாக - தங்கத்தின் விலை (22 கேரட் அல்லது 18 கேரட் ) ×
                தங்கத்தின் எடை கிராமில் + நகை செய்வதற்கு ஆகும் செலவு + 3% ஜிஎஸ்டி ( நகையின் விலை + நகை செய்வதற்காகும்
                விலை).
              </p>
            </Collapsible>
            <Collapsible trigger="4. தங்கத்தின் மீதான ஜிஎஸ்டி வரி எவ்வளவு?">
              <p>
                தங்கத்தின் மீதான ஜிஎஸ்டியானது 3 சதவிகிதம் ஆகும். அதே சமயத்தில் தங்க நகை என்றால் கூடுதலாக 5 சதவிகித
                ஜிஎஸ்டி தங்க நகை செய்வதற்காகக் கணக்கிடப்படும்.
              </p>
            </Collapsible>
            <Collapsible trigger="5. தங்கத்தில் 22 கேரட் தங்கம் என்றால் என்ன? 24 கேரட் தங்கம் என்றால் என்ன?">
              <div>
                <p>
                  பொதுவாக 24 கேரட் தங்கம் என்பது, முழுவதும் சுத்தமான தங்கமாக இருக்கும். ஆனால் 22 கேரட் தங்கத்தில் 22
                  பகுதிகள் மட்டுமே தூய தங்கத்தின் பொருட்கள் இடம்பெற்றிருக்கும். மீதி இரண்டு பகுதிகளாக வேறு எதாவது உலோகம்
                  கலக்கப்பட்டிருக்கும்.
                </p>
                <p>24 கேரட் தங்கம் = 99.5 % தூய தங்கம்.</p>
                <p>22 கேரட் தங்கம் = 91.7 % தங்கம் + இதர உலோகங்கள்.</p>
              </div>
            </Collapsible>
            <Collapsible trigger="6. இந்தியாவில் மத்திய அரசால் தொடங்கப்பட்டுள்ள தங்கத் திட்டம் எது?">
              <p>
                இந்திய அரசால் கடந்த 2015-ல் சாவ்ரேன் கோல்ட் பாண்டு ஸ்கீம் தொடங்கப்பட்டது. இதன் மூலம் தங்கத்தை
                பயன்படுத்துவதற்குப் பதில், உள்நாட்டு முதலீடாக மாற்றத் திட்டமிட்டது. இந்த சாவ்ரேன் கோல்ட் பாண்டு
                ஸ்கீமானது வங்கிகளிலும், அஞ்சலகங்களிலும்,பங்குச்சந்தையிலும், எஸ்.ஹெச்.சி.ஐ.எல்-லிலும்(SHCIL) கிடைக்கும்.
              </p>
            </Collapsible>
            <Collapsible trigger="7. தங்கத்தின் விலை 2020ல் அதிகரிக்குமா">
              <p>
                தங்கத்தின் மூலம் கடந்த 2019ல் 23.74 சதவிகித வருமானம் கிடைத்தது. இது 2020ல் இன்னும் அதிகரிக்கும் என்றே
                எதிர்பார்க்கப்படுகிறது.இதனால் வருகின்ற நாட்களில் பத்து கிராம் தங்கத்தின் விலையானது ₹ 50,000 முதல் ₹
                55,000 வரை செல்லலாம் என நிபுணர்கள் தெரிவிக்கிறார்கள்.இப்போது வரை மட்டுமே 2020ல் 17.31 சதவிகித வருமானம்
                தங்கம் மூலம் கிடைத்துள்ளது.
              </p>
            </Collapsible>
            <Collapsible trigger="8. தங்க விரயம்  என்றால் என்ன?">
              <p>
                தங்க நகைகளே உருவாக்கும் போது பொற்கொல்லர் அல்லது கைவினை கலைஞர் தங்கத்தை உருக்கி, உருமாற்றிதான் நகையைச்
                செய்வார்கள். இப்படிச் செய்யும் போது சிறிய தங்கத் துகள்கள் வீணாகப் போகலாம். இந்த துகள்கள் அனைத்தும்
                வீணானது கிடையாது. இதிலிருந்து சிதறும் சிறிய துகள்கள் மீண்டும் நகை செய்வதற்கும் பயன்படும். ஆனால் வீணான
                தங்கத்திற்கான சேதார விலையும் நகை விலையிலேயே சேர்க்கப்படும். இந்தியாவில் பொதுவாகத் தங்க விரயமானது 3
                சதவிகிதம் முதல் 35 சதவிகிதம் வரை கணக்கிடப்படுகிறது.
              </p>
            </Collapsible>
            <Collapsible trigger="9. எந்த நாட்டில் தங்கம் மிகவும் விலை உயர்ந்தது?">
              <p>
                இந்தியாவில் தான் தங்கத்தின் விலை மிக உயர்ந்ததாக உள்ளது. இங்கு தான் அதிகமான தங்கம் இறக்குமதி
                செய்யப்படுகிறது. தேவை அதிகமாக இருப்பதாலும், இந்திய மக்களுக்குத் தங்கத்தின் மீது மோகம் அதிகமாக
                இருப்பதாலும் தங்க விலையானது மற்ற நாடுகளை விட இந்தியில் உயர்ந்தே காணப்படுகிறது.
              </p>
            </Collapsible>
            <Collapsible trigger="10. வரலாற்றில் தங்கத்தின் அதிகபட்ச விலை எது?">
              <p>
                வரலாற்றில் தங்கத்தின் அதிகபட்ச விலையானது கடந்த 2011 ல் ஒரு அவுன்ஸின் (31.103 கிராம்) விலையானது 2000
                டாலர் வரை தொட்டது. அது தான் இப்போது வரை தங்கத்தின் உச்சபட்ச விலையாகும்
              </p>
            </Collapsible>
            <Collapsible trigger="11. தங்கத்தின் விலையானது வருகின்ற 2021ல் குறையுமா? ">
              <p>
                இல்லை. தங்கத்தின் விலையானது கொரோனா காலத்திற்குப் பிறகு அதிகமாகும் என நிபுணர்கள் தெரிவிக்கிறார்கள்.
                அடுத்த 2021 ஆண்டு இறுதிக்குள் தங்கத்தின் விலை பத்து கிராமிற்கு 82,000 ரூபாயாக இருக்கும் என பேங்க் ஆஃப்
                அமெரிக்க செக்யூரிட்டீஸ் அதன் சமீபத்திய அறிக்கையில் தெரிவித்துள்ளது.
              </p>
            </Collapsible>
          </div>
          <div className={styles["sidebar-story-container"]}>
            <div className={styles["small-card-ad-container"]}>
              <DfpAd className={`${styles.ad}`} adtype="Rectangle" placement="gold-page-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GoldStory.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardML4x3DP1x1.propTypes))
  })
};
GoldStory.storyLimit = 4;

export default GoldStory;
