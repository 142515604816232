import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Carousel from "../../atoms/carousel";
import SmallStoryCardDesktopBigNoBorder from "../../atoms/small-story-desktop-big-no-border";
import styles from "./styles.m.css";
import SlideBigStoryCard from "../../atoms/slide-big-story-card";
import { DfpAd } from "../../atoms/dfp-ad";
import { getStoryHeadline } from "../../utils/utils";
import Icon from "../../atoms/icon";

const OneCarouselTwoStoriesOneAdOneWidget = ({ collection, accentColor, pagetype = "" }) => {
  const items = get(collection, ["items"], []);
  let resizeDone = false;
  const stories = items
    .filter(item => item.type === "story")
    .map(item => item.story)
    .filter(story => getStoryHeadline(story) && story.url)
    .slice(0, 7);

  if (!stories.length) {
    return null;
  }

  const carouselStories = stories.splice(0, 5);

  const slides = carouselStories
    ? carouselStories.map(story => <SlideBigStoryCard key={story.id} story={story} />)
    : [];

  const resize = () => {
    if (resizeDone === false) {
      global.dispatchEvent(new global.Event("resize"));
      resizeDone = true;
    }
  };

  const carousel = !slides.length ? null : slides.length === 1 ? (
    <div className={styles.carousel}>{slides}</div>
  ) : (
    <Carousel
      className={styles["carousel"]}
      afterSlide={resize}
      options={{
        autoplay: 3000,
        type: "carousel"
      }}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          aria-label="previous slide"
          role="button"
          onClick={previousSlide}
          className={`${styles["slider-nav-button"]} ${styles["prev-button"]}`}
        >
          <Icon type={"angle-left"} />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          aria-label="next slide"
          role="button"
          onClick={nextSlide}
          className={`${styles["slider-nav-button"]} ${styles["next-button"]}`}
        >
          <Icon type={"angle-right"} />
        </button>
      )}
      renderBottomCenterControls={() => {
        return null;
      }}
    >
      {slides}
    </Carousel>
  );

  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container row ${styles.row}`}>
        {carousel}
        {stories[0] && <SmallStoryCardDesktopBigNoBorder story={stories[0]} className={styles["story-1"]} />}
        {stories[1] && <SmallStoryCardDesktopBigNoBorder story={stories[1]} className={styles["story-2"]} />}
        <div className={styles.sidebar}>
          <DfpAd className={styles.ad} adtype="Vikatan_Desktop_AP_ATF_RIGHT_300x250" placement={pagetype} />
          <div className={`${styles["widget"]} hidden-mobile`}>
            <DfpAd
              className={`${styles.ad} hidden-mobile`}
              adtype="Vikatan_Desktop_SP_ATF_Right_300x250"
              placement={pagetype}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OneCarouselTwoStoriesOneAdOneWidget.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    items: PropTypes.array
  }),
  pagetype: PropTypes.string
};
OneCarouselTwoStoriesOneAdOneWidget.storyLimit = 7;

export default OneCarouselTwoStoriesOneAdOneWidget;
