import React from "react";
import PropTypes from "prop-types";
import { wrapCollectionLayout } from "@quintype/components";
import { DfpAd } from "../../atoms/dfp-ad";
import { getType } from "../../../data/collection";
import SliderMain from "../../atoms/slider-main/index";
import SmallStoryCard from "../../atoms/small-story-card/index";
import SmallStoryCardDesktopMedium from "../../atoms/small-story-card-desktop-medium/index";
import SmallStoryCardDesktopVertical from "../../atoms/small-story-card-desktop-vertical/index";
import CollectionSpecialBundle from "../../atoms/collection-special-bundle";
import TrendingStories from "../../atoms/trending-stories";

import styles from "./styles.m.css";

const WrappedTrendingStories = wrapCollectionLayout(TrendingStories);

const SliderCollectionStoriesBundle = ({ stories, collections }) => {
  return (
    <div className={styles["slider-collection-stories-bundle"]}>
      <div className={styles["slider-stories-wrap"]}>
        <SliderMain stories={stories.slice(0, 5)} />
      </div>
      <div className={styles["two-stories-wrap"]}>
        {stories &&
          stories
            .slice(5, 7)
            .map(story => story && <SmallStoryCard key={story.id} story={story} className={styles["story-card"]} />)}
      </div>
      {collections && (
        <div className={styles["collections-wrap"]}>
          {collections
            .slice(0, 1)
            .map(
              collection =>
                collection && (
                  <CollectionSpecialBundle
                    key={collection.id}
                    collection={collection}
                    className={styles["collection-item"]}
                  />
                )
            )}
        </div>
      )}
    </div>
  );
};

SliderCollectionStoriesBundle.propTypes = {
  stories: SliderMain.propTypes.stories,
  collections: PropTypes.arrayOf(CollectionSpecialBundle.propTypes.collection)
};

const FiveStories = ({ stories }) => {
  return (
    <div className={styles["five-story-container"]}>
      {stories &&
        stories
          .slice(0, 1)
          .map(
            story =>
              story && (
                <SmallStoryCardDesktopMedium
                  key={story.id}
                  story={story}
                  className={`${styles["five-cards-item"]} ${styles["story-card-medium"]}`}
                />
              )
          )}
      {stories &&
        stories
          .slice(1, 5)
          .map(
            story =>
              story && (
                <SmallStoryCardDesktopVertical
                  key={story.id}
                  story={story}
                  className={`${styles["five-cards-item"]} ${styles["story-card-small"]}`}
                />
              )
          )}
    </div>
  );
};

FiveStories.propTypes = {
  stories: SliderMain.propTypes.stories
};
FiveStories.storyLimit = 5;

const MainRowSpecialBundle = ({ collection, accentColor, pagetype = "" }) => {
  if (!collection.items) {
    return null;
  }
  const stories = collection.items.filter(item => item && item.type === "story").map(item => item.story);
  const collections = collection.items.filter(item => item && item.type === "collection");

  const collectionBundle = collections.filter(collection => getType(collection) === "bundle").slice(0, 1);
  const trendingCollection =
    collections.filter(collection => getType(collection) === "trending").slice(0, 1)[0] || null;

  return (
    <div className={styles["main-row"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className="row">
          {stories &&
            stories.length > 0 && (
              <SliderCollectionStoriesBundle stories={stories.slice(0, 7)} collections={collectionBundle} />
            )}
          <div className="widget300xauto">
            <DfpAd className={`${styles.ad}`} adtype="Rectangle" placement={pagetype} />
          </div>
          <WrappedTrendingStories collection={trendingCollection} className={styles["trending-stories"]} />
          {stories && stories.length > 7 && <FiveStories stories={stories.slice(7, 12)} />}
          <div className="widget300xauto-targetted">
            <DfpAd className={`${styles.ad}`} adtype="Vikatan_widget" placement={`${pagetype}-section`} />
          </div>
        </div>
      </div>
    </div>
  );
};

MainRowSpecialBundle.propTypes = {
  accentColor: PropTypes.string,
  collection: CollectionSpecialBundle.propTypes.collection,
  pagetype: PropTypes.string
};
MainRowSpecialBundle.storyLimit = 12;

export default MainRowSpecialBundle;
