import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Carousel from "../../atoms/carousel";
import styles from "./styles.m.css";
import SlideBigStoryCard from "../../atoms/slide-big-story-card";
import PhotoAlbum from "../../rows/photo-album";
import { getStoryHeadline } from "../../utils/utils";
import { fetchStories } from "../../../api";
import Icon from "../../atoms/icon";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithSideLine from "../../atoms/CollectionTitleWithSideLine";

const OneStoriesSliderOneStorySlider = ({ collection, accentColor = "#fff", pagetype = "" }) => {
  const items = get(collection, ["items"], []);
  const name = get(collection, ["name"], []);
  let resizeDone = false;
  const stories = items
    .filter(item => item.type === "story")
    .map(item => item.story)
    .filter(story => getStoryHeadline(story) && story.url)
    .slice(0, 6);

  const getStoryId = "c8d83c98-ed9c-497a-8752-b66ba0adc494";

  const [story, setStory] = useState("");
  useEffect(() => {
    fetchStories(getStoryId)
      .then(res => {
        setStory(res);
      })
      .catch(console.error);
  }, []);

  const getStorycard = get(story, ["story", "cards"], []);
  const firstPhotoAlbumElement =
    getStorycard.length > 0 &&
    getStorycard
      .reduce((acc, currEle) => {
        acc = acc.concat(currEle["story-elements"]);
        return acc;
      }, [])
      .find(({ subtype }) => subtype === "image-gallery");

  const renderHeaderPhotoAlbum = () => {
    if (!firstPhotoAlbumElement) {
      return null;
    }
    return <PhotoAlbum storyElement={firstPhotoAlbumElement} pagetype="election" showCount={false} />;
  };

  if (!stories.length) {
    return null;
  }

  const carouselStories = stories.splice(0, 5);

  const slides = carouselStories
    ? carouselStories.map(story => <SlideBigStoryCard key={story.id} story={story} hasTruncatedHeadline={false} />)
    : [];

  const resize = () => {
    if (resizeDone === false) {
      global.dispatchEvent(new global.Event("resize"));
      resizeDone = true;
    }
  };

  const carousel = !slides.length ? null : slides.length === 1 ? (
    <div className={styles.carousel}>{slides}</div>
  ) : (
    <Carousel
      afterSlide={resize}
      options={{
        autoplay: 3000,
        type: "carousel"
      }}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          aria-label="previous slide"
          role="button"
          onClick={previousSlide}
          className={`${styles["slider-nav-button"]} ${styles["prev-button"]}`}
        >
          <Icon type={"angle-left"} />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          aria-label="next slide"
          role="button"
          onClick={nextSlide}
          className={`${styles["slider-nav-button"]} ${styles["next-button"]}`}
        >
          <Icon type={"angle-right"} />
        </button>
      )}
      renderBottomCenterControls={() => {
        return null;
      }}
    >
      {slides}
    </Carousel>
  );

  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container row ${styles.row}`}>
        <div className={styles["carousel"]}>
          <CollectionLink collection={collection}>
            <CollectionTitleWithSideLine title={name} design="outer" />
          </CollectionLink>
          {carousel}
        </div>
        <div className={styles.sidebar}>
          <CollectionTitleWithSideLine title="ரீவைண்ட் க்ளிக்ஸ் 2016" design="outer" />
          {renderHeaderPhotoAlbum()}
        </div>
      </div>
    </div>
  );
};

OneStoriesSliderOneStorySlider.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    items: PropTypes.array
  }),
  pagetype: PropTypes.string
};
OneStoriesSliderOneStorySlider.storyLimit = 7;

export default OneStoriesSliderOneStorySlider;
