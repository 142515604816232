import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithCrossLineBundle from "../../atoms/CollectionTitleWithCrossLineBundle";
import CollectionSelectItem from "../../atoms/collection-select-bundle";
import { getStoryHeadline } from "../../utils/utils";
import Icon from "../../atoms/icon";
import Carousel from "../../atoms/carousel";

import styles from "./styles.m.css";

const SliderFocusedCardBundle = ({
  collection,
  numberOfCardsInViewDesktop = 3,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1,
  accentColor
}) => {
  const { items } = collection;
  const desktopGap = 30;
  const mobileGap = 0;

  let stacks = items.filter(item => item.type === "collection").map((collection, index) => {
    collection = produce(collection, draft => {
      draft.items = draft.items.filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null);
    });
    return (
      <CollectionSelectItem
        key={collection.id}
        collection={collection}
        className={styles.card}
        numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
      />
    );
  });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`container ${styles["carousel-wrapper"]}`}>
          {collection.name && (
            <CollectionLink collection={collection}>
              <CollectionTitleWithCrossLineBundle className={styles.title} title={collection.name} />
            </CollectionLink>
          )}

          <Carousel
            className={styles["carousel"]}
            options={{
              type: "carousel",
              perView: numberOfCardsInViewDesktop,
              gap: desktopGap,
              rewind: false,
              peek: {
                before: 0,
                after: 244
              },
              breakpoints: {
                767: {
                  perView: numberOfCardsInViewMobile,
                  gap: mobileGap,
                  peek: {
                    before: mobileGap,
                    after: 144
                  }
                },
                991: {
                  perView: numberOfCardsInViewTablet,
                  gap: desktopGap,
                  peek: {
                    before: desktopGap,
                    after: 244
                  }
                }
              }
            }}
            renderCenterLeftControls={({ previousSlide, currentSlide }) =>
              currentSlide > 0 ? (
                <button
                  aria-label="previous slide"
                  role="button"
                  onClick={previousSlide}
                  className={`${styles["slider-nav-button"]}`}
                >
                  <Icon type="angle-left" className={styles.icon} />
                </button>
              ) : null
            }
            renderCenterRightControls={({ nextSlide }) => (
              <button
                aria-label="next slide"
                role="button"
                onClick={nextSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="angle-right" className={styles.icon} />
              </button>
            )}
            childClass={styles["glide_li_child"]}
          >
            {stacks}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

SliderFocusedCardBundle.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default SliderFocusedCardBundle;
