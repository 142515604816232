import React from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFeaturedBig from "../../atoms/story-card-featured-big";
import StoryCardFeaturedSmall from "../../atoms/story-card-featured-small";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import styles from "./styles.m.css";

const SixStoryTwoBigStory = ({ collection, accentColor }) => {
  const { name, items } = collection;
  return (
    <div className={`${styles["four-story-half-featured"]}`}>
      <div className={`container`}>
        <CollectionLink collection={collection}>
          <CollectionTitleWithCrossLine className={`${styles.title}`} title={name} />
        </CollectionLink>
        <div className="row">
          {items &&
            items.length > 0 &&
            items
              .slice(0, 2)
              .map(item => (
                <StoryCardFeaturedBig className={styles["story-card-featured-big"]} key={item.id} story={item.story} />
              ))}
          {items &&
            items.length > 2 &&
            items
              .slice(2, 6)
              .map(item => (
                <StoryCardFeaturedSmall
                  className={styles["story-card-featured-small"]}
                  key={item.id}
                  story={item.story}
                />
              ))}
        </div>
        <br />
        <CollectionTitleWithCrossLine className={`${styles.title}`} title={"Social Talks"} />
        <Iframe
          frameBorder="0"
          width="100%"
          height="800px"
          className={styles["corona-main"]}
          url="https://walls.io/q4swp?nobackground=1&show_header=0"
          type="text/html"
          allowscriptaccess="always"
          allowFullScreen="true"
          scrolling="yes"
          allownetworking="all"
        />
      </div>
    </div>
  );
};

SixStoryTwoBigStory.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFeaturedBig.propTypes)),
    accentColor: PropTypes.string
  })
};
SixStoryTwoBigStory.storyLimit = 4;

export default SixStoryTwoBigStory;
