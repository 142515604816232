import React from "react";
import PropTypes from "prop-types";

import StoryCardDL43ML54Design from "../story-card-dl-4-3-ml-54";

import styles from "./styles.m.css";

const StoryStackD3 = ({ bigStory, mobileStoryCount, stories, className }) => {
  if (stories.length < 1) {
    return null;
  }
  const stack = [
    bigStory ? (
      <StoryCardDL43ML54Design story={stories[0]} key={stories[0].id} hasTruncatedHeadline={true} />
    ) : (
      <StoryCardDL43ML54Design story={stories[0]} key={stories[0].id} />
    )
  ].concat(
    stories.slice(1).map((story, index) => (
      <StoryCardDL43ML54Design
        story={story}
        className={`
          ${styles[index + 1 >= mobileStoryCount ? "mobile-hide" : ""]}
          ${styles[index + 2 === mobileStoryCount]}
        `}
        key={story.id}
      />
    ))
  );
  return <div className={`${styles["base"]} ${className}`}>{stack}</div>;
};

StoryStackD3.propTypes = {
  bigStory: PropTypes.bool,
  mobileStoryCount: PropTypes.number,
  stories: PropTypes.arrayOf(StoryCardDL43ML54Design.propTypes.story),
  className: PropTypes.string
};

export default StoryStackD3;
