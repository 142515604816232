import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithCTA from "../../atoms/CollectionTitleWithCTA";
import StoryCardDLTextOverley from "../../atoms/story-card-dl-text-overley";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const SixStoriesWithCard = ({ collection, accentColor }) => {
  const { name, items } = collection;
  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);
  const stories =
    items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, 8)
      .map(({ story }) => (
        <StoryCardDLTextOverley
          className={styles["story-card"]}
          key={story.id}
          story={story}
          pFrom={`${pageName}-six-stories`}
        />
      )) || [];

  if (!stories.length) {
    return null;
  }

  return (
    <div className={`${styles["six-story-4s"]}`}>
      <div className={`${styles["container-story"]} container`}>
        <CollectionLink collection={collection}>
          <CollectionTitleWithCTA className={styles.title} title={name} />
        </CollectionLink>
        <div className={`${styles["story-container"]}`}>{stories}</div>
      </div>
    </div>
  );
};

SixStoriesWithCard.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardDLTextOverley.propTypes))
  })
};
SixStoriesWithCard.storyLimit = 10;

export default SixStoriesWithCard;
