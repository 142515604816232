import React from "react";
import PropTypes from "prop-types";
import Icon from "../../atoms/icon";
import Carousel from "../../atoms/carousel";

import styles from "./styles.m.css";
import AudioCollectionPremiumSlider from "../../atoms/audio-collection-premium-slider";

const AudioSliderCollectionCard = ({
  collection,
  numberOfCardsInViewDesktop = 4.4,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 2.2,
  accentColor,
  mobileslider
}) => {
  const { items } = collection;
  const desktopGap = 0;
  const mobileGap = 0;
  let stacks =
    items &&
    items.filter(item => item.type === "collection").map((collection, index) => {
      return (
        <AudioCollectionPremiumSlider
          key={collection.id}
          collection={collection}
          className={styles.card}
          numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
          hideTitle={true}
          mobileslider={true}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className="hidden-mobile">
        <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
          <div className={`${styles["carousel-wrapper"]}`}>
            {stacks.length > 1 && (
              <Carousel
                className={styles["carousel"]}
                options={{
                  type: "slider",
                  perView: numberOfCardsInViewDesktop,
                  gap: desktopGap,
                  bound: false,
                  startAt: 0,
                  loop: false,
                  hoverpause: true,
                  animationDuration: 1000,
                  breakpoints: {
                    320: {
                      perView: numberOfCardsInViewMobile,
                      gap: mobileGap
                    },
                    767: {
                      perView: numberOfCardsInViewMobile,
                      startAt: 1,
                      gap: mobileGap
                    },
                    991: {
                      perView: numberOfCardsInViewTablet,
                      gap: desktopGap
                    }
                  }
                }}
                renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                  currentSlide > 0 ? (
                    <button
                      aria-label="previous slide"
                      role="button"
                      onClick={previousSlide}
                      className={`${styles["slider-nav-button"]}`}
                    >
                      <Icon type="angle-left" className={styles.icon} />
                    </button>
                  ) : null
                }
                renderCenterRightControls={({ nextSlide }) => (
                  <button
                    aria-label="next slide"
                    role="button"
                    onClick={nextSlide}
                    className={`${styles["slider-nav-button"]}`}
                  >
                    <Icon type="angle-right" className={styles.icon} />
                  </button>
                )}
                renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                  return (
                    <div className={styles.dots}>
                      {items.map((story, index) => (
                        <button
                          title="previous slide"
                          aria-label="next slide"
                          role="button"
                          key={story.id}
                          className={
                            currentSlide === index ? `${styles["bottom-center-controls"]} ${styles["is-active"]}` : ``
                          }
                          onClick={() => {
                            goToSlide(index);
                          }}
                        />
                      ))}
                    </div>
                  );
                }}
                childClass={styles["glide_li_child"]}
              >
                {stacks}
              </Carousel>
            )}
          </div>
        </div>
      </div>
      <div className="hidden-desktop">
        <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
          <div className={`${styles["carousel-wrapper"]} ${styles["carousel-wrapper-mobile"]}`}>
            {stacks.length > 1 && stacks}
          </div>
        </div>
      </div>
    </div>
  );
};

AudioSliderCollectionCard.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  mobileslider: PropTypes.bool
};

export default AudioSliderCollectionCard;
