import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import Icon from "../../atoms/icon";
import { checkAppSlug } from "../../utils/utils";
import styles from "./styles.m.css";

const ReadMoreLink = ({ href, text = "மேலும் படிக்க", externalLink = false }) => {
  if (!href) {
    return null;
  }

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = href;
  }

  return (
    <Link aria-label="Readmore" className={styles["subscription-link"]} href={href} externalLink={SourceLink}>
      <span className={styles["text"]}>
        {text} <Icon type={"arrow-right"} className={styles["arrow-right"]} />
      </span>
    </Link>
  );
};
ReadMoreLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  externalLink: PropTypes.bool
};

export default ReadMoreLink;
