import React from "react";
import { LazyLoadImages } from "@quintype/components";
import { HomePage as HomePageBase } from "../components/pages/home";
import { VikatanHomeHeader } from "../components/rows/ad-free";

function wrapLazyLoadImages(Template) {
  const ImageLazyLoadedComponent = props => {
    return (
      <React.Fragment>
        <VikatanHomeHeader />
        <LazyLoadImages margin={"150px"}>
          <Template {...props} />
        </LazyLoadImages>
      </React.Fragment>
    );
  };
  ImageLazyLoadedComponent.displayName = `WrapLazyLoadImages(${Template.name})`;
  return ImageLazyLoadedComponent;
}

export const HomePage = wrapLazyLoadImages(HomePageBase);
