import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";

import { getStoryData, generateImageSources } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

const AudioStoryCardDLTextOverley = ({
  story,
  collection,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  pFrom = "story-card",
  sectionpagetemplateBigcard = false,
  rectcard = false
}) => {
  const storyData = getStoryData(story);

  const slug = get(collection, ["slug"], "");

  const audiomagazinePagePath = slug => {
    return `/vikatan-play/${slug}`;
  };

  if (!(storyData.headline && story.slug)) {
    return null;
  }

  return (
    <div className={`${className} ${styles["base"]} ${styles[rectcard ? "rectcard" : ""]}`}>
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        className={`${styles["link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""} ${
          styles[sectionpagetemplateBigcard ? "sectionpagetemplatebigcard" : ""]
        } `}
        href={audiomagazinePagePath(slug)}
        // href={`${externalLink || story.url}?pfrom=${pFrom}`}
      >
        <div className={styles["layout"]}>
          <div className={styles["image-container"]}>
            <ResponsiveImageWithFallback
              className={styles["image-wrapper"]}
              slug={storyData.imageS3Key}
              metadata={storyData.imageMetadata}
              isPremium={isPremium(story)}
              alt={storyData.imageCaption}
              imgParams={{ auto: ["format", "compress"] }}
              sources={generateImageSources(
                { aspectRatio: [5, 4], screenWidthCoverage: 0.35 },
                { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
              )}
              story={story}
            />
          </div>
          <div className={`${styles["text-wrapper"]}`}>
            <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
              {storyData.headline}
            </h3>
          </div>
        </div>
      </Link>
    </div>
  );
};

AudioStoryCardDLTextOverley.propTypes = {
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  sectionpagetemplateBigcard: PropTypes.bool,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  story: PropTypes.shape({
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  }),
  pFrom: PropTypes.string,
  rectcard: PropTypes.string
};

export default AudioStoryCardDLTextOverley;
