import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import produce from "immer";
import Carousel from "../../atoms/carousel";
import BigStoryCard from "../../atoms/big-story-card/index";
import SmallStoryCard from "../../atoms/small-story-card/index";
import { DfpAd } from "../../atoms/dfp-ad";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

import { getStoryHeadline } from "../../utils/utils";

const FourteenStories = ({ collection, metadata, accentColor = "#fff", pagetype = "" }) => {
  const { name, items } = collection;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filter, setFilter] = useState(null);

  const handleFilter = (slug, index) => {
    setCurrentIndex(index);
    setFilter(slug);
  };

  let stacks = items.filter(item => item.type === "collection").map((collection, index) => {
    collection = produce(collection, draft => {
      draft.items = draft.items.filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null);
    });
    return (
      <React.Fragment key={collection.id}>
        <div
          className={`${filter === collection.slug && styles["active"]}`}
          onClick={() => handleFilter(collection.slug, index)}
        >
          {collection.name}
        </div>
      </React.Fragment>
    );
  });

  if (!stacks || !stacks.length) {
    return null;
  }

  const stories = get(collection, ["items", [currentIndex], "items"], []).filter(items => items.type === "story");

  return (
    <div className={`${styles["base"]} container`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={styles["filter-slider"]}>
        {name && <h2>{name}</h2>}
        <div className={styles["tag-slider"]}>
          <Carousel
            className={`${styles["first-slider"]}`}
            options={{
              type: "slider",
              focusAt: 0,
              bound: true,
              perView: 4,
              peek: {
                before: 0,
                after: 0
              },
              breakpoints: {
                320: {
                  bound: true,
                  perView: 2,
                  peek: {
                    before: 0,
                    after: 0
                  }
                },
                767: {
                  bound: true,
                  perView: 2,
                  peek: {
                    before: 0,
                    after: 0
                  }
                },
                991: {
                  bound: true,
                  perView: 3,
                  peek: {
                    before: 0,
                    after: 0
                  }
                }
              }
            }}
            renderCenterLeftControls={({ previousSlide }) => (
              <button
                aria-label="previous slide"
                role="button"
                onClick={previousSlide}
                className={`${styles["slider-nav-button"]} ${styles["prev-button"]}`}
              >
                <Icon type={"angle-left"} />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button
                aria-label="next slide"
                role="button"
                onClick={nextSlide}
                className={`${styles["slider-nav-button"]} ${styles["next-button"]}`}
              >
                <Icon type={"angle-right"} />
              </button>
            )}
            renderBottomCenterControls={({ currentSlide, goToSlide }) => {
              return null;
            }}
            childClass={styles["glide__slide__li"]}
          >
            {stacks}
          </Carousel>
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={`${styles.row}`}>
          <div className={styles["desktop-col-1"]}>
            {stories.splice(0, 15).map(story => (
              <SmallStoryCard key={story.id} story={story.story} className={styles["story-card"]} />
            ))}
          </div>
        </div>
        <div className={styles["desktop-col-2"]}>
          <div>
            {stories.splice(0, 2).map(story => (
              <SmallStoryCard key={story.id} story={story.story} className={styles["story-card"]} />
            ))}
          </div>
          <DfpAd
            className={`${styles.ad} ${styles["ad-row-2"]} hidden-mobile`}
            adtype="Vikatan_Desktop_SP_ATF_Right_300x250"
            placement={pagetype}
          />
        </div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(BigStoryCard.propTypes))
});

FourteenStories.propTypes = {
  accentColor: PropTypes.string,
  pagetype: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  })
};

export default FourteenStories;
