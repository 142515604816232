import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";

const FireWorkUnit = ({
  id = "48Tkp_uK3u3QX6tMK5qyB3FGzk36aqYi",
  className = "container",
  title = "Short Videos",
  showTitle = true
}) => {
  useEffect(() => {
    const embedFeed = document.createElement("script");
    embedFeed.src = "//asset.fwcdn2.com/js/embed-feed.js";
    embedFeed.async = true;

    const storyblock = document.createElement("script");
    storyblock.src = "//asset.fwcdn2.com/js/storyblock.js";
    storyblock.async = true;

    document.body.appendChild(embedFeed);
    document.body.appendChild(storyblock);

    return () => {
      document.body.removeChild(embedFeed);
      document.body.removeChild(storyblock);
    };
  }, []);

  return (
    <div className={`${className}`}>
      {showTitle && <h2 className={`${styles.headline}`}>{title}</h2>}
      <fw-embed-feed
        channel="vikatan"
        playlist="gwYYl5"
        mode="row"
        open_in="default"
        max_videos="0"
        placement="middle"
        player_placement="bottom-right"
      />
    </div>
  );
};

FireWorkUnit.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  showTitle: PropTypes.bool
};

export default FireWorkUnit;
