import React from "react";
import PropTypes from "prop-types";
import CollectionPremiumSlider from "../../atoms/collection-premium-slider";
import Icon from "../../atoms/icon";
import Carousel from "../../atoms/carousel";

import styles from "./styles.m.css";

const SliderPremiumCollectionCard = ({
  collection,
  numberOfCardsInViewDesktop = 3,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 2,
  accentColor
}) => {
  const { items } = collection;
  const desktopGap = 30;
  const mobileGap = 10;
  let stacks =
    items &&
    items.filter(item => item.type === "collection").map((collection, index) => {
      return (
        <CollectionPremiumSlider
          key={collection.id}
          collection={collection}
          className={styles.card}
          numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`container ${styles["carousel-wrapper"]}`}>
          {stacks.length > 1 && (
            <Carousel
              className={styles["carousel"]}
              options={{
                type: "carousel",
                perView: numberOfCardsInViewDesktop,
                gap: desktopGap,
                bound: true,
                startAt: 1,
                peek: {
                  before: 80,
                  after: 80
                },
                breakpoints: {
                  320: {
                    perView: numberOfCardsInViewMobile,
                    gap: mobileGap,
                    peek: {
                      before: 60,
                      after: 40
                    }
                  },
                  767: {
                    perView: numberOfCardsInViewMobile,
                    startAt: 1,
                    gap: mobileGap,
                    peek: {
                      before: 20,
                      after: 2
                    }
                  },
                  991: {
                    perView: numberOfCardsInViewTablet,
                    gap: desktopGap
                  }
                }
              }}
              renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                currentSlide > 0 ? (
                  <button
                    aria-label="previous slide"
                    role="button"
                    onClick={previousSlide}
                    className={`${styles["slider-nav-button"]}`}
                  >
                    <Icon type="angle-left" className={styles.icon} />
                  </button>
                ) : null
              }
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  aria-label="next slide"
                  role="button"
                  onClick={nextSlide}
                  className={`${styles["slider-nav-button"]}`}
                >
                  <Icon type="angle-right" className={styles.icon} />
                </button>
              )}
              renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                return (
                  <div className={styles.dots}>
                    {items.map((story, index) => (
                      <button
                        title="previous slide"
                        aria-label="next slide"
                        role="button"
                        key={story.id}
                        className={
                          currentSlide === index ? `${styles["bottom-center-controls"]} ${styles["is-active"]}` : ``
                        }
                        onClick={() => {
                          goToSlide(index);
                        }}
                      />
                    ))}
                  </div>
                );
              }}
              childClass={styles["glide_li_child"]}
            >
              {stacks}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

SliderPremiumCollectionCard.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default SliderPremiumCollectionCard;
