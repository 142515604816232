import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import Contributor from "../contributor/index";
import styles from "./styles.m.css";
import { getStoryHeadline, checkAppSlug } from "../../utils/utils";
import { PremiumBadgeHeadline } from "../../atoms/premium-badge";
import { isPremium } from "../../../../isomorphic/data/story";

const SmallStoryCardNoImageNumber = ({ story, className = "", index }) => {
  const headline = getStoryHeadline(story);
  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  if (!(headline && story.url)) {
    return null;
  }

  return (
    <Link
      aria-label={`${"Read full story: "} ${headline}`}
      className={`${className} ${styles["base"]}`}
      href={externalLink || story.url}
      externalLink={SourceLink || externalLink}
    >
      <div className={styles["text-wrapper"]}>
        <div className={styles["number-frmt"]}>{index}</div>
        <div className={styles["card-content"]}>
          {contributor && (
            <Contributor
              name={contributor["name"]}
              type={contributorRole}
              iconHeight={13}
              iconWidth={13}
              iconColor="#4a4a4a"
              className={styles["contributor"]}
            />
          )}
          <h3 className={`${styles["headline"]}`}>
            <PremiumBadgeHeadline headline={headline} isPremium={isPremium(story)} />
          </h3>
        </div>
      </div>
    </Link>
  );
};

SmallStoryCardNoImageNumber.propTypes = {
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string.isRequired
  }),
  className: PropTypes.string,
  index: PropTypes.number
};

export default SmallStoryCardNoImageNumber;
