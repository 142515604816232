import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import Contributor from "../../atoms/contributor";
import Icon from "../../atoms/icon";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";

import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

export default function StoryCardFeaturedSmall({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  titleColor
}) {
  const storyData = getStoryData(story);

  if (!(storyData.headline && story.url)) {
    return null;
  }

  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");
  const section = get(story, ["sections", 0]);
  const sectionColor = get(story, ["sections", 0, "data", "color"]);
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={externalLink || story.url}
      externalLink={SourceLink || externalLink}
    >
      <div className={styles["image-container"]}>
        <ResponsiveImageWithFallback
          className={styles["figure"]}
          slug={storyData.imageS3Key}
          metadata={storyData.imageMetadata}
          alt={storyData.imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [4, 3], screenWidthCoverage: 0.25 },
            { aspectRatio: [4, 3], screenWidthCoverage: 0.17 }
          )}
          story={story}
        />
        {section && (
          <span
            className={`${styles["badge"]} ${!isPremium(story) ? "hidden-mobile" : ""}`}
            style={sectionColor && { "--accent-color": sectionColor }}
          >
            {isPremium(story) && <Icon type="premium" className={styles["badge-icon"]} />}
            <span className={styles["badge-text"]}>{section["display-name"]}</span>
          </span>
        )}
      </div>
      <div className={styles["content"]}>
        {contributor && (
          <Contributor
            name={contributor["name"]}
            type={contributorRole}
            className={`${styles["contributor"]} ${titleColor ? styles["custom-contributor"] : ""}`}
          />
        )}
        <h3
          className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}
          style={titleColor && { "--title-color": titleColor }}
        >
          {storyData.headline}
        </h3>
      </div>
    </Link>
  );
}

StoryCardFeaturedSmall.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  story: PropTypes.shape({
    section: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string
      })
    ),
    "access-type": PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  }),
  titleColor: PropTypes.string
};
