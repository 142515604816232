import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BigStoryCardCurve from "../big-story-card-curve/index";
import SmallStoryCardCurve from "../small-story-card-curve/index";

import styles from "./styles.m.css";

const FourteenStoryTwoCollectionStoryStack = ({
  bigStory,
  mobileStoryCount,
  stories,
  className,
  verticalimg = true,
  bigimage,
  bigStoryHover = false
}) => {
  if (stories.length < 1) {
    return null;
  }
  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);
  const stack = [
    <BigStoryCardCurve
      story={stories[0]}
      key={stories[0].id}
      className={`${styles["index-one"]}`}
      bigimage={bigimage}
      pFrom={`${pageName}-fourteen-story-collection`}
    />
  ].concat(
    stories.slice(1).map((story, index) => (
      <SmallStoryCardCurve
        story={story}
        verticalimg={verticalimg}
        className={`
          ${styles[index + 1 >= mobileStoryCount ? "" : ""]}
          ${styles["line-separater"]}
          ${styles[index + 2 === mobileStoryCount ? "skip-line" : ""]}
          ${styles[index % 2 === 0 ? "withborder" : "withborder-left"]}
        `}
        key={story.id}
        pFrom={`${pageName}-fourteen-story-collection`}
      />
    ))
  );
  return <div className={`${styles["base"]} ${className}`}>{stack}</div>;
};

FourteenStoryTwoCollectionStoryStack.propTypes = {
  bigStory: PropTypes.bool,
  verticalimg: PropTypes.bool,
  bigimage: PropTypes.bool,
  mobileStoryCount: PropTypes.number,
  stories: PropTypes.arrayOf(BigStoryCardCurve.propTypes.story),
  className: PropTypes.string,
  bigStoryHover: PropTypes.bool
};

export default FourteenStoryTwoCollectionStoryStack;
