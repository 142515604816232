import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import StoryCardDLTextOverley from "../../atoms/story-card-dl-text-overley";
import SmallStoryCardCurve from "../../atoms/small-story-card-curve";
import { getPagePath } from "../../../data/collection";

import styles from "./styles.m.css";
import { VikatanMainResponsive } from "../ad-free";
import Icon from "../../atoms/icon";

const FourStoryOneAdOneWidget = ({
  collection,
  metadata,
  accentColor,
  showReadmore = true,
  pagetype = "",
  index,
  stories,
  className,
  mobileStoryCount,
  verticalimg = true,
  sectionpagetemplate = true,
  sectionpagetemplateBigcard = true
}) => {
  if (stories.length < 1) {
    return null;
  }

  const [userType = "F", setType] = useState(false);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType);
    });
  }, []);

  const stack = [
    <StoryCardDLTextOverley
      story={stories[0]}
      key={stories[0].id}
      sectionpagetemplateBigcard={sectionpagetemplateBigcard}
      className={styles["first-big-card"]}
    />
  ].concat(
    stories.slice(1, 4).map((story, index) => (
      <SmallStoryCardCurve
        story={story}
        verticalimg={verticalimg}
        sectionpagetemplate={sectionpagetemplate}
        key={story.id}
        className={`
          ${styles["line-separater"]}
        `}
      />
    ))
  );

  const stacks = [
    <StoryCardDLTextOverley
      story={stories[0]}
      key={stories[0].id}
      sectionpagetemplateBigcard={sectionpagetemplateBigcard}
      className={styles["first-big-card"]}
    />
  ].concat(
    stories
      .slice(1, 7)
      .map((story, index) => (
        <SmallStoryCardCurve
          story={story}
          verticalimg={verticalimg}
          sectionpagetemplate={sectionpagetemplate}
          key={story.id}
          className={`${styles["line-separater"]}`}
        />
      ))
  );

  return (
    <div>
      {userType === "P" || userType === "T" ? (
        <div className={`${styles["base"]}`}>
          <div className={`${styles["card"]} container`}>
            <div className={styles["page-content-grid-paid"]}>
              <div className={styles["main-content-paid"]}>
                {stacks}
                <div className={styles["read-more-wrapper"]}>
                  {showReadmore && (
                    <Link href={getPagePath(collection)} text={"மேலும் படிக்க"} className={styles["read-more"]}>
                      <span>மேலும் படிக்க</span>
                      <Icon type="slider-next" className={styles["arrow"]} />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles["base"]}`}>
          <div className={`${styles["card"]} container`}>
            <div className={styles["page-content-grid"]}>
              <div className={styles["main-content"]}>
                {stack}
                <div className={styles["read-more-wrapper"]}>
                  {showReadmore && (
                    <Link href={getPagePath(collection)} text={"மேலும் படிக்க"} className={styles["read-more"]}>
                      <span>மேலும் படிக்க</span>
                      <Icon type="slider-next" className={styles["arrow"]} />
                    </Link>
                  )}
                </div>
              </div>
              <div className={`${styles["sidebar"]} hidden-mobile`}>
                <VikatanMainResponsive
                  placement={pagetype}
                  MobileAdtype="Vikatan_mrec_mid_SP"
                  DesktopAdtype="Vikatan_Desktop_SP_ATF_Right_300x250"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FourStoryOneAdOneWidget.propTypes = {
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  accentColor: PropTypes.string,
  showReadmore: PropTypes.bool,
  bigimage: PropTypes.bool,
  verticalimg: PropTypes.bool,
  sectionpagetemplate: PropTypes.bool,
  sectionpagetemplateBigcard: PropTypes.bool,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardDLTextOverley.propTypes))
  }),
  stories: PropTypes.arrayOf(StoryCardDLTextOverley.propTypes.story),
  className: PropTypes.string,
  pagetype: PropTypes.string,
  index: PropTypes.number,
  mobileStoryCount: PropTypes.number
};

export default FourStoryOneAdOneWidget;
