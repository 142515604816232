import React from "react";
import PropTypes from "prop-types";
import BigStoryCard from "../../atoms/big-story-card/index";
import StoryCardFullImg1X3 from "../../atoms/story-card-full-img-1-3";
import SmallStoryCard from "../../atoms/small-story-card/index";

import styles from "./styles.m.css";

const StoryStack = ({ bigStory, mobileStoryCount, stories, className, bigStoryHover = false }) => {
  if (stories.length < 1) {
    return null;
  }
  const stack = [
    bigStory ? (
      bigStoryHover ? (
        <StoryCardFullImg1X3
          story={stories[0]}
          key={stories[0].id}
          className={`${styles["eight-story-base"]}`}
          hasTruncatedHeadline={false}
        />
      ) : (
        <BigStoryCard story={stories[0]} key={stories[0].id} />
      )
    ) : (
      <SmallStoryCard story={stories[0]} className={`${styles["line-separater"]}`} key={stories[0].id} />
    )
  ].concat(
    stories.slice(1).map((story, index) => (
      <SmallStoryCard
        story={story}
        className={`
          ${styles[index + 1 >= mobileStoryCount ? "mobile-hide" : ""]}
          ${styles["line-separater"]}
          ${styles[index + 2 === mobileStoryCount ? "skip-line" : ""]}
        `}
        key={story.id}
      />
    ))
  );
  return <div className={`${styles["base"]} ${className}`}>{stack}</div>;
};

StoryStack.propTypes = {
  bigStory: PropTypes.bool,
  mobileStoryCount: PropTypes.number,
  stories: PropTypes.arrayOf(BigStoryCard.propTypes.story),
  className: PropTypes.string,
  bigStoryHover: PropTypes.bool
};

export default StoryStack;
