import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFullWidth from "../../atoms/story-card-full-width";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";
import BigStoryCardCurve from "../../atoms/big-story-card-curve";
import SmallStoryCardCurve from "../../atoms/small-story-card-curve";

export const SevenStory7sfirstbigcard = ({ collection, iconbottomright = true, videocard = true }) => {
  const { name, items } = collection;
  const MaxNumberOfThumbnailStories = 10;
  return (
    <div className={`${styles["seven-story-7s"]}`}>
      <div className={`${styles["video-collection"]} container`}>
        <div>
          <CollectionLink collection={collection}>
            <h2 className={`${styles.title}`}>
              {name}
              <span className={styles["collection-cta"]}>
                <Icon type={"slider-next"} />
              </span>
            </h2>
          </CollectionLink>
        </div>
        <div className={styles["grid-sec"]}>
          <div>
            {items &&
              items.length > 0 && (
                <BigStoryCardCurve
                  className={styles["story-card-full-width"]}
                  key={items[0].id}
                  story={items[0].story}
                  videocard={videocard}
                  iconbottomright={iconbottomright}
                />
              )}
          </div>
          <div className={styles["gradient-container"]}>
            <div className={styles["thumb-story-container"]}>
              {items &&
                items.length > 1 &&
                items
                  .slice(1, MaxNumberOfThumbnailStories + 1)
                  .map(item => (
                    <SmallStoryCardCurve
                      className={styles["story-card"]}
                      key={item.id}
                      story={item.story}
                      videocard={videocard}
                      iconbottomright={iconbottomright}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const VikatanTV = ({ collection, BgPattern = "", accentColor }) => {
  const VikatanTVIcon = () => <Icon type="vikatan-tv" className={styles.icon} />;
  return (
    <SevenStory7sfirstbigcard
      style={accentColor && { "--accent-color": accentColor }}
      collection={collection}
      BgPattern={BgPattern}
      Icon={VikatanTVIcon}
    />
  );
};

VikatanTV.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFullWidth.propTypes))
  }),
  BgPattern: PropTypes.string,
  iconbottomright: PropTypes.string,
  videocard: PropTypes.string
};

SevenStory7sfirstbigcard.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFullWidth.propTypes))
  }),
  Icon: PropTypes.func,
  BgPattern: PropTypes.string,
  iconbottomright: PropTypes.string,
  videocard: PropTypes.string
};
SevenStory7sfirstbigcard.storyLimit = 10;

export default SevenStory7sfirstbigcard;
