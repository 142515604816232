import React from "react";
import PropTypes from "prop-types";
import produce from "immer";

import BigStoryCardCurve from "../../atoms/big-story-card-curve/index";
import MagazineCollectionStoryStack from "../../atoms/magazine-collection-story-stack/index";

import styles from "./styles.m.css";

import { wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";
import Media from "react-media";
import { PGAdwithAd } from "../ad-free";

import Carousel from "../../atoms/carousel";
import Icon from "../../atoms/icon";

const WrappedStoryStack = wrapCollectionLayout(MagazineCollectionStoryStack);

const mobileStoryCount = 4;

const desktopEvenStoryCount = 4;

const MagazineCollection = ({ collection, metadata, accentColor, pageType, stories, className }) => {
  const { items } = collection;

  const adUnitPrefix = pageType === "home-page" ? "HP" : "SP";
  let stacks = items
    .filter(item => item.type === "collection")
    .slice(0, 4)
    .map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, index % 2 === 0 ? desktopEvenStoryCount : desktopEvenStoryCount);
      });
      return (
        <React.Fragment key={collection.id}>
          <WrappedStoryStack bigStory={index % 2 === 0} mobileStoryCount={mobileStoryCount} collection={collection} />
        </React.Fragment>
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["base"]}>
      <div className={`${styles["container-story"]} container`}>
        <div className={styles["stack-grid"]}>
          {stacks.length >= 0 && (
            <Carousel
              className={`${styles["slider-magazine-collection"]} ${className}`}
              options={{
                type: "carousel",
                perView: 3,
                gap: 20,
                focusAt: 0,
                bound: true,
                peek: {
                  before: -12,
                  after: 120
                },
                breakpoints: {
                  320: {
                    perView: 1,
                    gap: 20,
                    bound: true,
                    peek: {
                      before: 0,
                      after: 120
                    }
                  },
                  767: {
                    perView: 0.8,
                    gap: 10,
                    bound: true,
                    peek: {
                      before: 0,
                      after: 120
                    }
                  },
                  991: {
                    perView: 1,
                    gap: 20,
                    bound: true,
                    peek: {
                      before: 0,
                      after: 120
                    }
                  }
                }
              }}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  title="previous slide"
                  aria-label="previous slide"
                  role="button"
                  onClick={previousSlide}
                  className={`${styles["slider-nav-button"]} ${styles["prev-button"]}`}
                >
                  <Icon type={"slider-prev"} />
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  title="next slide"
                  aria-label="next slide"
                  role="button"
                  onClick={nextSlide}
                  className={`${styles["slider-nav-button"]} ${styles["next-button"]}`}
                >
                  <Icon type={"slider-next"} />
                </button>
              )}
              renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                return null;
              }}
              childClass={styles["glide_li"]}
            >
              {stacks}
            </Carousel>
          )}
        </div>
        <Media query="(max-width:768px)">
          <PGAdwithAd unit={`Vikatan_mobile_mid_${adUnitPrefix}`} class="d_300_250" />
        </Media>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(BigStoryCardCurve.propTypes))
});

MagazineCollection.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  pageType: PropTypes.string,
  stories: PropTypes.arrayOf(BigStoryCardCurve.propTypes.story),
  className: PropTypes.string
};

export default MagazineCollection;
