import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources } from "../../utils/utils";

const CollectionCoverImage = ({ collection, cardWithImageZoom = true, className = "" }) => {
  const headline = get(collection, ["name"], "");
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["slug"], "");
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");

  if (!(headline && slug)) {
    return null;
  }
  return (
    <ResponsiveImageWithFallback
      className={styles["image-wrapper"]}
      slug={coverImageS3Key}
      metadata={coverImageMetaData}
      alt={imageCaption}
      imgParams={{ auto: ["format", "compress"] }}
      sources={generateImageSources(
        { aspectRatio: [5, 1], screenWidthCoverage: 1 },
        { aspectRatio: [5, 1], screenWidthCoverage: 1 }
      )}
    />
  );
};

CollectionCoverImage.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  className: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

export default CollectionCoverImage;
