import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Carousel from "../../atoms/carousel";
import Icon from "../../atoms/icon";
import styles from "./styles.m.css";
import { fetchStateElectionResult } from "../../../api";
import { Link } from "@quintype/components";

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    if (chunk.length > 0 && chunk[0].state_tn !== "") {
      chunks.push(chunk);
    }
  }
  return chunks;
};

const ResultCard = ({ results }) => (
  <div className={styles["grid-item-container"]}>
    {results.map((result, index) => (
      <div key={index} className={styles["grid-item"]}>
        <div className={styles["result-title"]}>{get(result, ["state_tn"], "")}</div>
        <div className={styles["result-seats"]}>
          Seats: <span>{get(result, ["seats"], "")}</span>
        </div>
        {result.alliance_Parties.map((party, i) => (
          <div key={i} className={styles["points"]}>
            <div className={styles["party"]}>
              {get(party, ["party_symbol"]) && (
                <img src={get(party, ["party_symbol"], "")} alt={get(party, ["party_symbol"], "")} />
              )}
              <p className={styles["party-text"]}>{get(party, ["alliance_party"], "")}</p>
            </div>
            <div className={styles["rate"]}>
              <p className={styles["party-text"]}>{get(party, ["count"], "-")}</p>
            </div>
          </div>
        ))}
      </div>
    ))}
  </div>
);

ResultCard.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      state_tn: PropTypes.string.isRequired,
      seats: PropTypes.string.isRequired,
      alliance_Parties: PropTypes.arrayOf(
        PropTypes.shape({
          alliance_party: PropTypes.string.isRequired,
          party_symbol: PropTypes.string.isRequired,
          Seats: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

const StateElectionResultWidget = ({
  customLink,
  numberOfCardsInViewDesktop = 2.1,
  numberOfCardsInViewTablet = 2.1,
  numberOfCardsInViewMobile = 1.2
}) => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      fetchStateElectionResult()
        .then(res => {
          const candidateArray = Object.values(res).map(candidate => ({
            state_tn: get(candidate, ["state_tn"], ""),
            seats: get(candidate, ["seats"], ""),
            alliance_Parties: get(candidate, ["alliance_Parties"], []).map(party => ({
              alliance_party: get(party, ["alliance_party"], ""),
              party_symbol: get(party, ["party_symbol"], ""),
              count: get(party, ["count"], "")
            }))
          }));
          setCandidates(candidateArray);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch candidates");
          setLoading(false);
        });
    };

    fetchData(); // Fetch data initially

    const interval = setInterval(fetchData, 300000); // Fetch data every 5 seconds

    return () => clearInterval(interval); // Cleanup
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const candidateChunks = chunkArray(candidates, 1);

  return (
    <div className={styles["rectangle-container"]}>
      <div className={styles["grid-container"]}>
        <h1 className={styles["star-title"]}>
          <span>மாநிலங்கள் நிலவரம்</span>
          <Link
            href="https://www.vikatan.com/government-and-politics/election/lok-sabha-election-2024-results-live-update-of-national-level-and-states-wise?pfrom=homepage"
            className={styles["title-link"]}
          >
            <span>Live Updates</span>
            <Icon type="slider-next" className={styles["arrow-icon"]} />
          </Link>
        </h1>
        {candidateChunks.length > 0 && (
          <Carousel
            className={styles["carousel"]}
            options={{
              type: "carousel",
              autoPlay: false,
              perView: numberOfCardsInViewDesktop,
              gap: 10,
              dots: true,
              rewind: false,
              breakpoints: {
                767: { perView: numberOfCardsInViewMobile, gap: 10 },
                991: { perView: numberOfCardsInViewTablet, gap: 10 }
              }
            }}
            renderCenterLeftControls={({ previousSlide, currentSlide }) =>
              currentSlide > 0 ? (
                <button
                  aria-label="previous slide"
                  role="button"
                  onClick={previousSlide}
                  className={styles["slider-nav-button"]}
                >
                  <Icon type="angle-left" className={styles.icon} />
                </button>
              ) : null
            }
            renderCenterRightControls={({ nextSlide, currentSlide }) =>
              currentSlide < candidateChunks.length - 1 ? (
                <button
                  aria-label="next slide"
                  role="button"
                  onClick={nextSlide}
                  className={styles["slider-nav-button"]}
                >
                  <Icon type="angle-right" className={styles.icon} />
                </button>
              ) : null
            }
            renderBottomCenterControls={({ currentSlide, goToSlide }) => (
              <div className={styles.dots}>
                {candidateChunks.map((_, index) => (
                  <button
                    key={index}
                    className={currentSlide === index ? styles["is-active"] : ""}
                    onClick={() => goToSlide(index)}
                  />
                ))}
              </div>
            )}
            childClass={styles["glide_li"]}
          >
            {candidateChunks.map((chunk, index) => (
              <ResultCard key={index} results={chunk} />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

StateElectionResultWidget.propTypes = {
  customLink: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default StateElectionResultWidget;
