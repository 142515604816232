import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import Contributor from "../../atoms/contributor";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";

import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

const StoryCardDL43ML54 = ({ story, className = "", cardWithImageZoom = true }) => {
  const storyData = getStoryData(story);

  if (!storyData.headline || !story["url"]) {
    return null;
  }

  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["contributors", 0, "role-name"], "");
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  return (
    <div className={`${className} ${styles["base"]}`}>
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        className={`${styles["link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
        href={externalLink || story.url}
        externalLink={SourceLink || externalLink}
      >
        <div className={styles["layout"]}>
          <div className={styles["image-container"]}>
            <ResponsiveImageWithFallback
              className={styles["image-wrapper"]}
              slug={storyData.imageS3Key}
              metadata={storyData.imageMetadata}
              isPremium={isPremium(story)}
              alt={storyData.imageCaption}
              imgParams={{ auto: ["format", "compress"] }}
              sources={generateImageSources(
                { aspectRatio: [5, 4], screenWidthCoverage: 0.35 },
                { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
              )}
              story={story}
            />
          </div>
          <div className={`${styles["text-wrapper"]}`}>
            {contributor && (
              <Contributor
                name={contributor["name"]}
                type={contributorRole}
                iconColor="#4a4a4a"
                className={styles["contributor"]}
              />
            )}
            <h3 className={styles["headline"]}>{storyData.headline}</h3>
          </div>
        </div>
      </Link>
    </div>
  );
};

StoryCardDL43ML54.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  story: PropTypes.shape({
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  })
};

export default StoryCardDL43ML54;
