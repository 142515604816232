import React from "react";
import PropTypes from "prop-types";

import MainCardStory from "../../atoms/main-card-story/index";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const SliderMain5s = ({ stories, className = "", topFullView = true, utmURL = "" }) => {
  stories = stories.filter(story => story.url && getStoryHeadline(story));
  if (stories.length === 0) {
    return null;
  }
  let slides = stories.map(story => (
    <MainCardStory
      className={styles.card}
      key={story.id}
      story={story}
      topFullView={topFullView}
      authorName={false}
      utmURL={utmURL}
    />
  ));

  if (slides.length === 1) {
    return <div>{slides}</div>;
  }

  return (
    <div
      className={`${styles["main-slider-collection"]} ${
        topFullView ? styles["main-card-grid"] : styles["not-main-card"]
      } `}
    >
      {slides}
    </div>
  );
};

SliderMain5s.propTypes = {
  stories: PropTypes.arrayOf(MainCardStory.propTypes.story),
  className: PropTypes.string,
  topFullView: PropTypes.string,
  utmURL: PropTypes.string
};

export default SliderMain5s;
