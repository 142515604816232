import React from "react";
import PropTypes from "prop-types";
import StoryCardBig from "../../atoms/story-card-responsive-same";
import StoryCardDL1MP54 from "../../atoms/story-card-dl-1-ml-5-4";
import StoryCardDP95MP32 from "../../atoms/story-card-dp-9-5-mp-3-2";
import StoryCardDP95MP95 from "../../atoms/story-card-dp-9-5-mp-9-5";
import StoryCardDL43ML54 from "../../atoms/story-card-dl-4-3-ml-5-4";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import { VikatanMainResponsive } from "../ad-free";

const FiveStoryOneAd = ({ collection, accentColor, pagetype = "" }) => {
  if (!collection) {
    return null;
  }

  const items = ([...collection.items] || []).filter(({ story = {} }) => getStoryHeadline(story) && story.url);
  if (!items.length) {
    return null;
  }

  return (
    <div className={`${styles["base"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className={`${styles["row"]}`}>
          <div className={styles["main-content"]}>
            <StoryCardDP95MP32 story={items[0].story} />
          </div>
          <div className={styles["second-column"]}>
            {!!items[1] && <StoryCardDP95MP95 story={items[1].story} />}
            {!!items[2] && <StoryCardDL43ML54 story={items[2].story} />}
            {!!items[3] && <StoryCardDL43ML54 story={items[3].story} />}
          </div>
          <div className={styles["third-column"]}>
            <VikatanMainResponsive
              placement="magazine-landing-page"
              MobileAdtype="Vikatan_mrec_mid_SP"
              DesktopAdtype="Vikatan_Desktop_SP_ATF_Right_300x250"
            />
            <div className={styles["bottom-aligned"]}>{!!items[4] && <StoryCardDL1MP54 story={items[4].story} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FiveStoryOneAd.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardBig.propTypes))
  }),
  pagetype: PropTypes.string
};
FiveStoryOneAd.storyLimit = 5;

export default FiveStoryOneAd;
