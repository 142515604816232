import React from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import FaqSection from "../../atoms/faq-section";
import StoryCardFeaturedBig from "../../atoms/story-card-featured-big";
import styles from "./styles.m.css";

const FaqWithForm = ({ collection, accentColor }) => {
  const { name } = collection;
  return (
    <div>
      <div className={`${styles["faq-main"]} container`}>
        <div className={styles["grid-one-two"]}>
          <div className={styles["faq-div"]}>
            <h1>{name}</h1>
            <FaqSection />
          </div>
          <div className={styles["iframe-form"]}>
            <Iframe
              url="https://special.vikatan.com/coronavirus/react_corono_form.php"
              width="100%"
              frameBorder="0"
              className={styles["faq-form"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FaqWithForm.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFeaturedBig.propTypes)),
    accentColor: PropTypes.string
  })
};
FaqWithForm.storyLimit = 4;

export default FaqWithForm;
