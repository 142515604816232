import React from "react";
import PropTypes from "prop-types";
import CollectionTitleWithSeries from "../../atoms/CollectionTitleWithSeries";
import CollectionSeriesItem from "../../atoms/collection-series-bundle";
import Icon from "../../../../isomorphic/components/atoms/icon";

import { get } from "lodash";

import styles from "./styles.m.css";
import { Link } from "@quintype/components";

const SeriesCollectionCardList = ({
  collection,
  numberOfCardsInViewDesktop = get(collection, ["associated-metadata", "number_of_stories_to_show"], ""),
  numberOfCardsInViewTablet = 4,
  numberOfCardsInViewMobile = 1.4,
  accentColor
}) => {
  const { items } = collection;
  const slug = get(collection, ["slug"], "");
  let stacks =
    items &&
    items.filter(item => item.type === "collection").map((collection, index) => {
      return (
        <CollectionSeriesItem
          key={collection.id}
          collection={collection}
          className={styles.card}
          numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }
  const premiumseriesTitlePagePath = slug => {
    return `/collection/${slug}`;
  };

  let SourceLink = "";

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`container ${styles["carousel-wrapper"]} ${styles["collection-wrapper"]}`}>
          <div className={styles["collection-series-wrapper"]}>
            {collection.name && (
              <Link
                href={premiumseriesTitlePagePath(slug)}
                externalLink={SourceLink}
                className={styles["collection-read-more-title"]}
              >
                <CollectionTitleWithSeries className={styles.title} title={collection.name} />

                <Icon type={"slider-next"} className={styles["read-more-next-icon"]} />
              </Link>
            )}
            <div className={styles["collection-series-grid"]}>{stacks}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

SeriesCollectionCardList.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default SeriesCollectionCardList;
