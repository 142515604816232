import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFeaturedBig from "../../atoms/story-card-featured-big";
import StoryCardFeaturedSmall from "../../atoms/story-card-featured-small";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import styles from "./styles.m.css";

const CustomFourStoryHalfFeatured = ({ collection }) => {
  const { name, items } = collection;
  return (
    <div className={`${styles["four-story-half-featured"]}`}>
      <div className={`container`}>
        <CollectionLink collection={collection}>
          <CollectionTitleWithCrossLine className={`${styles.title}`} title={name} />
        </CollectionLink>
        <div className="row">
          {items &&
            items.length > 0 && (
              <StoryCardFeaturedBig
                className={styles["story-card-featured-big"]}
                key={items[0].id}
                story={items[0].story}
                titleColor="#fff"
              />
            )}
          {items &&
            items.length > 1 &&
            items
              .slice(1, 4)
              .map(item => (
                <StoryCardFeaturedSmall
                  className={styles["story-card-featured-small"]}
                  key={item.id}
                  story={item.story}
                  titleColor="#fff"
                />
              ))}
        </div>
      </div>
    </div>
  );
};

CustomFourStoryHalfFeatured.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFeaturedBig.propTypes)),
    accentColor: PropTypes.string
  })
};
CustomFourStoryHalfFeatured.storyLimit = 4;

export default CustomFourStoryHalfFeatured;
