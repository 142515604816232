import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";

const CollectionTitle = ({ title, className = "" }) => {
  if (!title) {
    return null;
  }

  return (
    <h2 className={`${styles.title} ${className}`}>
      <span className={styles.text}>{title}</span>
    </h2>
  );
};

CollectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CollectionTitle;
