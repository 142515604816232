import React, { useState, useEffect } from "react";
import styles from "./styles.m.css";
import { fetchWCSchedule } from "../../../api";

const CricketSchedule = () => {
  const dateOptions = { weekday: "short", day: "numeric", month: "short" };
  const timeOptionsLocal = { hour: "2-digit", minute: "2-digit", hour12: true, timeZone: "Asia/Kolkata" };

  const [scheduleData, setScheduleData] = useState([]);

  useEffect(() => {
    fetchWCSchedule()
      .then(data => {
        // Filter and limit the data to the next 10 matches
        const currentDate = new Date();
        const nextMatches = data.matches.filter(match => new Date(match.start_at * 1000) >= currentDate);
        const limitedData = nextMatches.slice(0, 12);

        // Set the limited data in the state
        setScheduleData(limitedData);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className={styles.base}>
      <h1 className={styles["schedule-title"]}>Schedule</h1>
      <table className={styles["schedule-table"]}>
        <thead>
          <tr>
            <th>Match</th>
            <th>Date</th>
            <th>Time(IST)</th>
          </tr>
        </thead>
        <tbody>
          {scheduleData.map((match, index) => (
            <tr key={index}>
              <td style={{ color: match.short_name.includes("IND") ? "red" : "black" }}>{match.short_name}</td>
              <td style={{ color: match.short_name.includes("IND") ? "red" : "black" }}>
                {new Date(match.start_at * 1000).toLocaleDateString("en-US", dateOptions)}
              </td>
              <td style={{ color: match.short_name.includes("IND") ? "red" : "black" }}>
                {new Date(match.start_at * 1000).toLocaleTimeString("en-US", timeOptionsLocal)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CricketSchedule;
