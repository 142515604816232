import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { fetchConstituency, fetchElectionComment, postComments } from "../../../api";

import Select from "react-select";

import styles from "./styles.m.css";
import Icon from "../../atoms/icon";
import { Link } from "@quintype/components";

class AutoSelectComponent extends React.Component {
  logChange = val => {
    const { SelectedValue } = this.props;
    SelectedValue(val.value);
  };

  render() {
    const { options, className } = this.props;
    return (
      <Select className={styles[className]} options={options} onChange={this.logChange} placeholder="உங்கள் தொகுதி*" />
    );
  }
}

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) return content;
  return content.slice(0, maxLength) + "...";
};

AutoSelectComponent.propTypes = {
  options: PropTypes.array,
  SelectedValue: PropTypes.func,
  className: PropTypes.string
};

const LoksabhaElectionCommentsWidget = props => {
  const [constituency, setConstituency] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  const [username, setUsername] = useState("");
  const [description, setDescription] = useState("");
  const [formError, setFormError] = useState(false);
  const [visibleComments, setVisibleComments] = useState(5);
  const slug = get(props, ["slug"], "");

  useEffect(() => {
    fetchConstituency()
      .then(res => {
        const conRes = get(res, ["data", "items"]);
        setConstituency(conRes);
      })
      .catch(console.error);

    fetchElectionComment(slug)
      .then(res => {
        const comments = get(res, ["data", "items", "comments_info"]);
        setComments(comments);
      })
      .catch(console.error);
  }, []);

  const allContOp =
    constituency &&
    constituency.map(item => {
      const objItem = {};
      objItem.label = `${item.area} (${item.area_en})`;
      objItem.value = item.thokkuthi_slug;
      return objItem;
    });

  const handleSubmit = () => {
    // Validate form fields
    if (username && description && selectedConstituency) {
      // All fields are filled, proceed to submit
      const formData = {
        username: username,
        comments: description,
        id: selectedConstituency
      };

      console.log("formData--", formData);

      // Post data to API
      postComments(formData)
        .then(response => {
          setUsername("");
          setDescription("");
          setSelectedConstituency(null);
          setShowSuccess(true);
          console.log("Form data submitted successfully:", response);
        })
        .catch(error => {
          // Handle error
          console.error("Error submitting form data:", error);
        });
    } else {
      // Display error message or take appropriate action
      console.error("Please fill in all fields before submitting the form.");
      setFormError(true);
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);

  const loadMore = () => {
    setVisibleComments(prevVisibleComments => prevVisibleComments + 10); // Show next 5 comments
  };

  const toggleCommentExpansion = commentIndex => {
    setComments(prevComments =>
      prevComments.map(
        (comment, index) => (index === commentIndex ? { ...comment, expanded: !comment.expanded } : comment)
      )
    );
  };

  return (
    <div className={`${styles["election-container"]} container`}>
      <div className={styles["form-grid"]}>
        <div className={styles["form-txt"]}>
          <h1>
            <span>உங்கள் தொகுதி; முக்கிய பிரச்னைகள் என்னென்ன?</span>
          </h1>
        </div>
        <div className={styles["form-content"]}>
          <p>
            உங்கள் தொகுதியின் முக்கிய பிரச்னைகளை இங்கே பதியுங்கள்; இவை அனைத்தும் வெற்றிபெறும் எம்.பி-க்களிடம்
            சேர்க்கப்படும்!
          </p>
          {!showSuccess && (
            <div className={styles["form"]}>
              <div>
                <input
                  type="text"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  placeholder="பெயர்*"
                  className={formError && !username ? styles["error"] : ""}
                />
              </div>
              <div>
                <div
                  className={`${styles["search-field"]}  ${formError && !selectedConstituency ? styles["error"] : ""}`}
                >
                  <AutoSelectComponent
                    className="districk"
                    options={allContOp}
                    SelectedValue={e => setSelectedConstituency(e)}
                  />
                </div>
              </div>
              <div className={styles["textarea-sec"]}>
                <textarea
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  placeholder="தொகுதியின் முக்கிய பிரச்னைகள்*"
                  className={formError && !description ? styles["error"] : ""}
                />
              </div>
              <div className={styles["submit-sec"]}>
                <input type="button" value="உங்கள் கருத்தை சமர்ப்பிக்க" onClick={handleSubmit} />
              </div>
            </div>
          )}
          {showSuccess && (
            <div className={styles["success-content"]}>
              <Icon type="tick" />
              <p>
                உங்கள் கருத்து வெற்றிகரமாகப் பதிவு செய்தமைக்கு நன்றி. ஜூனியர் விகடன் ஆசிரியர் குழுவின் பரிசீலனைக்குப்
                பின்னர் அது உங்கள் தொகுதியின் சிறப்பு பக்கத்தில் பிரசுரமாகும். தொடர்ந்து விகடனுடன் இணைந்திருங்கள்!
              </p>
            </div>
          )}
        </div>
      </div>

      {comments &&
        comments.length > 0 && (
          <div className={styles["comments-wrapper"]}>
            <div className={styles["comments-wrapper-section"]}>
              <h1>வாசகர்களின் கருத்துகள்</h1>
              <div className={styles["comments-height"]}>
                {comments.slice(0, visibleComments).map((comment, index) => (
                  <ul key={index} className={styles["comments-section"]}>
                    <li className={styles["comments-header"]}>
                      <Icon type="icon-user" />
                      <div>
                        <h2>{get(comment, ["user_name"], "")}</h2>
                        <h3>
                          <Link
                            href={`/election/lok-sabha-election-2024/constituency/${get(
                              comment,
                              ["thokuthi_name_en"],
                              ""
                            ).toLowerCase()}`}
                          >
                            {get(comment, ["thokuthi_name"], "")}
                          </Link>
                        </h3>
                      </div>
                    </li>
                    <li className={styles["comments-pblm"]}>
                      {comment && comment.expanded ? (
                        <div>
                          {get(comment, ["thokuthi_pblm"], "")}
                          <button onClick={() => toggleCommentExpansion(index)} className={styles["read-more-less"]}>
                            Read Less
                          </button>
                        </div>
                      ) : (
                        <div>
                          {truncateContent(get(comment, ["thokuthi_pblm"], ""), 200)}
                          {get(comment, ["thokuthi_pblm"], "").length > 200 && (
                            <button onClick={() => toggleCommentExpansion(index)} className={styles["read-more-less"]}>
                              Read More
                            </button>
                          )}
                        </div>
                      )}
                    </li>
                  </ul>
                ))}
              </div>
              {visibleComments < comments.length && (
                <button onClick={loadMore} className={styles["load-more"]}>
                  Load More
                </button>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default LoksabhaElectionCommentsWidget;
