import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";

import { isPremium } from "../../../data/story";

import styles from "./styles.m.css";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";

const SubdomainsBigStoryCard = ({
  story,
  cardWithImageZoom = true,
  className = "",
  hasTruncatedHeadline = true,
  bigimage = true,
  pFrom = "vikatan-web",
  iconbottomright = false,
  videocard = false,
  iplcollection = false
}) => {
  const storyData = getStoryData(story, false);

  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  if (!(storyData.headline && story.url)) {
    return null;
  }

  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""} ${
        videocard ? styles["hasvideobigcard"] : styles["hasnotvideobigcard"]
      }  ${iplcollection ? styles["hasiplcollection"] : styles["hasnotiplcollection"]}`}
      href={`${externalLink || story.url}?utm_source=${pFrom}`}
      externalLink={SourceLink || externalLink}
    >
      <div
        className={`${styles["base"]} ${bigimage ? styles["fullheightimage"] : ""} ${
          iconbottomright ? styles["hasicon"] : styles["hasnoticon"]
        }`}
      >
        <div className={styles["image-with-icon"]}>
          <ResponsiveImageWithFallback
            className={styles["image-wrapper"]}
            slug={storyData.imageS3Key}
            metadata={storyData.imageMetadata}
            isPremium={isPremium(story)}
            alt={storyData.imageCaption}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [30, 18], screenWidthCoverage: 0 },
              { aspectRatio: [9, 6], screenWidthCoverage: 0.25 }
              // { aspectRatio: [9, 6], screenWidthCoverage: 0.5 },
              // { aspectRatio: [9, 6], screenWidthCoverage: 0.25 }
            )}
            story={story}
          />
        </div>
        <div className={styles["text-wrapper"]}>
          <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
            {storyData.headline}
          </h3>
        </div>
      </div>
    </Link>
  );
};

SubdomainsBigStoryCard.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  className: PropTypes.string,
  hasTruncatedHeadline: PropTypes.bool,
  bigimage: PropTypes.bool,
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  }),
  pFrom: PropTypes.string,
  iconbottomright: PropTypes.bool,
  videocard: PropTypes.bool,
  iplcollection: PropTypes.bool
};

export default SubdomainsBigStoryCard;
