import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { getPagePath } from "../../../data/collection";
import CollectionLink from "../../utils/generate-collection-link";
import StoryStackD3 from "../../atoms/story-stack-d3/index";
import ReadMoreLink from "../../atoms/read-more-link/index";
import styles from "./styles.m.css";
import Iframe from "react-iframe";

import { wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";

const WrappedStoryStack = wrapCollectionLayout(StoryStackD3);

const mobileStoryCount = 4;
const desktopOddStoryCount = 4;

const EightStory2C = ({ collection, metadata, accentColor }) => {
  const { items } = collection;
  let stacks = items
    .filter(item => item.type === "collection")
    .slice(0, 3)
    .map((collection, index) => {
      const isReadMoreRequired = index % 1 === 0 && collection.items.length > desktopOddStoryCount;

      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, desktopOddStoryCount);
      });
      return (
        <React.Fragment key={collection.id}>
          <CollectionLink collection={collection}>
            <h2 className={styles["sub-collection-headline"]}>
              <span>{collection.name}</span>
            </h2>
          </CollectionLink>
          <WrappedStoryStack mobileStoryCount={mobileStoryCount} collection={collection} />
          <div className={styles["read-more-wrapper"]}>
            {isReadMoreRequired && (
              <ReadMoreLink href={collection.pagePath || getPagePath(collection)} text={metadata["read_more_text"]} />
            )}
          </div>
        </React.Fragment>
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["base"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className="container">
        <div className={`${styles["embedded-code"]} container`}>
          <div className={styles["iframe-outer"]}>
            <h3 className={styles["head"]}>#GameCorner</h3>
            <Iframe
              frameBorder="0"
              width="100%"
              height="350px"
              style="position: absolute; top: 0; left: 0; width: 100%;"
              src="https://view.genial.ly/5e7d7d1036d3a20e42b6bf42"
              type="text/html"
              allowscriptaccess="always"
              allowFullScreen="true"
              scrolling="yes"
              allownetworking="all"
            />
          </div>
        </div>
        <div />
        <div className={styles["stack-iframe-grid"]}>
          <div className={styles["stack-grid"]}>{stacks}</div>
          <div className={styles["iframe-grid"]}>
            <Iframe
              frameBorder="0"
              width="100%"
              height="100%"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
              src="https://special.vikatan.com/coronavirus-work-from-home/react_quiz.php"
              type="text/html"
              allowscriptaccess="always"
              allowFullScreen="true"
              scrolling="no"
              allownetworking="all"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.shape()
});

EightStory2C.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  })
};

export default EightStory2C;
