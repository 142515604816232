import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";

import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import Contributor from "../contributor/index";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

const BigStoryCardAutoSlider = ({ story, cardWithImageZoom = true, className = "" }) => {
  const storyData = getStoryData(story);

  if (!storyData.headline || !story.url) {
    return null;
  }

  const contributorName = get(story, ["authors", 0, "name"], "");
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  return (
    <Link
      className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      href={externalLink || story.url}
      externalLink={SourceLink || externalLink}
    >
      <ResponsiveImageWithFallback
        className={cardWithImageZoom ? styles["slider-image-wrapper"] : styles["slider-image-wrapper-fix"]}
        slug={storyData.imageS3Key}
        metadata={storyData.imageMetadata}
        alt={storyData.imageCaption}
        isPremium={isPremium(story)}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          { aspectRatio: [2, 1], screenWidthCoverage: 1 },
          { aspectRatio: [2, 1], screenWidthCoverage: 1 }
        )}
        story={story}
      >
        <div className={styles["text-area"]}>
          <div className={styles["text-wrapper"]}>
            {contributorName && (
              <Contributor name={contributorName} type={contributorRole} className={`${styles["contributor"]}`} />
            )}
            <h3 className={styles.headline}>
              <span className={styles.highlight}>{storyData.headline}</span>
            </h3>
            <div className={`${styles["content"]} ${styles["mobile-hide"]}`}>{story.subheadline}</div>
          </div>
        </div>
      </ResponsiveImageWithFallback>
    </Link>
  );
};

BigStoryCardAutoSlider.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  className: PropTypes.string,
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    subheadline: PropTypes.string,
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        "display-name": PropTypes.string,
        color: PropTypes.string
      })
    ),
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  })
};

export default BigStoryCardAutoSlider;
