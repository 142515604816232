import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import SingleStoryCard from "../../rows/single-story-banner-card";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const SingleStoryBanner = ({ collection, accentColor }) => {
  const { name, items } = collection;
  const stories =
    items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, 1)
      .map(({ story }) => <SingleStoryCard className={styles["story-card"]} key={story.id} story={story} />) || [];

  if (!stories.length) {
    return null;
  }

  return (
    <div className={`${styles["six-story-4s"]}`}>
      <div className={`container`}>
        <CollectionLink collection={collection}>
          <CollectionTitleWithCrossLine className={styles.title} title={name} />
        </CollectionLink>
        <div className={`${styles["story-container"]}`}>{stories}</div>
      </div>
    </div>
  );
};

SingleStoryBanner.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(SingleStoryBanner.propTypes))
  })
};
SingleStoryBanner.storyLimit = 4;

export default SingleStoryBanner;
