import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import BigStoryCardCurve from "../../atoms/big-story-card-curve/index";

import styles from "./styles.m.css";

import { Link } from "@quintype/components";
import Icon from "../../atoms/icon";
import StateElectionResultWidget from "../loksabha-state-election-result";
import CandidateResultWidget from "../loksabha-election-candidate-result";
import { get } from "lodash";
import SliderMain5s from "../../atoms/slider-main-5s";
import SliderVideoCard from "../slider-video-card";

const FlourishIframe = ({ key }) => {
  return (
    <div key={key}>
      <iframe
        src="https://flo.uri.sh/visualisation/18082232/embed"
        title="Interactive or visual content"
        className={styles["flourish-embed-iframe"]}
        frameBorder="0"
        scrolling="no"
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
      />
    </div>
  );
};

FlourishIframe.propTypes = {
  key: PropTypes.any.isRequired
};

const ElectionHomeCollection = ({ collection, accentColor, pageType }) => {
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshKey(prevKey => prevKey + 1);
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const { items, metadata } = collection;
  const imgUrl = get(metadata, ["alternative-image-url"], "/");
  let stacks = items.filter(item => item.type === "collection").slice(0, 1);

  const firstStories = stacks && get(stacks, [0, "items"], []);
  const stories = firstStories.map(item => item.story);

  let stacksVideo = items.filter(item => item.type === "collection").slice(1, 2);
  const stacksCollection = stacksVideo && get(stacksVideo, [0], []);

  return (
    <div className={styles["base"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`${styles["container-story"]} container`}>
        <div className={styles["main-wc-banner"]}>
          <div className="hidden-desktop">
            <a
              href="https://www.vikatan.com/election/lok-sabha-election-2024"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://gumlet.assettype.com/vikatan/2024-06/342bcb30-996a-47aa-a665-9c4636ac05f2/election-mobile-banner.png?format=webp&w=310&dpr=1.0"
                alt=""
              />
            </a>
          </div>
          <div className="hidden-mobile">
            <a
              href="https://www.vikatan.com/election/lok-sabha-election-2024"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://gumlet.assettype.com/vikatan/2024-06/09c9929b-566a-41e3-b53f-7cb3307082bf/election-desktop-banner.png?format=webp&w=1500&dpr=1.0"
                alt=""
              />
            </a>
          </div>
        </div>

        <div className={styles["candidate-collection"]}>
          <div className={`${styles["box-div"]} ${styles["election-story-collection"]}`}>
            <h1 className={styles["main-title"]}>
              <Link
                href="https://www.vikatan.com/collection/lok-sabha-election-2024-updates?pfrom=homepage"
                className={styles["main-title-text"]}
              >
                <span>மக்கள் தீர்ப்பு - Exclusives</span>
                <div className={styles["title-link"]}>
                  <span>Read More</span>
                  <Icon type="slider-next" className={styles["arrow-icon"]} />
                </div>
              </Link>
            </h1>
            <SliderMain5s stories={stories.slice(0, 5)} utmURL="home-main-row" />
          </div>
          <div>
            <CandidateResultWidget customLink={imgUrl} />
          </div>
        </div>

        <div className={styles["florish-state"]}>
          <div className={styles["box-div"]}>
            <h1 className={styles["star-title"]}>
              <span>மக்களவைத் தேர்தல் முடிவுகள்</span>
              <Link href={imgUrl} className={styles["title-link"]}>
                <span>Live Updates</span>
                <Icon type="slider-next" className={styles["arrow-icon"]} />
              </Link>
            </h1>
            <div className={styles["election-florish-chart"]}>
              <FlourishIframe key={refreshKey} />
            </div>
          </div>
          <div>
            <StateElectionResultWidget customLink={imgUrl} />
          </div>
        </div>

        <SliderVideoCard collection={stacksCollection} showReadMore={true} />
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(BigStoryCardCurve.propTypes))
});

ElectionHomeCollection.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  pageType: PropTypes.string
};

export default ElectionHomeCollection;
