import React from "react";
import PropTypes from "prop-types";

import StoryCardBig from "../../atoms/story-card-responsive-same";
import SmallStoryCardDesktopMedium16x9 from "../../atoms/small-story-card-desktop-medium-16x9";
import SmallStoryCardDesktopVertical from "../../atoms/small-story-card-desktop-vertical";
import { getPagePath } from "../../../data/collection";
import ReadMoreLink from "../../atoms/read-more-link/index";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import { PGAdwithAd, VikatanMainResponsive } from "../ad-free";

const FiveStoryOneAdOneWidget = ({ collection, metadata, accentColor, showReadmore = true, pagetype = "", index }) => {
  if (!collection) {
    return null;
  }

  const items = ([...collection.items] || []).filter(({ story = {} }) => getStoryHeadline(story) && story.url);
  if (!items.length) {
    return null;
  }

  return (
    <div className={`${styles["base"]}`}>
      <div className={`container`}>
        <div className="row">
          <div className={styles["main-content"]}>
            <StoryCardBig
              story={items[0].story}
              className={`${styles["story-card"]} ${styles["story-card-big"]}`}
              eager={index === 0}
            />
            {!!items[1] && (
              <SmallStoryCardDesktopMedium16x9
                story={items[1].story}
                className={`${styles["story-card"]} ${styles["story-card-medium"]} ${styles["story-card-medium-scm"]}`}
                eager={index === 0}
              />
            )}
            {!!items[2] && (
              <SmallStoryCardDesktopMedium16x9
                story={items[2].story}
                className={`${styles["story-card"]} ${styles["story-card-medium"]} ${styles["story-card-medium-scm2"]}`}
                eager={index === 0}
              />
            )}

            <PGAdwithAd
              adId={`Vikatan2023_Mobile_SP_MID1_336x280`}
              adSize={[[336, 280], [300, 250], [320, 100], [320, 50]]}
              minHeight={280}
              adDivId="div-ad-desk-3849069-1690280872-1"
              class="ad_300_250 hidden-desktop"
            />
            {!!items[3] && (
              <SmallStoryCardDesktopVertical
                story={items[3].story}
                className={`${styles["story-card"]} ${styles["story-card-small"]} ${styles["story-card-small-scs"]}`}
              />
            )}
            {!!items[4] && (
              <SmallStoryCardDesktopVertical
                story={items[4].story}
                className={`${styles["story-card"]} ${styles["story-card-small"]} ${styles["story-card-small-scs2"]}`}
              />
            )}
            <div className={styles["read-more-wrapper"]}>
              {showReadmore && (
                <ReadMoreLink href={getPagePath(collection)} text={metadata && metadata["read_more_text"]} />
              )}
            </div>
          </div>
          <div className={styles["sidebar"]}>
            <VikatanMainResponsive
              placement={pagetype}
              MobileAdtype="Vikatan_mrec_mid_SP"
              DesktopAdtype="Vikatan_Desktop_SP_ATF_Right_300x250"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FiveStoryOneAdOneWidget.propTypes = {
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  accentColor: PropTypes.string,
  showReadmore: PropTypes.bool,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardBig.propTypes))
  }),
  pagetype: PropTypes.string,
  index: PropTypes.number
};
FiveStoryOneAdOneWidget.storyLimit = 5;

export default FiveStoryOneAdOneWidget;
