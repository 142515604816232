import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import SevenStory7s from "../seven-story-7s";
import CustomizableStoryCard from "../../cards/customizable-story-card";
import {
  desktop9by5VerticalH5,
  mobile9by5VerticalMobileh3,
  desktop4by3HorizontalH5,
  mobile4by3HorizontalMobileh4,
  mobileNoimgVerticalMobileh4,
  desktop2by1VerticalH5,
  desktopNoimgVerticalH5,
  desktop1by1HorizontalH5
} from "../../cards/customizable-story-card/story-card.config";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import { PGAdwithAd } from "../ad-free";

const TwelveStoriesOneAd = ({ collection, accentColor, pagetype = "" }) => {
  let stories = get(collection, ["items"], [])
    .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
    .map(item => item.story);
  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`${styles.row} container row`}>
        <div className={styles["desktop-col-3"]}>
          {stories.splice(0, 1).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator-desktop"]}
              story={story}
              desktop={Object.assign({}, desktop9by5VerticalH5, { textWrapperPadding: "var(--space1_5) 0 0" })}
              mobile={Object.assign({}, mobile9by5VerticalMobileh3, { textWrapperPadding: "var(--space1) 0 0" })}
            />
          ))}
          {stories.splice(0, 2).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop4by3HorizontalH5}
              mobile={mobile4by3HorizontalMobileh4}
            />
          ))}
        </div>
        <div className={styles["desktop-col-2"]}>
          {stories.splice(0, 2).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktop2by1VerticalH5}
              mobile={mobileNoimgVerticalMobileh4}
            />
          ))}
        </div>
        <div className={styles["desktop-col-3"]}>
          <PGAdwithAd
            adId={`Vikatan2023_Mobile_SP_MID1_336x280`}
            adSize={[[336, 280], [300, 250], [320, 100], [320, 50]]}
            minHeight={280}
            adDivId="div-ad-desk-3849069-1690280872-1"
            class="ad_300_250 hidden-desktop"
          />

          {stories.splice(0, 2).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktopNoimgVerticalH5}
              mobile={mobile4by3HorizontalMobileh4}
            />
          ))}
          {stories.splice(0, 3).map(story => (
            <CustomizableStoryCard
              key={story.id}
              className={styles["line-separator"]}
              story={story}
              desktop={desktopNoimgVerticalH5}
              mobile={mobileNoimgVerticalMobileh4}
            />
          ))}
        </div>
        <div className={styles["desktop-col-4"]}>
          <PGAdwithAd
            adId={`Vikatan2023_Mobile_SP_MID1_336x280`}
            adSize={[[336, 280], [300, 250]]}
            minHeight={280}
            adDivId="div-ad-desk-3849069-1690280998-1"
            class="ad_300_250 hidden-mobile"
          />

          <div>
            {stories.splice(0, 2).map(story => (
              <CustomizableStoryCard
                key={story.id}
                className={styles["story-1x1"]}
                story={story}
                desktop={Object.assign({}, desktop1by1HorizontalH5, { imageToHeadlineRatio: [4, 13] })}
                mobile={mobileNoimgVerticalMobileh4}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

TwelveStoriesOneAd.propTypes = {
  accentColor: PropTypes.string,
  collection: SevenStory7s.propTypes.collection,
  pagetype: PropTypes.string
};
TwelveStoriesOneAd.storyLimit = 12;

export default TwelveStoriesOneAd;
