import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Link } from "@quintype/components";
import Contributor from "../../atoms/contributor";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";
import Icon from "../../atoms/icon";

export default function MainCardStory({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  topFullView = true,
  authorName = true,
  utmURL = "",
  sponsercard = false
}) {
  const storyData = getStoryData(story);

  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();

  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  if (!storyData.headline || !story["url"]) {
    return null;
  }

  return (
    <div
      className={`${styles["base"]} ${topFullView ? styles["firstcardfullview"] : styles["notcardview"]} ${
        sponsercard ? styles["sponsercardtrue"] : ""
      } ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
    >
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        className={styles["link"]}
        href={`${externalLink || story.url}?pfrom=${utmURL}`}
        externalLink={SourceLink || externalLink}
      >
        <div className={styles["image-container"]}>
          <ResponsiveImageWithFallback
            className={styles["image-wrapper"]}
            slug={storyData.imageS3Key}
            metadata={storyData.imageMetadata}
            alt={storyData.imageCaption}
            isPremium={isPremium(story)}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [5, 3], screenWidthCoverage: 1 },
              { aspectRatio: [5, 3], screenWidthCoverage: 0.42 }
            )}
            story={story}
          >
            {story.engagement &&
              story.engagement.total && (
                <span className={styles["engagement-total"]}>
                  <Icon type={"speaker"} className={styles["speaker-icon"]} />
                  {story.engagement.total}
                </span>
              )}
          </ResponsiveImageWithFallback>
        </div>
        <div className={styles["content"]}>
          <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
            {storyData.headline}
          </h3>
          {story.engagement && (
            <div className={styles["engagement"]}>
              {story.engagement.comments && (
                <span className={styles["engagement-item"]}>
                  <Icon type={"comment"} className={styles["engagement-icon"]} />
                  {story.engagement.comments}
                </span>
              )}
              {story.engagement.shares && (
                <span className={styles["engagement-item"]}>
                  <Icon type={"share"} className={styles["engagement-icon"]} />
                  {story.engagement.shares}
                </span>
              )}
            </div>
          )}
          {authorName &&
            contributor && (
              <Contributor
                name={contributor["name"]}
                type={contributorRole}
                iconColor="#4a4a4a"
                className={`${styles["contributor"]} ${
                  authorName ? styles["authornametrue"] : styles["authornamefalse"]
                }`}
              />
            )}
        </div>
      </Link>
    </div>
  );
}

MainCardStory.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  sponsercard: PropTypes.bool,
  topFullView: PropTypes.string,
  authorName: PropTypes.bool,
  utmURL: PropTypes.string,
  story: PropTypes.shape({
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  })
};
