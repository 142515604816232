import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithCrossLineBundle from "../../atoms/CollectionTitleWithCrossLineBundle";
import CollectionSelectItem from "../../atoms/collection-select-bundle";
import { getStoryHeadline } from "../../utils/utils";

import { get } from "lodash";

import styles from "./styles.m.css";

const ViselectCardBundleList = ({
  collection,
  numberOfCardsInViewDesktop = get(collection, ["associated-metadata", "number_of_stories_to_show"], ""),
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1,
  accentColor
}) => {
  const { items } = collection;

  let stacks =
    items &&
    items.filter(item => item.type === "collection").map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items.filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null);
      });
      return (
        <CollectionSelectItem
          key={collection.id}
          collection={collection}
          className={styles.card}
          numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`container ${styles["carousel-wrapper"]}`}>
          {collection.name && (
            <CollectionLink collection={collection}>
              <CollectionTitleWithCrossLineBundle className={styles.title} title={collection.name} />
            </CollectionLink>
          )}

          <div className={styles["card-list-bundle"]}>{stacks}</div>
        </div>
      </div>
    </div>
  );
};

ViselectCardBundleList.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default ViselectCardBundleList;
