import React, { Component } from "react";
import { Link } from "@quintype/components";
import PropTypes from "prop-types";
import { get } from "lodash";
import StoryCardFullImg1X3 from "../../atoms/story-card-full-img-1-3-without-link";
import StoryCardFullImg from "../../atoms/story-card-full-img-1-3";
import StoryCard from "../../atoms/story-card";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionTitleWithSideLine from "../../atoms/CollectionTitleWithSideLine";
import Icon from "../../atoms/icon";
import BigStoryCardAutoSlider from "../../atoms/big-storycard-autoslider";
import styles from "./styles.m.css";
import Carousel from "../../atoms/carousel";
import { getStoryHeadline } from "../../utils/utils";

class SliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.intervalUpdate = null;
  }

  componentDidMount() {
    this.intervalUpdate = setInterval(this.props.slideChange, this.props.interval || 2000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalUpdate);
  }

  render() {
    const { index, slides } = this.props;
    const slidesWithClasses = slides.map((slide, idx) => {
      const className = idx === index ? "card-active" : "card-not-active";
      return (
        <div className={`${styles[className]} ${styles["flex-element"]}`} key={"slide-" + idx}>
          {slide}
        </div>
      );
    });
    return <div className={`${styles["carousal-holder"]} ${styles["carousal-slider"]}`}>{slidesWithClasses}</div>;
  }
}

SliderComponent.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape(StoryCardFullImg1X3.propTypes)).isRequired,
  index: PropTypes.number.isRequired,
  slideChange: PropTypes.func.isRequired,
  interval: PropTypes.number
};

class FiveStoryAutoSliderWithStory extends Component {
  constructor() {
    super();
    this.state = {
      currentSlideIndex: 0
    };
    this.slideCount = 4;
    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.handleSlideChangeMobile = this.handleSlideChangeMobile.bind(this);
    this.init = this.init.bind(this);
  }

  handleSlideChange() {
    if (this.state.currentSlideIndex != null && this.state.currentSlideIndex + 1 < this.slideCount) {
      this.setState({ currentSlideIndex: this.state.currentSlideIndex + 1 });
    } else {
      this.init();
    }
  }

  handleSlideChangeMobile(index) {
    this.setState({ currentSlideIndex: index });
  }

  init() {
    this.setState({ currentSlideIndex: 0 });
  }

  render() {
    let { collection, interval } = this.props;

    if (!collection) {
      return null;
    }
    if (!interval) {
      interval = 10000;
    }

    const items = (collection.items || [])
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .splice(1, this.slideCount);

    const videoItems = (collection.items || [])
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .splice(0, 1);

    const videoSory = get(videoItems, [0, "story"]);

    const slides = items.map(({ story }, idx) => {
      return (
        <div key={story.id} onClick={() => this.handleSlideChangeMobile(idx)}>
          <StoryCardFullImg1X3 className={styles["story-card-small"]} key={story.id} story={story} index={idx + 1} />
        </div>
      );
    });

    const carousal = slides => (
      <Carousel
        className={styles["carousel"]}
        afterSlide={this.handleSlideChangeMobile}
        options={{
          type: "carousel",
          autoplay: false,
          breakpoints: {
            768: {
              perView: 2
            },
            576: {
              perView: 1.5
            }
          },
          animationTimingFunc: "ease-in-out",
          startAt: 0,
          classes: {
            activeSlide: styles["card-active"]
          }
        }}
        renderCenterLeftControls={({ previousSlide }) => (
          <button
            aria-label="previous slide"
            role="button"
            onClick={previousSlide}
            className={`${styles["slider-nav-button"]}`}
          >
            <Icon type={"slider-prev"} className={styles["slider-nav"]} />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button
            aria-label="next slide"
            role="button"
            onClick={nextSlide}
            className={`${styles["slider-nav-button"]}`}
          >
            <Icon type={"slider-next"} className={styles["slider-nav"]} />
          </button>
        )}
        renderBottomCenterControls={({ nextSlide }) => null}
      >
        {slides}
      </Carousel>
    );
    const bannerLink = `https://www.vikatan.com/election-2021?pfrom=home`;
    return (
      <div
        className={`${styles["base"]}`}
        style={this.props.accentColor && { "--accent-color": this.props.accentColor }}
      >
        <div className={`container`}>
          <a href={bannerLink} target="new">
            <img
              // eslint-disable-next-line max-len
              src="https://gumlet.assettype.com/vikatan/2021-03/e1b20d3a-d38d-4935-88aa-f0e40778f4c9/D_Election_promo_banner.png"
              alt="Election banner"
              width="100%"
              height="auto"
              className="hidden-mobile"
            />
            <img
              // eslint-disable-next-line max-len
              src="https://gumlet.assettype.com/vikatan/2021-03/a8c0ecef-a044-467c-a499-d520a1ab3b81/M_Election_promo_banner.png"
              alt="Election banner"
              width="100%"
              height="auto"
              className="hidden-desktop"
            />
          </a>
          <div className={`${styles["row"]}`}>
            <div>
              <CollectionLink collection={collection}>
                <CollectionTitleWithSideLine title={collection.name} design="outer" />
              </CollectionLink>
              <div className={`${styles["slider-section"]}`}>
                {items.length > 1 && (
                  <BigStoryCardAutoSlider
                    story={items[this.state.currentSlideIndex].story}
                    className={styles["story-card-big"] + (slides.length === 1 ? ` ${styles["show-on-mobile"]}` : "")}
                    type="big"
                  />
                )}
                {slides.length > 1 && (
                  <div>
                    <div className={`${styles["carousal-holder"]} ${styles["carousal-nav"]}`}>{carousal(slides)}</div>
                    <SliderComponent
                      slideChange={this.handleSlideChange}
                      slides={slides}
                      interval={interval}
                      index={this.state.currentSlideIndex}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <CollectionTitleWithSideLine title={"ட்ரெண்டிங் வீடியோஸ்"} design="outer" />
              <div className={styles["video-wrapper"]}>
                <div className={styles["numbered-header"]}>
                  <h2 className={styles["title"]}>
                    <Icon type={"videographer"} className={styles["video-icon"]} />
                    <Link aria-label={`"Read more`} href={`/vikatan-video?artfrm=video-section-home`}>
                      வீடியோஸ்
                    </Link>
                  </h2>
                  <div className={styles["more-div"]}>
                    <Link
                      className={styles["more-button"]}
                      aria-label={`"Read ${styles["more-button"]}`}
                      href={`vikatan-video?artfrm=video-section-home-readmore`}
                    >
                      <span>மேலும் வீடியோஸ் </span>
                      <span className={styles["more-icons"]}>
                        <Icon type={"slider-next"} className={styles["left-icon"]} />
                        <Icon type={"slider-next"} className={styles["right-icon"]} />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className={styles["vidoe-frame"]}>
                  <Icon type={"video-play"} className={styles["video-frame-icon"]} />
                  <StoryCardFullImg className={styles["iframe-outer"]} story={videoSory} title={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FiveStoryAutoSliderWithStory.propTypes = {
  accentColor: PropTypes.string,
  interval: PropTypes.number,
  slideCount: PropTypes.number,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCard.propTypes))
  })
};

export default FiveStoryAutoSliderWithStory;
