import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import Carousel from "../../atoms/carousel";
import CollectionTitleWithCrossLineBundle from "../../atoms/CollectionTitleWithCrossLineBundle";
import Icon from "../../atoms/icon";
import { getStoryHeadline } from "../../utils/utils";
import CollectionSelectCard from "../../atoms/collection-select-card";

import styles from "./styles.m.css";

const SliderFocusedCardRectangle = ({
  collection,
  numberOfCardsInViewDesktop = 2,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1,
  accentColor
}) => {
  const desktopGap = 30;
  const mobileGap = 0;
  const slides =
    collection.items &&
    collection.items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .map(({ story }) => <CollectionSelectCard key={story.id} story={story} className={styles.card} />);

  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container ${styles["carousel-wrapper"]}`}>
        {collection.name && (
          <CollectionLink collection={collection}>
            <CollectionTitleWithCrossLineBundle className={styles.title} title={collection.name} />
          </CollectionLink>
        )}
        <Carousel
          className={styles["carousel"]}
          options={{
            type: "carousel",
            perView: numberOfCardsInViewDesktop,
            gap: desktopGap,
            rewind: false,
            peek: {
              before: 0,
              after: 0
            },
            breakpoints: {
              767: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap,
                peek: {
                  before: mobileGap,
                  after: 0
                }
              },
              991: {
                perView: numberOfCardsInViewTablet,
                gap: desktopGap,
                peek: {
                  before: desktopGap,
                  after: 0
                }
              }
            }
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) =>
            currentSlide > 0 ? (
              <button
                aria-label="previous slide"
                role="button"
                onClick={previousSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="angle-left" className={styles.icon} />
              </button>
            ) : null
          }
          renderCenterRightControls={({ nextSlide }) => (
            <button
              aria-label="next slide"
              role="button"
              onClick={nextSlide}
              className={`${styles["slider-nav-button"]}`}
            >
              <Icon type="angle-right" className={styles.icon} />
            </button>
          )}
          childClass={styles["glide_li"]}
        >
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

SliderFocusedCardRectangle.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default SliderFocusedCardRectangle;
