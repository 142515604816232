import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { fetchConstituency } from "../../../api";

import Select from "react-select";

import styles from "./styles.m.css";
import { checkAppSlug } from "../../utils/utils";
import Icon from "../../atoms/icon";

class AutoSelectComponent extends React.Component {
  logChange = val => {
    const { SelectedValue } = this.props;
    SelectedValue(val.value);
  };

  render() {
    const { options, className } = this.props;
    return (
      <Select
        className={styles[className]}
        options={options}
        onChange={this.logChange}
        placeholder="நாடாளுமன்றத் தொகுதியைத் தேர்ந்தெடுக்க"
      />
    );
  }
}

AutoSelectComponent.propTypes = {
  options: PropTypes.array,
  SelectedValue: PropTypes.func,
  className: PropTypes.string
};

const LoksabhaElectionSearchBar = () => {
  const [constituency, setConstituency] = useState([]);

  const [selectedConstituency, setSelectedConstituency] = useState(null);
  useEffect(() => {
    fetchConstituency()
      .then(res => {
        const conRes = get(res, ["data", "items"]);
        setConstituency(conRes);
      })
      .catch(console.error);
  }, []);

  const allContOp =
    constituency &&
    constituency.map(item => {
      const objItem = {};
      objItem.label = `${item.area} (${item.area_en})`;
      objItem.value = item.thokkuthi_slug;
      return objItem;
    });

  let link = "";
  if (checkAppSlug()) {
    link = "?source=app&type=webview";
  }

  return (
    <div className={`${styles["election-container"]} container`}>
      <div className={styles["form-grid"]}>
        <div className={styles["form-txt"]}>
          <h1>உங்கள் தொகுதியின் கள நிலவரங்கள் மற்றும் விரிவான அலசல்களைப் பெற்றிட</h1>
        </div>
        <div className={styles["form-content"]}>
          {/* <h2>உங்கள் தொகுதியின் கள நிலவரங்கள் மற்றும் விரிவான அலசல்களைப் பெற்றிட</h2> */}
          <div className={styles["form"]}>
            <div className={styles["form-dd"]}>
              {/* <label>நாடாளுமன்றத் தொகுதியைத் தேர்ந்தெடுக்க</label> */}
              <div className={styles["search-field"]}>
                <AutoSelectComponent
                  className="districk"
                  options={allContOp}
                  SelectedValue={e => setSelectedConstituency(e)}
                />
              </div>
            </div>

            <a
              aria-label={`இங்கு க்ளிக் செய்க`}
              className={selectedConstituency ? styles["submit-btn"] : styles["isDisabled"]}
              href={
                selectedConstituency
                  ? `/election/lok-sabha-election-2024/constituency/${selectedConstituency}${link}`
                  : "#"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              இங்கு க்ளிக் செய்க
              <Icon type="double-arrow" className={styles["arrows"]} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoksabhaElectionSearchBar;

LoksabhaElectionSearchBar.propTypes = {
  slug: PropTypes.string
};
