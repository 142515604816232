import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import Icon from "../icon";

import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

export default function VideoStoryCard({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  page,
  playBtn
}) {
  const storyData = getStoryData(story);

  if (!storyData.headline || !story["url"]) {
    return null;
  }

  const externalLink = get(story, ["metadata", "reference-url"]);
  const icons = {
    "photo-album": "gallery",
    photo: "photostory",
    video: "video-play"
  };

  const iconType = get(icons, [story["story-template"]], null);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }
  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${page === "home-page" ? styles["base"] : styles["base-video"]} ${page === "grid-page" &&
        styles["grid-base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={`${externalLink || story.url}?artfrm=video-section-home`}
      externalLink={SourceLink || externalLink}
    >
      <ResponsiveImageWithFallback
        className={page === "home-page" ? styles["image-container"] : styles["image-container-video"]}
        slug={storyData.imageS3Key}
        metadata={storyData.imageMetadata}
        isPremium={isPremium(story)}
        alt={storyData.imageCaption}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          { aspectRatio: [18, 10], screenWidthCoverage: 0.01 },
          { aspectRatio: [18, 10], screenWidthCoverage: 0.01 }
        )}
        story={story}
      >
        {iconType && (
          <span className={styles["story-template-icon"]}>
            <Icon
              type={iconType}
              className={`${styles["template-icon"]} ${playBtn === "big" && styles["big-icon"]}`}
              // style={bigPlay.obj}
            />
          </span>
        )}
        {story.engagement &&
          story.engagement.total && (
            <span className={styles["engagement"]}>
              <Icon type="speaker" className={styles["speaker-icon"]} />
              {story.engagement.total}
            </span>
          )}
      </ResponsiveImageWithFallback>
      <div className={styles["content"]}>
        <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
          {storyData.headline}
        </h3>
      </div>
    </Link>
  );
}

VideoStoryCard.propTypes = {
  className: PropTypes.string,
  page: PropTypes.string,
  playBtn: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  story: PropTypes.shape({
    "author-name": PropTypes.string,
    "contributor-role": PropTypes.shape({
      name: PropTypes.string
    }),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "story-template": PropTypes.string,
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  })
};
