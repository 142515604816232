import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import Contributor from "../contributor/index";

import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

const StoryCardResponsiveSame = ({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  eager
}) => {
  const storyData = getStoryData(story);

  if (!(storyData.headline && story.url)) {
    return null;
  }

  const contributor = get(story, ["authors", 0]);
  const contributorRole = get(story, ["authors", 0, "contributor-role", "name"], "");
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${className} ${styles["base"]} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={externalLink || story.url}
      externalLink={SourceLink || externalLink}
    >
      <ResponsiveImageWithFallback
        className={styles["image-wrapper"]}
        slug={storyData.imageS3Key}
        metadata={storyData.imageMetadata}
        alt={storyData.imageCaption}
        isPremium={isPremium(story)}
        imgParams={{ auto: ["format", "compress"] }}
        eager={eager}
        sources={generateImageSources(
          { aspectRatio: [8, 5], screenWidthCoverage: 1 },
          { aspectRatio: [2, 1], screenWidthCoverage: 0.5 }
        )}
        story={story}
      />
      <div className={styles["text-wrapper"]}>
        {contributor && (
          <Contributor name={contributor["name"]} type={contributorRole} className={styles["contributor"]} />
        )}
        <h3 className={`${styles["headline"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
          {storyData.headline}
        </h3>
      </div>
    </Link>
  );
};

StoryCardResponsiveSame.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string
  }),
  eager: PropTypes.bool
};

export default StoryCardResponsiveSame;
