import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources, checkAppSlug } from "../../utils/utils";
import { getPagePath } from "../../../../isomorphic/data/collection";

const CollectionItem = ({ collection, cardWithImageZoom = true, className = "" }) => {
  const headline = get(collection, ["name"], "");
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["slug"], "");
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = getPagePath(collection);
  }

  if (!(headline && slug)) {
    return null;
  }

  if (!(collection && collection.items)) {
    return null;
  }

  return (
    <Link
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={getPagePath(collection)}
      externalLink={SourceLink}
    >
      <div className={styles["base"]}>
        <ResponsiveImageWithFallback
          className={styles["image-wrapper"]}
          slug={coverImageS3Key}
          metadata={coverImageMetaData}
          alt={imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [6, 5], screenWidthCoverage: 0.75 },
            { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
          )}
        />
        <div className={styles["content-wrap"]}>
          {collection.summary && <p className={styles["summary"]}>{collection.summary}</p>}
        </div>
      </div>
    </Link>
  );
};

CollectionItem.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  className: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

export default CollectionItem;
