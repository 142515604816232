import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import BigStoryCardCurve from "../big-story-card-curve/index";
import SmallStoryCardCurve from "../small-story-card-curve/index";

import styles from "./styles.m.css";
import { Link } from "@quintype/components";

const MagazineCollectionStoryStack = ({
  collection,
  bigStory,
  mobileStoryCount,
  stories,
  verticalimg = false,
  bigimage = false,
  bigStoryHover = false
}) => {
  if (stories.length < 1) {
    return null;
  }
  const collectionCoverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");

  const summary = get(collection, ["summary"], "");

  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);

  const stack = [
    <BigStoryCardCurve
      story={stories[0]}
      key={stories[0].id}
      className={styles["first-big-card"]}
      bigimage={bigimage}
      pFrom={`${pageName}-magazine-collection`}
    />
  ].concat(
    stories.slice(1).map((story, index) => (
      <SmallStoryCardCurve
        story={story}
        verticalimg={verticalimg}
        className={`
          ${styles[index + 1 >= mobileStoryCount ? "mobile-hide" : ""]}
          ${styles["line-separater"]}
          ${styles[index + 2 === mobileStoryCount ? "skip-line" : ""]}
        `}
        key={story.id}
        pFrom={`${pageName}-magazine-collection`}
      />
    ))
  );
  return (
    <div className={styles["base"]}>
      <div className={styles["magazine-collection-header"]}>
        <div>
          {collectionCoverImageS3Key && (
            <img
              src={`https://gumlet.assettype.com/${collectionCoverImageS3Key}`}
              className={styles["magazine-logo"]}
              width=""
              height=""
              alt={collection.name}
            />
          )}
        </div>
        <div>
          <div>
            <Link
              href={`https://www.vikatan.com/${summary}?pfrom=magazine-collection-read-more`}
              className={styles["read-in-app"]}
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
      {stack}
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(BigStoryCardCurve.propTypes))
});

MagazineCollectionStoryStack.propTypes = {
  bigStory: PropTypes.bool,
  mobileStoryCount: PropTypes.number,
  bigimage: PropTypes.bool,
  verticalimg: PropTypes.bool,
  stories: PropTypes.arrayOf(BigStoryCardCurve.propTypes.story),
  className: PropTypes.string,
  bigStoryHover: PropTypes.bool,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  })
};

export default MagazineCollectionStoryStack;
