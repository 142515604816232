import React from "react";
import templates from "./collection-templates";
import { DfpAd } from "./atoms/dfp-ad";
import AdUnit from "./atoms/ad-unit";
import { PGAdwithAd } from "./rows/ad-free";
import Media from "react-media";

function wrapTemplateWithAds(Template, pagetype) {
  return function AdWrappedTemplate(props) {
    let pagetypeUnit = pagetype === "home" ? "HP" : "SP";
    return (
      <React.Fragment>
        {props.index > 2 && props.index % 2 === 0 && <AdUnit placement={pagetype} index={props.index} />}
        <Template {...props} pagetype={pagetype} />
        {props.index === 2 && (
          <div>
            <Media query="(min-width:768px)">
              <PGAdwithAd
                adId={`Vikatan2023_Desktop_${pagetypeUnit}_MID1_970x250`}
                adSize={[[970, 250]]}
                minHeight={250}
                adDivId={`div-ad-desk-3849069-1690280777-3`}
                class="ad-top-bottom-space"
              />
            </Media>
          </div>
        )}
      </React.Fragment>
    );
  };
}

export function addAdsToTemplates(pagetype) {
  let newTemplates = { ...templates };
  for (const templateKey in newTemplates) {
    if (newTemplates.hasOwnProperty(templateKey)) {
      newTemplates[templateKey] = wrapTemplateWithAds(newTemplates[templateKey], pagetype);
    }
  }
  return newTemplates;
}

function wrapTemplateWithoutAds(Template, pagetype) {
  return function AdWrappedTemplate(props) {
    return (
      <React.Fragment>
        <Template {...props} pagetype={pagetype} />
      </React.Fragment>
    );
  };
}

export function addWithoutAdsToTemplates(pagetype) {
  let newTemplates = { ...templates };
  for (const templateKey in newTemplates) {
    if (newTemplates.hasOwnProperty(templateKey)) {
      newTemplates[templateKey] = wrapTemplateWithoutAds(newTemplates[templateKey], pagetype);
    }
  }
  return newTemplates;
}

function wrapNewTemplateWithAds(Template, pagetype) {
  return function AdWrappedTemplate(props) {
    return (
      <React.Fragment>
        {props.index > 2 && props.index % 2 === 0 && <DfpAd adtype="Leaderboard_new" placement={pagetype} />}
        <Template {...props} pagetype={pagetype} />
      </React.Fragment>
    );
  };
}

export function addAdsToNewTemplates(pagetype) {
  let newTemplates = { ...templates };
  for (const templateKey in newTemplates) {
    if (newTemplates.hasOwnProperty(templateKey)) {
      newTemplates[templateKey] = wrapNewTemplateWithAds(newTemplates[templateKey], pagetype);
    }
  }
  return newTemplates;
}

export function getCollectionTemplate(designTemplate) {
  return templates[designTemplate] || templates.defaultTemplate;
}

const count = 12;

export function getStoryLimits() {
  return {
    "main-row-with-bundle-12s-5c-1ad": 26,
    "main-row-with-special-bundle-12s-1c-1ad": 20,
    "slider-focused-card-ns": 4,
    "four-story-half-featured-4s": 4,
    "amoeba-slider-ns": 8,
    "seven-media-stories-7s": 7,
    "video-collection-template": 10,
    "seven-media-stories-7s-with-neet": 7,
    "vikatan-tv": 8,
    "sixteen-story-4c": 8,
    "gradient-cards-four-story-4s": count,
    "magazine-subscription-slider-nc": count,
    "three-story-slider-round": 3,
    "app-exclusive-story-slider-round": 16,
    "five-story-one-ad-one-widget": count,
    "four-story-photo-gallery": 4,
    "inverted-four-story-half-featured-4s": 4,
    "nineteen-stories-one-ad-19s": 20,
    "one-carousel-two-stories-one-ad-one-widget-7s": 8,
    "4S-2C4S-1Ad-1Widget": 8,
    "twelve-stories-one-ad-one-widget-12s": 12,
    "five-story-one-ad": 5,
    "two-collection-four-story": 8,
    "three-story-autoslider-3s": 3,
    "twelve-stories-one-ad-12s": 12,
    "kids-bed-time-stories": 8,
    "Soma-valliappan": 6,
    "Elephant-stories": 6,
    "saibaba-stories": 6,
    "fivestory-autoslider-with-video": 5,
    "fivestory-autoslider-with-story": 5,
    "premium-series-collection": 5,
    "premium-slider-collection-card": 5,
    "six-stories-6s": 8,
    "six-story-card": 8,
    defaultTemplate: 4
  };
}
