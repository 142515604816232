import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import CollectionLink from "../../utils/generate-collection-link";
import CollectionSpecialItem from "../../atoms/collection-item";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import Carousel from "../../atoms/carousel";
import CollectionTitleWithCrossLineBundle from "../../atoms/CollectionTitleWithCrossLineBundle";
const CollectionsSlider = ({ collection, metadata, accentColor = "#FDDFF2" }) => {
  const { items } = collection;
  let stacks = items.filter(item => item.type === "collection").map((collection, index) => {
    collection = produce(collection, draft => {
      draft.items = draft.items.filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null);
    });
    return (
      <React.Fragment key={collection.id}>
        <CollectionSpecialItem collection={collection} />
      </React.Fragment>
    );
  });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={`${styles["main-row"]}`}>
      <div className={`${styles["outer-sec"]}`}>
        <div className={`${styles["base"]}`} style={accentColor && { "--accent-color": accentColor }}>
          <div className={`container ${styles["carousel-wrapper"]}`}>
            {collection.name && (
              <CollectionLink collection={collection}>
                <CollectionTitleWithCrossLineBundle className={styles.title} title={collection.name} />
              </CollectionLink>
            )}
            <Carousel
              className={styles["carousel"]}
              options={{
                type: "slider",
                rewind: true,
                bound: false,
                perView: 4,
                gap: 20,
                peek: {
                  before: 0,
                  after: 244
                },
                breakpoints: {
                  767: {
                    perView: 3,
                    type: "slider",
                    rewind: true,
                    bound: true,
                    autoplay: 1000,
                    gap: 20,
                    peek: {
                      before: 0,
                      after: 0
                    }
                  },
                  991: {
                    perView: 2,
                    rewind: false,
                    gap: 10,
                    peek: {
                      before: 30,
                      after: 244
                    }
                  }
                }
              }}
              renderCenterLeftControls={({ previousSlide }) => {
                return null;
              }}
              renderCenterRightControls={({ nextSlide }) => {
                return null;
              }}
              renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                return null;
              }}
            >
              {stacks}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.shape()
});

CollectionsSlider.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  })
};

export default CollectionsSlider;
