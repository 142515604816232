import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { fetchIplPointTable } from "../../../api";
import Iframe from "react-iframe";
import Cookies from "universal-cookie";

import styles from "./styles.m.css";
const cookies = new Cookies();

const IplPointTable = ({ accentColor }) => {
  const [matchList, setData] = useState([]);
  const [survierId, setsurvierId] = useState(null);

  useEffect(() => {
    fetchIplPointTable().then(list => {
      const id = cookies.get("ev-guid") || cookies.get("thinmint") || cookies.get("vikatanthinmint");
      setsurvierId(id);
      setData(list);
    });
  }, []);
  const pointList = get(matchList, ["teams"], []);

  return (
    <div className={styles["base"]}>
      <div className="container">
        <div className={`${styles["table-grid"]}`}>
          <div className={`${styles["point-table"]}`}>
            <h1 className={`${styles["point-table-title"]}`}>Points Table</h1>
            <table className={styles["inner-table"]}>
              <tr>
                <th>Team</th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>PLD</span>
                  <span className={`${styles["point-mobile"]}`}>P</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Won</span>
                  <span className={`${styles["point-mobile"]}`}>W</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Lost</span>
                  <span className={`${styles["point-mobile"]}`}>L</span>
                </th>
                <th>
                  <span className={`${styles["point-dsk"]}`}>Tied</span>
                  <span className={`${styles["point-mobile"]}`}>T</span>
                </th>
                <th>N/R</th>
                <th>Net RR</th>
                <th>Pts</th>
              </tr>
              {pointList.length > 0 &&
                pointList.map((list, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <table className={styles["inner-table"]}>
                          <tr>
                            <td>
                              <img alt={list.short_name} src={list.team_logo} className={styles["img-view"]} />
                            </td>
                            <td>{list.short_name}</td>
                          </tr>
                        </table>
                      </td>
                      <td>{list.played}</td>
                      <td>{list.won}</td>
                      <td>{list.lost}</td>
                      <td>{list.tied}</td>
                      <td>{list.no_result}</td>
                      <td>{list.net_run_rate}</td>
                      <td>{list.points}</td>
                    </tr>
                  );
                })}
            </table>
            <div className={styles["table-word-details"]}>
              <span>
                <b>P</b> - PLD
              </span>
              <span>
                <b>W</b> - Won
              </span>
              <span>
                <b>L</b> - Lost
              </span>
              <span>
                <b>T</b> - Tied
              </span>
            </div>
          </div>
          <div>
            <Iframe
              frameBorder="0"
              className={styles["iframe-outer"]}
              src={`https://special.vikatan.com/ipl/poll/index.php?survierId=${survierId}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
IplPointTable.propTypes = {
  accentColor: PropTypes.string
};

export default IplPointTable;
