import React from "react";
import PropTypes from "prop-types";
import SliderMain from "../../rows/story-image-slider";
import CollectionBundleItem from "../../atoms/collection-bundle-item";
import ReadMore from "../../rows/readmore-link";
import { get } from "lodash";

import styles from "./styles.m.css";

const SliderCollectionStoriesBundle = ({ stories, summary }) => {
  return (
    <div className={styles["slider-collection-stories-bundle"]}>
      <div className={styles["slider-stories-wrap"]}>
        <SliderMain stories={stories.slice(0, 7)} />
      </div>
      <div className={styles["second-slider-stories-wrap"]}>
        <div className={styles["readmore-button"]}>
          <p className={styles["content"]}>{summary}</p>
          <ReadMore href="https://special.vikatan.com/NRI-Kids-Creation" text="Upload Video/Photo" />
        </div>
      </div>
    </div>
  );
};

SliderCollectionStoriesBundle.propTypes = {
  stories: SliderMain.propTypes.stories,
  summary: PropTypes.string
};

const CollectionStorySlider = ({ collection, accentColor, pagetype = "", magazine, config }) => {
  const headline = get(collection, ["name"], "");
  const summary = get(collection, ["summary"], "");
  if (!collection.items) {
    return null;
  }
  if (!headline) {
    return null;
  }
  const stories = collection.items.filter(item => item && item.type === "story").map(item => item.story);
  return (
    <div className={styles["main-row"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container ${styles["collection-slider"]}`}>
        <h3 className={styles["headline"]}>{headline}</h3>
        {stories &&
          stories.length > 0 && <SliderCollectionStoriesBundle stories={stories.slice(0, 10)} summary={summary} />}
      </div>
    </div>
  );
};

CollectionStorySlider.propTypes = {
  accentColor: PropTypes.string,
  collection: CollectionBundleItem.propTypes.collection,
  pagetype: PropTypes.string,
  magazine: PropTypes.array,
  config: PropTypes.shape()
};

export default CollectionStorySlider;
