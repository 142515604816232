import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import produce from "immer";

import FourCollectionSixStoryStoryStack from "../../atoms/four-collection-6s-story-stack";

import VideoStories from "../../atoms/video-stories";

import styles from "./styles.m.css";

import { Link, wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";
import CollectionTitle from "../../atoms/CollectionTitle";
import { PGAdwithAd } from "../ad-free";
import Media from "react-media";

const WrappedStoryStack = wrapCollectionLayout(FourCollectionSixStoryStoryStack);
const WrappedVideoStories = wrapCollectionLayout(VideoStories);

const mobileStoryCount = 6;

const desktopEvenStoryCount = 6;

const SixStoryFourcollectionOnevideoOneImage = ({ collection, metadata, accentColor, pageType }) => {
  const { name, items } = collection;

  const [selectedCollectionSlug, setSelectedCollectionSlug] = useState(0);
  const [selectedCollectionStories, setSelectedCollectionStories] = useState(items[0]);

  const leftPanelCollections = items.slice(0, 4);
  const videoCollection = items.slice(4)[0] || null;

  const handleCollectionClick = (slug, index) => {
    setSelectedCollectionSlug(index);
    setSelectedCollectionStories(items[index]);
  };

  const [userType = "F", setType] = useState(false);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType);
    });
  }, []);

  let videocollection = items
    .filter(item => item.type === "collection")
    .slice(0, 1)
    .map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, index % 2 === 0 ? desktopEvenStoryCount : desktopEvenStoryCount);
      });
      return (
        <React.Fragment key={collection.id}>
          <WrappedVideoStories
            collection={videoCollection}
            storiesStart={1}
            showCarousel={false}
            className={`${styles["video-section"]}`}
          />
        </React.Fragment>
      );
    });

  if (!videocollection || !videocollection.length) {
    return null;
  }

  return (
    <div className={styles["twelve-story-base"]}>
      <div className={`${styles["six-story-collection"]} container`}>
        <div className={styles["left-panel"]}>
          <div className={styles["title-grid"]}>
            <CollectionTitle className={styles.title} title={name}>
              {collection.name}
            </CollectionTitle>
            <div className={styles["collection-tags"]}>
              {leftPanelCollections.map((item, index) => (
                <div
                  className={`${selectedCollectionSlug === index ? styles["active"] : styles["tag"]}`}
                  onClick={() => handleCollectionClick(item.slug, index)}
                  key={item.id}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          {leftPanelCollections &&
            selectedCollectionStories && (
              <WrappedStoryStack mobileStoryCount={mobileStoryCount} collection={selectedCollectionStories} />
            )}
        </div>
        <div className={styles["right-panel"]}>
          <div className="hidden-desktop">
            <Media query="(max-width: 767px)">
              {matches =>
                matches && (
                  <div>
                    <div>
                      {userType === "P" || userType === "T" || userType === "R" ? (
                        " "
                      ) : (
                        <div className={`${styles["ad-space"]} widget300xauto`}>
                          <PGAdwithAd
                            adId={`Vikatan2023_Mobile_HP_MID3_336x280`}
                            adSize={[[336, 280], [300, 250]]}
                            minHeight={280}
                            adDivId="div-ad-mob-3849069-165-3"
                            class=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            </Media>
          </div>

          <div className={styles["video-collection"]}>{videocollection}</div>

          <div className={styles["series-wrapper"]}>
            <div className={styles["series-free-user"]}>
              <Link className={`${styles["series-wrapper-link"]}`} href="/series?pfrom=homepage-mainrow-series">
                <img
                  src="https://gumlet.assettype.com/vikatan/2023-01/f3994072-d50a-402d-840f-5f88fec046ba/Vikatan_thodargal.png?format=webp&w=300&dpr=1.0"
                  alt="series"
                  width="350px"
                  height="425px"
                  className={styles["series-image-free-user"]}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape)
});

SixStoryFourcollectionOnevideoOneImage.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  pageType: PropTypes.string
};

export default SixStoryFourcollectionOnevideoOneImage;
