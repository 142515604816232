import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import StoryCardFullWidth from "../../atoms/story-card-full-width-with-neet";
import StoryCard from "../../atoms/story-card";
import styles from "./styles.m.css";

export const SevenStory7sWithNeet = ({ collection, Icon, BgPattern = "bg-diamond-dark" }) => {
  const { name, items } = collection;
  const MaxNumberOfThumbnailStories = 6;
  return (
    <div className={`${styles["seven-story-7s"]} ${BgPattern}`}>
      <div className={`container`}>
        <CollectionLink collection={collection}>
          <h2 className={`${styles.title}`}> {Icon ? <Icon /> : name}</h2>
        </CollectionLink>
        <div className="row">
          <StoryCardFullWidth className={styles["story-card-full-width"]} key={items[0].id} story={items[0].story} />

          <div className={styles["gradient-container"]}>
            <div className={styles["thumb-story-container"]}>
              {items &&
                items.length > 0 &&
                items
                  .slice(0, MaxNumberOfThumbnailStories)
                  .map(item => <StoryCard className={styles["story-card"]} key={item.id} story={item.story} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SevenStory7sWithNeet.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFullWidth.propTypes))
  }),
  Icon: PropTypes.func,
  BgPattern: PropTypes.string
};
SevenStory7sWithNeet.storyLimit = 8;

export default SevenStory7sWithNeet;
