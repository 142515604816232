import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources, checkAppSlug } from "../../utils/utils";
// import CollectionLink from "../../utils/generate-collection-link";
// import Icon from "../icon";

const AudioCollectionCard = ({
  collection,
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  className = "",
  numberOfCardsInViewDesktop,
  showTitleAndMetaDesc = false,
  showOnlyImages = false
}) => {
  const headline = get(collection, ["name"], "");
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["slug"], "");
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");

  // const MetaDataDesc = get(collection, ["metadata", "meta-description"], "");
  if (!(headline && slug)) {
    return null;
  }

  const audiomagazinePagePath = slug => {
    return `/vikatan-play/${slug}`;
  };

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = audiomagazinePagePath(slug);
  }
  return (
    <div>
      <Link className={`${styles["read-more-link"]}`} href={audiomagazinePagePath(slug)} externalLink={SourceLink}>
        {/* {showTitleAndMetaDesc && (
          <div className={`${styles["image-caption"]}`}>
            <div className={styles["image-section"]}>
              <ResponsiveImageWithFallback
                className={styles["image-wrapper"]}
                slug={coverImageS3Key}
                metadata={coverImageMetaData}
                alt={imageCaption}
                imgParams={{ auto: ["format", "compress"] }}
                sources={generateImageSources(
                  { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                  { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                )}
              />
            </div>
            <div className={styles["text-wrapper"]}>
              <div className={styles["bundle-text"]}>
                <h3 className={styles["headline"]}>
                  <span className={styles.highlight}>{collection.name}</span>
                </h3>
                <p className={styles["description"]}>{MetaDataDesc}</p>
                {collection.name && (
                  <CollectionLink collection={collection}>
                    <Icon type={"slider-next"} className={styles["collection-cta"]} />
                  </CollectionLink>
                )}
              </div>
            </div>
          </div>
        )} */}
        {/* {showOnlyImages && ( */}
        <div className={`${styles["base"]} ${styles["only-img-sec"]}`}>
          <div className={styles["image-section"]}>
            <ResponsiveImageWithFallback
              className={styles["image-wrapper"]}
              slug={coverImageS3Key}
              metadata={coverImageMetaData}
              alt={imageCaption}
              imgParams={{ auto: ["format", "compress"] }}
              sources={generateImageSources(
                { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
              )}
            />
          </div>
        </div>
        {/* )} */}
      </Link>
    </div>
  );
};

AudioCollectionCard.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  hideTitle: PropTypes.bool,
  categoriescard: PropTypes.bool,
  showTitleAndMetaDesc: PropTypes.bool,
  showOnlyImages: PropTypes.bool,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

export default AudioCollectionCard;
