import React from "react";
import PropTypes from "prop-types";

import Carousel from "../../atoms/carousel";

import MagazineBuyCard from "../../atoms/magazine-buy-card/index";
import Icon from "../../atoms/icon";
import styles from "./styles.m.css";

const MagazineSlider = ({
  items,
  className = "",
  numberOfCardsInViewDesktop = 4,
  numberOfCardsInViewMobile = 2,
  buyLink = "",
  accentColor
}) => {
  const magazines = (items && items.filter(item => item.type === "collection")) || [];
  if (magazines.length === 0) {
    return null;
  }
  let slides = magazines.map(magazine => <MagazineBuyCard key={magazine.id} collection={magazine} buyLink={buyLink} />);

  return (
    <div className={styles["magazine-slider-wrap"]} style={accentColor && { "--accent-color": accentColor }}>
      <Carousel
        className={`${styles["magazine-slider"]} ${className}`}
        options={{
          type: "slider",
          perView: numberOfCardsInViewDesktop,
          gap: 30,
          bound: true,
          breakpoints: {
            767: {
              gap: 15,
              type: "slider",
              rewind: false,
              perView: numberOfCardsInViewMobile,
              peek: {
                before: 0,
                after: 60
              }
            }
          }
        }}
        renderCenterLeftControls={({ previousSlide }) =>
          magazines.length > numberOfCardsInViewDesktop && (
            <button onClick={previousSlide} className={`${styles["slider-nav-button"]} ${styles["prev-button"]}`}>
              <Icon type={"line-arrow-prev"} />
            </button>
          )
        }
        renderCenterRightControls={({ nextSlide }) =>
          magazines.length > numberOfCardsInViewDesktop && (
            <button onClick={nextSlide} className={`${styles["slider-nav-button"]} ${styles["next-button"]}`}>
              <Icon type={"line-arrow-next"} />
            </button>
          )
        }
      >
        {slides}
      </Carousel>
    </div>
  );
};

MagazineSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(MagazineBuyCard.propTypes)),
  className: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  buyLink: PropTypes.string,
  accentColor: PropTypes.string
};

export default MagazineSlider;
