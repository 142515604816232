import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Carousel from "../../atoms/carousel";
import Icon from "../../atoms/icon";
import styles from "./styles.m.css";
import { fetchCandidateResult } from "../../../api";
import { Link } from "@quintype/components";

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const CandidateCard = ({ candidates }) => (
  <div className={styles["grid-item-container"]}>
    {candidates.map((candidate, index) => (
      <div key={index} className={styles["grid-item"]}>
        <Link href="https://www.vikatan.com/election/lok-sabha-election-2024/tamilnadu-candidates">
          {get(candidate, ["area_name"]) && (
            <div className={styles["candidate-state"]}>{get(candidate, ["area_name"])}</div>
          )}
          <div className={styles["candidate-image-status"]}>
            {get(candidate, ["candidate_image"]) && (
              <img
                src={get(candidate, ["candidate_image"])}
                alt={get(candidate, ["candidate_name"])}
                className={styles["img-candidate"]}
              />
            )}
            {get(candidate, ["result_status"]) && (
              <div
                className={`${styles["candidate-status"]} ${
                  get(candidate, ["result_status"]) === "1" ? styles["won"] : styles["lost"]
                }`}
              >
                <div className={styles["status-txt-animation"]}>{get(candidate, ["result_status_name"])}</div>
              </div>
            )}
          </div>
          {get(candidate, ["candidate_name"]) && (
            <div className={styles["candidate-name"]}>{get(candidate, ["candidate_name"])}</div>
          )}
          {get(candidate, ["party_name"]) && (
            <div className={styles["candidate-party"]}>({get(candidate, ["party_name"])})</div>
          )}
        </Link>
      </div>
    ))}
  </div>
);

CandidateCard.propTypes = {
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      area_name: PropTypes.string.isRequired,
      candidate_name: PropTypes.string.isRequired,
      party_name: PropTypes.string.isRequired,
      result_status: PropTypes.string.isRequired,
      result_status_name: PropTypes.string.isRequired,
      candidate_image: PropTypes.string.isRequired
    })
  ).isRequired
};

const CandidateResultWidget = ({
  customLink,
  numberOfCardsInViewDesktop = 1,
  numberOfCardsInViewTablet = 1,
  numberOfCardsInViewMobile = 1
}) => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      fetchCandidateResult()
        .then(res => {
          const candidateArray = Object.values(res).map(candidate => ({
            area_name: get(candidate, ["area_name"]),
            candidate_name: get(candidate, ["candidate_name"]),
            party_name: get(candidate, ["party_name"]),
            result_status: get(candidate, ["result_status"]),
            result_status_name: get(candidate, ["result_status_name"]),
            candidate_image: get(candidate, ["candidate_image"])
          }));
          setCandidates(candidateArray);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch candidates");
          setLoading(false);
        });
    };

    fetchData(); // Fetch data initially

    const interval = setInterval(fetchData, 300000); // Fetch data every 5 seconds

    return () => clearInterval(interval); // Cleanup
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const candidateChunks = chunkArray(candidates, 9);

  return (
    <div className={styles["Rectangle-container"]}>
      <div className={styles["grid-container"]}>
        <h1 className={styles["star-title"]}>
          <span>வேட்பாளர்கள் முன்னிலை நிலவரம்</span>
          <Link
            href="https://www.vikatan.com/election/lok-sabha-election-2024/tamilnadu-candidates"
            className={styles["title-link"]}
          >
            <Icon type="slider-next" className={styles["arrow-icon"]} />
          </Link>
        </h1>
        {candidateChunks.length > 0 && (
          <Carousel
            className={styles["carousel"]}
            options={{
              type: "carousel",
              autoPlay: 3000,
              perView: numberOfCardsInViewDesktop,
              gap: 30,
              rewind: false,
              breakpoints: {
                767: { perView: numberOfCardsInViewMobile, gap: 10 },
                991: { perView: numberOfCardsInViewTablet, gap: 30 }
              }
            }}
            renderCenterLeftControls={({ previousSlide, currentSlide }) =>
              currentSlide > 0 ? (
                <button
                  aria-label="previous slide"
                  role="button"
                  onClick={previousSlide}
                  className={styles["slider-nav-button"]}
                >
                  <Icon type="angle-left" className={styles.icon} />
                </button>
              ) : null
            }
            renderCenterRightControls={({ nextSlide, currentSlide }) =>
              currentSlide < candidateChunks.length - 1 ? (
                <button
                  aria-label="next slide"
                  role="button"
                  onClick={nextSlide}
                  className={styles["slider-nav-button"]}
                >
                  <Icon type="angle-right" className={styles.icon} />
                </button>
              ) : null
            }
            renderBottomCenterControls={({ currentSlide, goToSlide }) => (
              <div className={styles.dots}>
                {candidateChunks.map((_, index) => (
                  <button
                    key={index}
                    className={currentSlide === index ? styles["is-active"] : ""}
                    onClick={() => goToSlide(index)}
                  />
                ))}
              </div>
            )}
            childClass={styles["glide_li"]}
          >
            {candidateChunks.map((chunk, index) => (
              <CandidateCard key={index} candidates={chunk} />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

CandidateResultWidget.propTypes = {
  customLink: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default CandidateResultWidget;
