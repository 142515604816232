import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import LiveBlogMainCard from "../../atoms/home-live-blog-main-card";

import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import { getStoryData, getStoryHeadline, checkAppSlug } from "../../utils/utils";
import { prototype } from "events";
import Icon from "../icon";
import { fetchStories } from "../../../api";
import { get } from "lodash";
import { humanizeCustomDate } from "../../utils/time-format";

const HomeLiveBlog = ({ stories, className = "", authorName = true, utmURL = "", hasTruncatedHeadline = true }) => {
  stories = stories.filter(story => story.url && getStoryHeadline(story));
  if (stories.length === 0) {
    return null;
  }
  const story = get(stories, [0]);
  const storyData = getStoryData(story);
  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();

  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  if (!storyData.headline || !story["url"]) {
    return null;
  }

  let slides = stories.map(story => (
    <LiveBlogMainCard className={styles.card} key={story.id} story={story} authorName={authorName} utmURL={utmURL} />
  ));

  const [storyCard, setStoryCard] = useState([]);
  useEffect(() => {
    const getStoryId = get(stories, ["0", "id"], "");
    fetchStories(getStoryId)
      .then(res => {
        const cards = get(res, ["story", "cards"], []);
        setStoryCard(cards);
      })
      .catch(console.error);
  }, []);

  if (slides.length === 1) {
    return (
      <div className={styles["live-blog-main-grid"]}>
        <div className={styles["main-slider-collection"]}>{slides}</div>
        <div>
          <div className={styles["progress-wrapper-section"]}>
            {storyCard.length === 0 ? (
              <div className="skeloton-empty" />
            ) : (
              storyCard.slice(0, 5).map((card, index) => {
                return (
                  <div className={styles["progress-wrapper"]} key={card.id}>
                    <div
                      className={styles["progress-wrapper-timer"]}
                      dangerouslySetInnerHTML={{ __html: humanizeCustomDate(card["card-added-at"]) }}
                    />
                    <Link
                      aria-label={`${"Read full story: "} ${storyData.headline}`}
                      className={styles["link"]}
                      href={`${externalLink || story.url}?pfrom=${utmURL}`}
                      externalLink={SourceLink || externalLink}
                    >
                      <h3 className={`${styles["live-desc"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
                        {card["story-elements"].filter(x => x.type === "title").map(element => {
                          return element.text;
                        })}
                      </h3>
                    </Link>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className={styles["cta"]}>
          <Link
            aria-label={`${"Read full story: "}`}
            className={styles["cta-btn"]}
            href={`${externalLink || story.url}?pfrom=${utmURL}`}
            externalLink={SourceLink || externalLink}
          >
            <span>மேலும் படிக்க</span>
            <Icon type="slider-next" />
          </Link>
        </div>
      </div>
    );
  }

  return <div>{slides}</div>;
};

HomeLiveBlog.propTypes = {
  stories: PropTypes.arrayOf(LiveBlogMainCard.propTypes.story),
  className: PropTypes.string,
  utmURL: PropTypes.string,
  authorName: prototype.bool,
  hasTruncatedHeadline: PropTypes.bool
};

export default HomeLiveBlog;
