import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BigStoryCardCurve from "../big-story-card-curve/index";
import styles from "./styles.m.css";
import StoryCardHorizondalCard from "../story-card-horizondal-card";

const FourCollectionSixStoryStoryStack = ({ stories, className, horizondalView }) => {
  if (stories.length < 1) {
    return null;
  }
  const [collections] = useState(stories);
  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);

  const stack =
    stories &&
    stories
      .slice(0, 6)
      .map((story, index) => (
        <StoryCardHorizondalCard
          story={story}
          horizondalView={false}
          cardGrid={false}
          authorName={false}
          key={story.id}
          utmURL={`${pageName}-fourth-row`}
        />
      ));

  return <div>{collections && <div className={`${styles["base"]} ${className}`}>{stack}</div>}</div>;
};

FourCollectionSixStoryStoryStack.propTypes = {
  stories: PropTypes.arrayOf(BigStoryCardCurve.propTypes.story),
  className: PropTypes.string,
  authorName: PropTypes.bool,
  horizondalView: PropTypes.bool
};

export default FourCollectionSixStoryStoryStack;
