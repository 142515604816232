import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

const CollectionTitleWithCTA = ({ title, className = "", theme }) => {
  if (!title) {
    return null;
  }
  const themeClass = theme ? styles[theme] : "";
  return (
    <h2 className={`${styles.title} ${className}`}>
      <span className={styles.text}>{title}</span>
      <div className={`${styles["collection-cta"]} ${themeClass}`}>
        <Icon type={"slider-next"} />
      </div>
    </h2>
  );
};

CollectionTitleWithCTA.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string
};

export default CollectionTitleWithCTA;
