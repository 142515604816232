import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";

import styles from "./styles.m.css";
import { getSubscriptionURL } from "../../utils/utils";

const MagazineYearlySubscriptionBase = ({ subscription, className = "", subscriptionHost = "" }) => {
  if (!subscription) {
    return null;
  }

  if (!subscription.amount) {
    return null;
  }
  return (
    <div className={`${styles["magazine-subscription"]} ${className}`}>
      <h3 className={styles["headline"]}>{subscription.headline}</h3>
      <div className={styles["saving-container"]}>
        {subscription.saving && (
          <div className={styles["saving-wrap"]}>
            <span className={styles["saving-text"]}>{subscription["saving_label"]}</span>
            <span className={`${styles["saving-amount"]} ${styles["saving-amount--highlight"]}`}>
              {`₹${subscription.saving}`}
            </span>
          </div>
        )}
        {subscription.saving && subscription.amount && <div className={styles["separator"]} />}

        {subscription.amount && (
          <div className={styles["saving-wrap"]}>
            <span className={styles["saving-text"]}>{subscription["amount_label"]}</span>
            <span className={styles["saving-amount"]}>{`₹${subscription.amount}`}</span>
          </div>
        )}
      </div>
      <Link
        aria-label="Subscribe"
        href={getSubscriptionURL(subscriptionHost)}
        target="_blank"
        className={styles["saving-btn"]}
      >
        {subscription["subscription_text"]}
      </Link>
    </div>
  );
};

MagazineYearlySubscriptionBase.propTypes = {
  className: PropTypes.string,
  subscriptionHost: PropTypes.string,
  subscription: PropTypes.shape({
    saving: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired
  })
};

export const MagazineYearlySubscription = connect(state => {
  return { subscriptionHost: get(state, ["qt", "config", "vikatanHosts", "subscription"]) };
})(MagazineYearlySubscriptionBase);

export default MagazineYearlySubscription;
