import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { getPagePath } from "../../../data/collection";
import CollectionLink from "../../utils/generate-collection-link";
import ReadMoreLink from "../../atoms/read-more-link/index";
import styles from "./styles.m.css";

import SliderMain from "../../atoms/big-story-slider";
import StoryStack from "../../atoms/story-stack-big/index";

import { wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";

const WrappedStorySlider = wrapCollectionLayout(SliderMain);
const WrappedStoryStack = wrapCollectionLayout(StoryStack);

const mobileStoryCount = 4;
const desktopOddStoryCount = 4;

const TwoStory2C = ({ collection, metadata, accentColor }) => {
  const { items } = collection;
  let stacks = items
    .filter(item => item.type === "collection")
    .slice(1, 2)
    .map((collection, index) => {
      const isReadMoreRequired = index % 1 === 0 && collection.items.length > desktopOddStoryCount;

      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, desktopOddStoryCount);
      });
      return (
        <React.Fragment key={collection.id}>
          <CollectionLink collection={collection}>
            <h2 className={styles["sub-collection-headline"]}>
              <span>{collection.name}</span>
            </h2>
          </CollectionLink>
          <WrappedStoryStack mobileStoryCount={mobileStoryCount} collection={collection} />
          <div className={styles["read-more-wrapper"]}>
            {isReadMoreRequired && (
              <ReadMoreLink href={collection.pagePath || getPagePath(collection)} text={metadata["read_more_text"]} />
            )}
          </div>
        </React.Fragment>
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  let slide = items
    .filter(item => item.type === "collection")
    .slice(0, 1)
    .map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, 5);
      });
      return (
        <React.Fragment key={collection.id}>
          <CollectionLink collection={collection}>
            <h2 className={styles["sub-collection-headline"]}>
              <span>{collection.name}</span>
            </h2>
          </CollectionLink>
          <WrappedStorySlider mobileStoryCount={mobileStoryCount} collection={collection} />
        </React.Fragment>
      );
    });

  if (!slide || !slide.length) {
    return null;
  }

  return (
    <div className={styles["base"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className="container">
        <div className={styles["stack-iframe-grid"]}>
          <div className={styles["iframe-grid"]}>{slide}</div>
          <div className={styles["stack-grid"]}>{stacks}</div>
        </div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.shape()
});

TwoStory2C.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  })
};

export default TwoStory2C;
