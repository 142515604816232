import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import StoryStack from "../../atoms/story-stack";
import BigStoryCardDifferentAspect from "../../atoms/big-story-card-different-aspect";
import SmallStoryCardDesktopBig from "../../atoms/small-story-desktop-big";
import SmallStoryCardDesktopSmallVertical from "../../atoms/small-story-desktop-small-verticle";
import { DfpAd } from "../../atoms/dfp-ad";

import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";

const TwelveStoriesOneAdOneWidget = ({ collection, accentColor, pagetype = "" }) => {
  const items = get(collection, ["items"], []);

  const stories = items
    .filter(item => item.type === "story")
    .slice(0, 4)
    .map(item => item.story);
  const subCollections = items
    .filter(item => item.type === "collection")
    .slice(0, 2)
    .map((subCollection, index) => (
      <div key={subCollection.id} className={styles["story-stack-" + (index + 1)]}>
        <h2 className={styles["sub-collection-headline"]}>{subCollection.name}</h2>
        <StoryStack
          bigStory={false}
          mobileStoryCount={4}
          stories={subCollection.items
            .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
            .slice(0, 4)
            .map(item => item.story)}
        />
      </div>
    ));

  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container row ${styles.row}`}>
        {stories[0] && <BigStoryCardDifferentAspect story={stories[0]} className={styles["story-1"]} />}
        {stories[1] && <SmallStoryCardDesktopBig story={stories[1]} className={styles["story-2"]} />}
        {stories[2] && <SmallStoryCardDesktopSmallVertical story={stories[2]} className={styles["story-3"]} />}
        {stories[3] && <SmallStoryCardDesktopSmallVertical story={stories[3]} className={styles["story-4"]} />}
        <div className={styles["sidebar"]}>
          <div className={styles["ad"]}>
            <DfpAd className="hidden-mobile" adtype="Vikatan_Desktop_AP_ATF_RIGHT_300x250" placement={pagetype} />
          </div>
          <div className={`${styles["widget"]} hidden-mobile`}>
            <DfpAd className="hidden-mobile" adtype="Vikatan_Desktop_SP_ATF_Right_300x250" placement={pagetype} />
          </div>
        </div>
        {subCollections}
      </div>
    </div>
  );
};

TwelveStoriesOneAdOneWidget.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    items: PropTypes.array
  }),
  pagetype: PropTypes.string
};
TwelveStoriesOneAdOneWidget.storyLimit = 12;

export default TwelveStoriesOneAdOneWidget;
