import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";

import ResponsiveImageWithFallback from "../responsive-image-with-fallback";

import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";

import styles from "./styles.m.css";
import { Icon } from "../../atoms/icon";
import { fetchStoryAttributes } from "../../../api";

export const CollectionSelectCard = ({
  story,
  className = "",
  cardWithImageZoom = true,
  hasTruncatedHeadline = true
}) => {
  const storyData = getStoryData(story);

  if (!storyData.headline || !story.url) {
    return null;
  }

  const [storyMagazineURL, setStoryMagazineURL] = useState(null);
  useEffect(() => {
    fetchStoryAttributes(story)
      .then(res => {
        const conRes = get(res, ["collections"]);
        setStoryMagazineURL(conRes);
      })
      .catch(console.error);
  }, []);
  const slug = get(storyMagazineURL, [1, "slug"]);

  const viSelectPagePath = slug => {
    if (!slug) return null;

    let tokens = slug.split("-");

    const date = new Date(tokens.splice(tokens.length - 3, 3).join("-"));

    const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const dateString = !isNaN(date.getTime())
      ? [date.getFullYear(), monthNames[date.getMonth()], date.getDate()].join("-")
      : "";

    return "/vikatan-select" + "/" + dateString.replace("/", "");
  };

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = viSelectPagePath(slug);
  }
  return (
    <Link
      aria-label={`${"Read full story: "} ${storyData.headline}`}
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={viSelectPagePath(slug)}
      externalLink={SourceLink}
    >
      <div className={styles["base"]}>
        <ResponsiveImageWithFallback
          className={styles["image-wrapper"]}
          slug={storyData.imageS3Key}
          metadata={storyData.imageMetadata}
          alt={storyData.imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
            { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
          )}
          story={story}
        />
        <div className={styles["text-wrapper"]}>
          <div className={styles["bundle-text"]}>
            <h3 className={`${styles["headline"]}  ${hasTruncatedHeadline ? styles["title-truncated"] : ""}`}>
              <span className={styles.highlight}>{story.headline}</span>
            </h3>
            <p className={`${styles["contributor"]} ${hasTruncatedHeadline ? styles["truncated"] : ""} `}>
              {story.subheadline}
            </p>
          </div>
          <div className={styles["arrows"]}>
            <Icon type={"angle-right"} />
            <Icon type={"angle-right"} />
          </div>
        </div>
      </div>
    </Link>
  );
};

CollectionSelectCard.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string
  })
};

export default CollectionSelectCard;
