import React from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import ReadMoreLink from "../../atoms/read-more-link";
import StoryCardFeaturedBigHalfImg from "../../atoms/story-card-featured-big-half-img";
import StoryCardFeaturedSmall from "../../atoms/story-card-featured-small";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import styles from "./styles.m.css";

const InvertedFourStoryHalfFeatured = ({ collection, metadata, accentColor }) => {
  const { name, items } = collection;

  let stories = items.filter(item => item.type === "story").map(item => item.story) || [];

  return (
    <div className={`${styles["four-story-half-featured"]}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <CollectionLink collection={collection}>
          <CollectionTitleWithCrossLine className={`${styles.title}`} title={name} />
        </CollectionLink>
        <div className={`row ${styles.row}`}>
          {stories.splice(0, 1).map(story => (
            <StoryCardFeaturedBigHalfImg className={styles["story-card-featured-big"]} key={story.id} story={story} />
          ))}
          {stories.splice(0, 3).map((story, index) => (
            <StoryCardFeaturedSmall
              className={`${styles["story-card-featured-small"]} ${styles["small-story-" + (index + 1)]}`}
              key={story.id}
              story={story}
            />
          ))}
          {collection.pagePath && (
            <ReadMoreLink
              className={`hidden-desktop ${styles["read-more"]}`}
              href={collection.pagePath}
              text={metadata && metadata["read_more_text"]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

InvertedFourStoryHalfFeatured.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        story: StoryCardFeaturedBigHalfImg.propTypes.story
      })
    )
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  })
};
InvertedFourStoryHalfFeatured.storyLimit = 4;

export default InvertedFourStoryHalfFeatured;
