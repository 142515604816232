import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { fetchWCLiveScore } from "../../../api";

import styles from "./styles.m.css";

const WCLiveScore = ({ slug }) => {
  const [matchList, setData] = useState([]);

  const fetchWCScoreTableData = async () => {
    try {
      const data = await fetchWCLiveScore(slug);
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(
    () => {
      // Fetch the initial data when the component mounts
      fetchWCScoreTableData();

      // Set up an interval to fetch data every 10 seconds
      const intervalId = setInterval(fetchWCScoreTableData, 45000);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    },
    [slug]
  );

  const teams = get(matchList, ["data", "teams"], {});
  const innings = get(matchList, ["data", "play", "innings"], {});

  const teamAName = get(teams, ["a", "code"], "");
  const teamBName = get(teams, ["b", "code"], "");

  const teamsAkey = get(teams, ["a", "key"], " ");
  const teamsBkey = get(teams, ["b", "key"], " ");

  const teamAScore = get(innings, ["a_1", "score_str"], "");
  const teamBScore = get(innings, ["b_1", "score_str"], "");

  return (
    <div className={`${styles["live-card-wrapper"]}`}>
      <div className={`${styles["team-header"]}`}>
        <div className={`${styles["team-sec"]}`}>
          <div className={`${styles["img-sec"]} ${styles[teamsAkey]}`} />
          <div className={`${styles["main-score"]}`}>
            <h6>{teamAName}</h6>
            <span>{teamAScore}</span>
          </div>
        </div>
        <div>
          <div className={`${styles["live-card"]}`}>
            <span className={`${styles["live-card-tag"]}`}>Live</span>
          </div>
          <div className={`${styles["vs"]}`}>VS</div>
        </div>
        <div className={`${styles["team-sec"]} ${styles["team-sec-groupb"]}`}>
          <div className={`${styles["main-score"]}`}>
            <h6>{teamBName}</h6>
            <span>{teamBScore}</span>
          </div>
          <div className={`${styles["img-sec"]} ${styles[teamsBkey]}`} />
        </div>
      </div>
    </div>
  );
};
WCLiveScore.propTypes = {
  slug: PropTypes.string
};

export default WCLiveScore;
