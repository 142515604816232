import React from "react";
import PropTypes from "prop-types";
import { ClientSideOnly, Link, WithHostUrl } from "@quintype/components";
import { User } from "../../../../../shared/icons/user";
import { getSubstring, generateImageSources } from "../../../../../utils/utils";
import Contributor from "../../../../../atoms/contributor";
import ResponsiveImageWithFallback from "../../../../../atoms/responsive-image-with-fallback";

import styles from "./styles.m.css";
import Icon from "../../../../../atoms/icon";

const AuthorAvatarServerTemplate = ({ name }) => <User name={name} />;

AuthorAvatarServerTemplate.propTypes = {
  name: PropTypes.string
};

const AuthorAvatar = ({ author }) => {
  const avatarURL = author.avatarUrl || author["avatar-url"];
  const name = author.name;
  return (
    <ClientSideOnly serverComponent={AuthorAvatarServerTemplate}>
      {avatarURL ? (
        <ResponsiveImageWithFallback
          className={styles["author-img"]}
          slug={author["avatar-s3-key"]}
          metadata={author["metadata"]}
          alt={name}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [2, 1], screenWidthCoverage: 0.4 },
            { aspectRatio: [2, 1], screenWidthCoverage: 0.42 }
          )}
        />
      ) : (
        <User name={name} />
      )}
    </ClientSideOnly>
  );
};

AuthorAvatar.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    avatarURL: PropTypes.string,
    "avatar-url": PropTypes.string
  })
};

function renderAuthorBio(bio) {
  if (!bio) {
    return null;
  }
  let bioText = "";
  if (global.document && global.document.createElement) {
    let dummyElement = global.document.createElement("div");
    dummyElement.innerHTML = bio;
    bioText = getSubstring(dummyElement.innerText, 200);
  } else {
    bioText = bio;
  }

  return <div className={styles["author-bio"]}>{bioText}</div>;
}

const AuthorCard = ({ author, config = {}, bio = true }) => {
  return (
    <div className={`${styles.author} `}>
      <div className={styles["author-avatar"]}>
        <WithHostUrl>
          {({ primaryHostUrl }) => (
            <Link href={`${primaryHostUrl}/author/${author.slug}`}>
              <AuthorAvatar author={author} />
            </Link>
          )}
        </WithHostUrl>
      </div>
      <div className={styles["author-details"]}>
        <h4 className={styles["author-name"]}>
          <WithHostUrl>
            {({ primaryHostUrl }) =>
              config.disableAuthorLink ? (
                <span aria-label="Author">{author.name}</span>
              ) : (
                <Link aria-label="Author" href={`${primaryHostUrl}/author/${author.slug}`}>
                  <Icon type="author" className={styles["author-icon"]} /> {author.name}
                </Link>
              )
            }
          </WithHostUrl>
        </h4>
        <WithHostUrl>
          {({ primaryHostUrl }) =>
            config.disableAuthorLink ? (
              <span aria-label="Author">{author.name}</span>
            ) : (
              <div>
                {bio ? (
                  <Link aria-label="Author" href={`${primaryHostUrl}/author/${author.slug}`}>
                    {renderAuthorBio(author.bio)}
                  </Link>
                ) : (
                  ""
                )}
              </div>
            )
          }
        </WithHostUrl>
      </div>
    </div>
  );
};

AuthorCard.propTypes = {
  bio: PropTypes.bool,
  authorName: PropTypes.bool,
  author: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    bio: PropTypes.string,
    "twitter-handle": PropTypes.string
  }),
  config: PropTypes.shape({
    disableAuthorLink: PropTypes.bool
  })
};

const ContributorStack = ({ contributors }) => {
  return (
    <div className={styles["contributions"]}>
      <ul className={styles["contributor-stack"]}>
        <li className={styles["contributor-li"]}>Contributions : </li>
        {contributors.map(contributor =>
          contributor.authors.map(author => (
            <Contributor
              key={author.id}
              name={author.name}
              type={contributor["role-name"]}
              iconColor="#4a4a4a"
              className={styles["contributor"]}
            />
          ))
        )}
      </ul>
    </div>
  );
};
ContributorStack.propTypes = {
  contributors: PropTypes.arrayOf(
    PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      ),
      "role-name": PropTypes.string
    })
  )
};

const AuthorDetailCard = ({ authors, className = " ", config, bio = true, authorName = true }) => {
  if (!authors) {
    return null;
  }

  return (
    <div
      className={`${styles.base} ${className} ${
        authorName ? styles["collectiondetailauthor"] : styles["collectionauthor"]
      }`}
    >
      <div className={`${styles["authors"]}`}>
        {authors &&
          authors.map(author => (
            <AuthorCard key={author.id} config={config} author={author} bio={bio} authorName={authorName} />
          ))}
      </div>
    </div>
  );
};

AuthorDetailCard.propTypes = {
  className: PropTypes.string,
  bio: PropTypes.bool,
  authorName: PropTypes.bool,
  authors: PropTypes.arrayOf(AuthorCard.propTypes.author),
  config: PropTypes.shape({
    disableAuthorLink: PropTypes.bool
  })
};

export default AuthorDetailCard;
