import React from "react";
import PropTypes from "prop-types";

export function User({ color = "#404040", width = 20, height = 20 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102">
      <g id="Group_325" data-name="Group 325" transform="translate(5287 172)">
        <circle
          id="Ellipse_16"
          data-name="Ellipse 16"
          cx="47"
          cy="47"
          r="47"
          transform="translate(-5283 -168)"
          fill="#fff"
        />
        <g id="account_circle_black_24dp" transform="translate(-5287.152 -172.152)">
          <g id="Group_306" data-name="Group 306">
            <rect
              id="Rectangle_15433"
              data-name="Rectangle 15433"
              width="102"
              height="102"
              transform="translate(0.152 0.152)"
              fill="none"
            />
          </g>
          <g id="Group_307" data-name="Group 307" transform="translate(3.063 3.063)">
            <path
              id="Path_108796"
              data-name="Path 108796"
              d="M50.089,2A48.089,48.089,0,1,0,98.179,50.089,48.107,48.107,0,0,0,50.089,2Zm0,19.236A16.831,16.831,0,1,1,33.258,38.067,16.85,16.85,0,0,1,50.089,21.236Zm0,67.325a38.511,38.511,0,0,1-29.527-13.85,47.856,47.856,0,0,1,59.054,0A38.511,38.511,0,0,1,50.089,88.561Z"
              transform="translate(-2 -2)"
              fill="#ababab"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

User.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
