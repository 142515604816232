import { format, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";

function convertToMillisecs(timestamp) {
  const currentTimestamp = new Date().valueOf();
  if (timestamp * 1000 < currentTimestamp) {
    return timestamp * 1000;
  }
  return timestamp;
}

export function humanizeDate(timestamp, type = "relative") {
  timestamp = parseInt(timestamp);
  const currentDate = new Date();
  const givenDate = new Date(convertToMillisecs(timestamp));
  if (type === "absolute") {
    return `${format(givenDate, "dd MMM yyyy h a")}`;
  }
  if (differenceInSeconds(currentDate, givenDate) < 60) {
    return "Just Now";
  } else if (differenceInMinutes(currentDate, givenDate) < 60) {
    const minutes = differenceInMinutes(currentDate, givenDate);
    return minutes > 1 ? `${minutes} mins ago` : `${minutes} min ago`;
  } else if (differenceInHours(currentDate, givenDate) < 24 && currentDate.getDate() === givenDate.getDate()) {
    return `Today at ${format(givenDate, "h a")}`;
  } else if (differenceInDays(currentDate, givenDate) < 2) {
    return `Yesterday at ${format(givenDate, "h a")}`;
  } else {
    return `${format(givenDate, "dd MMM yyyy h a")}`;
  }
}

export function humanizeDateIST(timestamp, type = "relative") {
  timestamp = parseInt(timestamp);
  const currentDate = new Date();
  const givenDate = new Date(convertToMillisecs(timestamp));
  const timeZoneAbbreviation = "IST";

  if (type === "absolute") {
    return `${format(givenDate, "MMM dd, yyyy hh:mm a")} ${timeZoneAbbreviation}`;
  }

  if (differenceInSeconds(currentDate, givenDate) < 60) {
    return "Just Now";
  } else if (differenceInMinutes(currentDate, givenDate) < 60) {
    const minutes = differenceInMinutes(currentDate, givenDate);
    return minutes > 1 ? `${minutes} mins ago` : `${minutes} min ago`;
  } else if (differenceInHours(currentDate, givenDate) < 24 && currentDate.getDate() === givenDate.getDate()) {
    return `Today at ${format(givenDate, "hh:mm a")} ${timeZoneAbbreviation}`;
  } else if (differenceInDays(currentDate, givenDate) < 2) {
    return `Yesterday at ${format(givenDate, "hh:mm a")} ${timeZoneAbbreviation}`;
  } else {
    return `${format(givenDate, "MMM dd, yyyy hh:mm a")} ${timeZoneAbbreviation}`;
  }
}

export function getMonthDayString(timestamp) {
  timestamp = parseInt(timestamp);
  const givenDate = new Date(convertToMillisecs(timestamp));
  return `${format(givenDate, "MMMM d")}`;
}

export function getYearMonthDayString(timestamp) {
  timestamp = parseInt(timestamp);
  const givenDate = new Date(convertToMillisecs(timestamp));
  return `${format(givenDate, "dd MMM yyyy")}`;
}

export function humanizeCustomDate(timestamp, type = "relative") {
  timestamp = parseInt(timestamp);
  const currentDate = new Date();
  const givenDate = new Date(convertToMillisecs(timestamp));

  if (differenceInSeconds(currentDate, givenDate) < 60) {
    return `<h6 class="timer">Just</h6><p class="mins-ago">Now</p>`;
  } else if (differenceInMinutes(currentDate, givenDate) < 60) {
    const minutes = differenceInMinutes(currentDate, givenDate);
    return minutes > 1
      ? `<h6 class="timer">${minutes}</h6> <p class="mins-ago">mins ago</p>`
      : `<h6 class="timer">${minutes}</h6> <p class="mins-ago">min ago</p>`;
  } else if (differenceInHours(currentDate, givenDate) < 24 && currentDate.getDate() === givenDate.getDate()) {
    const hours = differenceInHours(currentDate, givenDate);
    return hours > 1
      ? `<h6 class="timer"> ${differenceInHours(currentDate, givenDate)} </h6><p class="mins-ago">Hrs ago</p>`
      : `<h6 class="timer"> ${differenceInHours(currentDate, givenDate)} </h6><p class="mins-ago">Hr ago</p>`;
  } else if (differenceInDays(currentDate, givenDate) < 2) {
    return `<h6 class="timer"> ${format(givenDate, "h a")} </h6><p class="mins-ago">Yesterday</p>`;
  } else {
    return `<h6 class="timer">${format(givenDate, "dd")}</h6><p class="mins-ago">${format(givenDate, "MMM")}</p>`;
  }
}

export function getMonthString() {
  const givenDate = new Date();
  return `${format(givenDate, "MMMM")}`;
}
