import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { getStoryData, generateImageSources, checkAppSlug } from "../../utils/utils";
import Icon from "../icon";
import { isPremium } from "../../../../isomorphic/data/story";

import styles from "./styles.m.css";

export default function StoryCardFullWidthCurve({ story, className = "", cardWithImageZoom = true }) {
  const storyData = getStoryData(story);

  if (!(storyData.headline && story.url)) {
    return null;
  }

  const externalLink = get(story, ["metadata", "reference-url"]);
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = externalLink || story.url;
  }

  const icons = {
    "photo-album": "gallery",
    photo: "photostory",
    video: "video"
  };

  const iconType = get(icons, [story["story-template"]], null);
  return (
    <div className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}>
      <Link
        aria-label={`${"Read full story: "} ${storyData.headline}`}
        className={styles["link"]}
        href={externalLink || story.url}
        externalLink={SourceLink || externalLink}
      >
        <ResponsiveImageWithFallback
          className={styles["image-container"]}
          slug={storyData.imageS3Key}
          metadata={storyData.imageMetadata}
          isPremium={isPremium(story)}
          alt={storyData.imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [16, 9], screenWidthCoverage: 1 },
            { aspectRatio: [16, 9], screenWidthCoverage: 0.66 }
          )}
          story={story}
          cardType="hero-banner"
        >
          {iconType && (
            <span className={styles["story-template-icon"]}>
              <Icon type={iconType} className={styles["template-icon"]} />
            </span>
          )}
          {story.engagement &&
            story.engagement.total && (
              <span className={styles["engagement-total"]}>
                <Icon type={"speaker"} className={styles["speaker-icon"]} />
                {story.engagement.total}
              </span>
            )}
        </ResponsiveImageWithFallback>
        <div className={styles["content"]}>
          <h3 className={styles["headline"]}>{storyData.headline}</h3>
          <div className={styles["story-byline"]}>
            {story.engagement &&
              story.engagement.total && (
                <div className={styles["engagement"]}>
                  <span className={styles["engagement-item"]}>
                    <Icon type={"comment"} className={styles["engagement-icon"]} />
                    {story.engagement.comments}
                  </span>
                  <span className={styles["engagement-item"]}>
                    <Icon type={"share"} className={styles["engagement-icon"]} />
                    {story.engagement.shares}
                  </span>
                </div>
              )}
          </div>
        </div>
      </Link>
    </div>
  );
}

StoryCardFullWidthCurve.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  story: PropTypes.shape({
    "author-name": PropTypes.string,
    "contributor-role": PropTypes.shape({
      name: PropTypes.string
    }),
    headline: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "reference-url": PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "story-template": PropTypes.string,
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  })
};
