import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import { Icon } from "../../atoms/icon";
import Carousel from "../../atoms/carousel";
import VideoStoryCard from "../../atoms/video-story-card";
import styles from "./styles.m.css";

const SliderVideoCard = ({
  collection,
  numberOfCardsInViewDesktop = 3,
  numberOfCardsInViewTablet = 1,
  numberOfCardsInViewMobile = 1,
  handleAction,
  index,
  showReadMore = false
}) => {
  const desktopGap = 30;
  const mobileGap = 30;
  if (!collection || !collection.items) {
    return null;
  }
  const stories = collection.items.map(item => item.story);
  if (stories.length === 0) {
    return null;
  }
  let slides = stories
    .slice(0, 16)
    .map(story => <VideoStoryCard className={styles["story-card"]} key={story.id} story={story} />);

  let sliderMain = stories
    .slice(0, 1)
    .map(story => <VideoStoryCard className={styles["story-card"]} key={story.id} story={story} playBtn="big" />);

  if (slides.length === 2) {
    return <div>{slides}</div>;
  }
  return (
    <div className={`${styles["numbered-items-wrap"]} bg-diamond-dark`}>
      <div className="container">
        {collection.slug &&
          collection.name && (
            <div className={styles["numbered-header"]}>
              <h2 className={styles["title"]}>
                <Icon type={"videographer"} className={styles["video-icon"]} />
                <Link aria-label={`"Read more "${collection.name}`} href={`${collection.pagePath || collection.slug}`}>
                  {collection.name}
                </Link>
              </h2>
              <div className={styles["more-div"]}>
                {showReadMore === false ? (
                  <div
                    className={styles["more-button"]}
                    aria-label={`"Read ${styles["more-button"]} "${collection.name}`}
                    onClick={() => handleAction(collection.slug, index)}
                  >
                    <span>மேலும் வீடியோஸ்</span>
                    <span className={styles["more-icons"]}>
                      <Icon type={"slider-next"} className={styles["left-icon"]} />
                      <Icon type={"slider-next"} className={styles["right-icon"]} />
                    </span>
                  </div>
                ) : (
                  <Link
                    className={styles["more-button"]}
                    aria-label={`"Read more "${collection.name}`}
                    href={`${collection.pagePath || collection.slug}`}
                  >
                    <span>மேலும் வீடியோஸ்</span>
                  </Link>
                )}
              </div>
            </div>
          )}
        <div className={styles["main-head"]}>{sliderMain}</div>
        <div>
          <Carousel
            className={`${styles["first-slider"]}`}
            options={{
              type: "slider",
              perView: numberOfCardsInViewDesktop,
              gap: desktopGap,
              focusAt: 0,
              bound: true,
              peek: {
                before: 0,
                after: 120
              },
              breakpoints: {
                320: {
                  perView: numberOfCardsInViewMobile,
                  gap: mobileGap,
                  bound: true,
                  peek: {
                    before: 0,
                    after: 120
                  }
                },
                767: {
                  perView: numberOfCardsInViewMobile,
                  gap: mobileGap,
                  bound: true,
                  peek: {
                    before: 0,
                    after: 120
                  }
                },
                991: {
                  perView: numberOfCardsInViewTablet,
                  gap: desktopGap,
                  bound: true,
                  peek: {
                    before: 0,
                    after: 120
                  }
                }
              }
            }}
            renderCenterLeftControls={({ previousSlide }) => {
              return null;
            }}
            renderCenterRightControls={({ nextSlide }) => {
              return null;
            }}
            renderBottomCenterControls={({ currentSlide, goToSlide }) => {
              return null;
            }}
            childClass={styles["glide__slide__li"]}
          >
            {slides}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

SliderVideoCard.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.number,
        slug: PropTypes.string.number,
        id: PropTypes.string.number
      })
    )
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  handleAction: PropTypes.func,
  index: PropTypes.number,
  showReadMore: PropTypes.bool
};

export default SliderVideoCard;
