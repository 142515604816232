import React from "react";
import PropTypes from "prop-types";
import Carousel from "../../atoms/carousel";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import WebStoriesFocusedCard from "../../atoms/web-stories-focused-card";
import Icon from "../../atoms/icon";
import { getStoryHeadline } from "../../utils/utils";

import styles from "./styles.m.css";

const SliderWebStoriesCard = ({
  collection,
  numberOfCardsInViewDesktop = 4,
  numberOfCardsInViewTablet = 3,
  numberOfCardsInViewMobile = 1,
  accentColor
}) => {
  const desktopGap = 30;
  const mobileGap = 0;

  const slides = collection.items
    .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
    .map(({ story }) => <WebStoriesFocusedCard key={story.id} story={story} className={styles.card} />);

  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container ${styles["carousel-wrapper"]}`}>
        {collection.name && (
          <React.Fragment>
            <Icon type="stories" className={styles["story-icon"]} />
            <CollectionTitleWithCrossLine className={styles.title} title={collection.name} />
          </React.Fragment>
        )}
        <Carousel
          className={styles["carousel"]}
          options={{
            type: "carousel",
            perView: numberOfCardsInViewDesktop,
            gap: desktopGap,
            focusAt: 0,
            bound: true,
            peek: {
              before: 0,
              after: 244
            },
            breakpoints: {
              320: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap,
                bound: true,
                peek: {
                  before: mobileGap,
                  after: 80
                }
              },
              767: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap,
                bound: true,
                peek: {
                  before: mobileGap,
                  after: 80
                }
              },
              991: {
                perView: numberOfCardsInViewTablet,
                gap: desktopGap,
                bound: true,
                peek: {
                  before: desktopGap,
                  after: 244
                }
              }
            }
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) =>
            currentSlide > 0 ? (
              <button
                aria-label="previous slide"
                role="button"
                onClick={previousSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="angle-left" className={styles.icon} />
              </button>
            ) : null
          }
          renderCenterRightControls={({ nextSlide }) => (
            <button
              aria-label="next slide"
              role="button"
              onClick={nextSlide}
              className={`${styles["slider-nav-button"]}`}
            >
              <Icon type="angle-right" className={styles.icon} />
            </button>
          )}
          childClass={styles["glide__slide__li"]}
        >
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

SliderWebStoriesCard.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default SliderWebStoriesCard;
