import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";
import AudioCollectionCard from "../../atoms/audio-collection-card";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import { generateImageSources } from "../../utils/utils";
import { get } from "lodash";
import CollectionLink from "../../utils/generate-collection-link";
import Icon from "../../atoms/icon";

const Audio1CImagewithDesc4Cimages = ({ collection, numberOfCardsInViewDesktop = 3, accentColor }) => {
  const { items } = collection;
  const { headline } = collection.name;
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const MetaDataDesc = get(collection, ["metadata", "meta-description"], "");
  // console.log("collection--->", collection);
  if (!items || !items.length) {
    return null;
  }

  // const firstItem = items[0];
  // const firstCollection = (
  //   <AudioCollectionCard
  //     key={firstItem.id}
  //     collection={firstItem}
  //     className={`${styles.card} ${styles["first-item"]}`}
  //     showTitleAndMetaDesc={true}
  //     numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
  //   />
  // );

  const mainCollection = (
    <div className={styles["main-collection"]}>
      <CollectionLink collection={collection} customSlug={true} slugName={"vikatan-play/collection"}>
        <ResponsiveImageWithFallback
          className={styles["image-wrapper"]}
          slug={coverImageS3Key}
          metadata={coverImageMetaData}
          alt={imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
            { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
          )}
        />
      </CollectionLink>
      <div className={styles["text-wrapper"]}>
        <CollectionLink collection={collection} customSlug={true} slugName={"vikatan-play/collection"}>
          <div className={styles["bundle-text"]}>
            <h3 className={styles["headline"]}>
              <span className={styles.highlight}>{collection.name}</span>
            </h3>
            <div className={styles["mobile-desc-cta"]}>
              <p className={styles["description"]}>{MetaDataDesc}</p>
              {collection.name && <Icon type={"slider-next"} className={styles["collection-cta"]} />}
            </div>
          </div>
        </CollectionLink>
      </div>
    </div>
  );

  const itemsList = items.map((item, index) => (
    <AudioCollectionCard
      key={item.id}
      collection={item}
      className={`${styles.card} ${index === 0 ? styles["first-item"] : ""}`}
      showTitleAndMetaDesc={true}
      numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
    />
  ));

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`${styles["carousel-wrapper"]}`}>
          <div>{mainCollection}</div>
          <div className={styles["other-collection"]}>{itemsList}</div>
        </div>
      </div>
    </div>
  );
};

Audio1CImagewithDesc4Cimages.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default Audio1CImagewithDesc4Cimages;
