import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources, checkAppSlug } from "../../utils/utils";
import { Icon } from "../../atoms/icon";

const CollectionSelectItem = ({
  collection,
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  className = "",
  numberOfCardsInViewDesktop
}) => {
  const headline = get(collection, ["name"], "");
  const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["slug"], "");
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  if (!(headline && slug)) {
    return null;
  }

  const viSelectPagePath = slug => {
    if (!slug) return null;

    let tokens = slug.split("-");

    const date = new Date(tokens.splice(tokens.length - 3, 3).join("-"));

    const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const dateString = !isNaN(date.getTime())
      ? [date.getFullYear(), monthNames[date.getMonth()], date.getDate()].join("-")
      : "";

    return "/vikatan-select" + "/" + dateString.replace("/", "");
  };

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = viSelectPagePath(slug);
  }
  return (
    <Link
      className={`${styles["read-more-link"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}
      href={viSelectPagePath(slug)}
      externalLink={SourceLink}
    >
      <div
        className={`${styles["base"]} ${numberOfCardsInViewDesktop > 2 ? styles["vertical"] : styles["horizantal"]} `}
      >
        <ResponsiveImageWithFallback
          className={styles["image-wrapper"]}
          slug={coverImageS3Key}
          metadata={coverImageMetaData}
          alt={imageCaption}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
            { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
          )}
        />
        <div className={styles["text-wrapper"]}>
          <div className={styles["bundle-text"]}>
            <h3 className={`${styles["headline"]}`}>
              <span className={styles.highlight}>{collection.name}</span>
            </h3>
          </div>
          <div className={styles["arrows"]}>
            <Icon type={"angle-right"} />
            <Icon type={"angle-right"} />
          </div>
        </div>
      </div>
    </Link>
  );
};

CollectionSelectItem.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

export default CollectionSelectItem;
